export default {
    title: "Złożone zamówienie",
    header: {
        success: "@:views.order.submitted.title",
        error: "Zamówienie oczekuje na realizację"
    },
    message: {
        success: "Twoje zamówienie zostało przesłane. Aby uzyskać aktualizacje, sprawdź swoją pocztę e-mail lub panel na stronie <a href='{url}'>głównej</a> .",
        error: {
            stock: "Twoje zamówienie zostało złożone, <b>z wyjątkiem</b> pozycji wymienionych poniżej, ponieważ nie ma ich w magazynie. Aby zamówić te elementy, należy dokonać innego wyboru. Aby uzyskać aktualizacje, sprawdź swoją pocztę e-mail lub panel na stronie <a href='{url}'>głównej</a> .",
            status: "Mamy szczegóły Twojego zamówienia i zauważyliśmy problem z przypisaniem Twojego menedżera. Współpracujemy z GEAR IT, aby rozwiązać ten problem."
        }
    }
}