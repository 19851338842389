export default {
    menu: {
        approvals: "Aprobaciones",
        delegates: "Delegados"
    },
    approvals: {
        title: "Aprobación",
        error: "Ocurrió un error"
    },
    delegates: {
        title: "Delegados",
        error: "Ocurrió un error",
        noDelegates: "No hay artículos",
        delegateDelete: "El delegado ha sido eliminado.",
        confirm: {
            delete: "¿Eliminar delegado?"
        },
        delegates: {
            email: "Correo electrónico",
            startDate: "Fecha de inicio",
            endDate: "Fecha final",
        },
        buttons: {
            add: {
                text: "Agregar delegado"
            }
        }
    }
}