export default {
    menu: {
        approvals: "Zatwierdzenia",
        delegates: "Delegaci"
    },
    approvals: {
        title: "Aprobata",
        error: "Wystąpił błąd"
    },
    delegates: {
        title: "Delegaci",
        error: "Wystąpił błąd",
        noDelegates: "Żadnych przedmiotów",
        delegateDelete: "Delegat został usunięty",
        confirm: {
            delete: "Usunąć delegata?"
        },
        delegates: {
            email: "E-mail",
            startDate: "Data rozpoczęcia",
            endDate: "Data końcowa",
        },
        buttons: {
            add: {
                text: "Dodaj delegata"
            }
        }
    }
}