<template>
    <div v-if="true === loading" class="container">
        <div class="row">
            <div class="col-12">
                <loading/>
            </div>
        </div>
    </div>
    <page-message v-else-if="error">
        <template #header>{{t('error')}}</template>
        <template #message>{{error}}</template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
    <form v-else>
        <layout-two-column>
            <template v-if="display.delegate" #column>
                <panel>
                    <div>
                        <router-link :to="{name: 'approvals'}" active-class="font-weight-bold">
                            {{$t('views.approval.menu.approvals')}}
                        </router-link>
                    </div>
                    <div class="pt-1">
                        <router-link :to="{name: 'approvals.delegate'}" active-class="font-weight-bold">
                            {{$t('views.approval.menu.delegates')}}
                        </router-link>
                    </div>
                </panel>
            </template>

            <template #default>
                <approvals-list :approvals="approvals" @refresh="fetch"/>
            </template>
        </layout-two-column>
    </form>
</template>

<script>

    import {get} from "lodash";

    import mixin_view from "@/mixins/view";

    import ApprovalsList from "@/components/approvals/list/ApprovalsList";
    import LayoutTwoColumn from "@/components/layout/LayoutTwoColumn";
    import PageMessage from "@/components/page/PageMessage";
    import Panel from "@/components/Panel";

    export default {
        name: "ViewsApproval",
        mixins: [mixin_view],
        components: {
            ApprovalsList,
            LayoutTwoColumn,
            PageMessage,
            Panel
        },
        data() {

            return {
                t_path: "views.approval.approvals",
                loading: true,
                error: null,
                approvals: []
            }
        },
        computed: {
            display() {

                return {
                    delegate: this.$store.getters["config/user/delegate/enabled"]
                };
            }
        },
        methods: {
            fetch() {

                this.loading = true;

                const promises = [
                    this.$order.get(`/users/${this.$store.getters["user/id"]}/approvals`)
                ];

                return Promise
                    .all(promises)
                    .then(responses => {

                        const [responseOrder] = responses;

                        // Normalize data
                        const orders = Object
                            .keys(responseOrder.data.results.data)
                            .map(orderId => ({order_id: orderId, ...responseOrder.data.results.data[orderId]}));

                        this.approvals.splice(0, this.approvals.length, ...orders);
                    })
                    .catch(error => this.error = error.response && error.response.status
                        ? get(error, "response.data.results.0.msg")
                        : error
                    )
                    .finally(() => this.loading = false);
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>