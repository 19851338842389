<template>
    <div class="column-editable">
        <approvals-list-item-dynamic-decorator :approval="approval" :field="field" :filter-decorator-dependancy="true">
            {{value}}
        </approvals-list-item-dynamic-decorator>
    </div>
</template>

<script>

    import ApprovalsListItemDynamicDecorator from "@/components/approvals/list/item/dynamic/ApprovalsListItemDynamicDecorator";

    export default {
        name: "ApprovalsListItemPriorApprovalsItemDynamic",
        components: {
            ApprovalsListItemDynamicDecorator
        },
        props: {
            approval: {
                type: Object,
                required: true
            },
            field: {
                type: Object,
                required: true
            }
        },
        computed: {
            value() {

                return this.approval.dynamic[this.field.payload.field]
            }
        }
    }
</script>