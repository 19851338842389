import approvals from "./user/approval"
import checkout from "./user/checkout"
import delegate from "./user/delegate"

export default {
    namespaced: true,
    actions: {
        clear(context) {

            context.dispatch("approvals/clear");
            context.dispatch("checkout/clear");
            context.dispatch("delegate/clear");
        }
    },
    modules: {
        approvals,
        checkout,
        delegate
    }
}