export default {
    required: "Érték megadása kötelező",
    email: "Az érték nem érvényes e-mail cím",
    mask: "Az érték érvénytelen",
    maxValue: "A maximális érték {maxValue}",
    regex: "Az érték érvénytelen",
    server: {
        DELEGATES_NOT_ENABLED: "A küldöttek nem engedélyezettek ennél a partnernél.",
        DELEGATE_DOES_NOT_EXIST: "A delegált nem létezik",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Nem frissíthető a nem Önhöz tartozó meghatalmazott.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Nem lehet törölni olyan meghatalmazottat, aki nem az Öné.",
        DELEGATE_ALREADY_EXISTS: "Már létezik egy, a feltételeknek megfelelő felhatalmazási szabály.",
        DELEGATE_CANNOT_BE_SELF: "Nem veheti fel magát meghatalmazottként.",
        IMPERSONATE_USER_NO_PERMISSIONS: "Felhasználó {email} nem fér hozzá a DXC Gearhez, és a nevükben nem lehet megrendelni",
        "Invalid user type": "Érvénytelen felhasználói típus",
        "User not found": "Felhasználó nem található"
    }
}