export default {
    types: {
        "ac_router": "AC router",
        "chair": "Szék",
        "desk": "Íróasztal",
        "desktop": "Asztali",
        "desktop_riser": "Desktop Riser",
        "detector": "Pára- és zajérzékelő",
        "displayport_cable": "Displayport kábel",
        "docking station": "Dokkoló állomás",
        "dual_usb_adapter": "Kétsávos USB adapter",
        "ear cushion": "Fülpárna",
        "footrest": "Lábtartó",
        "hdmi_cable": "HDMI kábel",
        "hdmi_displayport_cable": "HDMI-DisplayPort kábel",
        "hdmi_vga_cable": "HDMI-VGA kábel",
        "headset": "Fejhallgató",
        "keyboard": "Billentyűzet",
        "kvm switch": "Kvm kapcsoló",
        "kvm_switch": "Kvm kapcsoló",
        "lamp": "Lámpa",
        "laptop stand": "Laptop állvány",
        "laptop": "Laptop",
        "mesh_router": "Mesh router",
        "monitor": "Monitor",
        "mouse": "Egér",
        "patch_cord": "Cat6 patch kábel",
        "power": "Tápkábel",
        "power_center": "Power Center",
        "power_strip": "Power Strip",
        "printer": "Nyomtató",
        "smart_plug": "Smart Plug mini",
        "smart_power_strip": "Intelligens elosztó",
        "stylus": "Stylus",
        "tablet": "Tabletta",
        "tablet accessory": "Táblagép tartozék",
        "thunderbolt_cable": "USB-C a Thunderbolthoz",
        "travel_router": "Utazási útválasztó",
        "usb hub": "Usb Hub",
        "usb_adapter": "USB adapter",
        "usb_hub": "Usb Hub",
        "webcam": "Webkamera"
    }
}