export default {
    status: {
        acknowledged: "Besteld",
        created: "Gemaakt",
        sent: "Besteld",
        confirmed: "Besteld",
        not_confirmed: "Niet besteld",
        delivery_email: "Verzonden",
        shipped: "Verzonden",
        delayed: "Vertraagd",
        preparing: "Voorbereiden",
        processing: "Verwerken",
        processed: "Verwerkt",
        submitted: "Besteld",
        delivery_not_received: "Verzonden",
        shipment_exception: "Verzonden",
        pre_pending_approval: "In behandeling",
        pending_approval: "In behandeling",
        approved: "Goedgekeurd",
        not_approved: "Niet goedgekeurd",
        canceled: "Geannuleerd",
        returned: "Teruggekeerd",
        lost_shipment: "Verloren zending",
        delivery_not_confirmed: "Afgeleverd",
        delivered: "Afgeleverd",
        delivery_received: "Afgeleverd",
        refurbished: "Terugkeer voltooid",
        returned_to_office: "Teruggekeerd naar kantoor",
        return_to_employer: "Teruggegeven aan werkgever",
        return_pickup_scheduled: "Afhalen gepland",
        return_pickup_completed: "Ophalen voltooid",
        return_pickup_excluded: "Afhalen uitgesloten",
        return_pickup_submitted: "Ophalen ingediend",
        return_pickup_finalized: "Afhalen afgerond",
        disposed: "Afgevoerd",
        damaged: "Beschadigd",
        transferred: "Overgedragen",
        transfer_received: "Overdracht ontvangen"
    }
}