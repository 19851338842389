export default {
    disclaimer: {
        label_default: "我承认并同意我所订购/收到的物品为 O-I 财产，将用于 O-I 工作相关活动，因此，O-I 保留在我退出 O-I 时（或更早，如果 O-I 要求）要求归还该财产的权利。",
        label_type_1: "我确认已准确、真诚地完成家庭作业自我评估，并且从 O-I 地点提供的或通过 Gear 订购的任何设备或家具都将由我用于我在 O-I 的工作。我承认并同意我订购/收到的物品是 O-I 财产，将用于 O-I 与工作相关的活动，因此，O-I 保留在我离开 O-I 时（或如果 O-I 要求，则更早）要求归还该财产的权利。",
        errors: {
            required: "请接受使用条款"
        }
    },
    cost_center: {
        label: "成本中心代码",
        placeholder: "成本中心代码",
        cost_center_not_found: "未显示的成本中心可能需要长达 24 小时才能显示。如果您未看到相应的成本中心，请联系全球服务台。",
        errors: {
            regex: "成本中心代码无效"
        }
    },
    company_code: {
        label: "公司代码"
    },
    cost_center_name: {
        label: "成本中心名称"
    },
    order_note: {
        label: "订单备注",
        placeholder: "可选备注，供经理批准",
    },
    send_emails_to_oobo_orderer: {
        "OOBO": "通知我此订单的更新情况"
    }
}