export default {
    title: "Ordine inviato",
    header: {
        success: "@:views.order.submitted.title",
        error: "L'ordine è in sospeso"
    },
    message: {
        success: "Il tuo ordine è stato inviato Per gli aggiornamenti, controlla la tua email o la dashboard nella <a href='{url}'>home</a> page.",
        error: {
            stock: "Il tuo ordine è stato effettuato <b>ad eccezione</b> degli articoli elencati di seguito poiché non sono disponibili. Effettuare selezioni diverse per ordinare questi articoli. Per gli aggiornamenti, controlla la tua email o la dashboard nella <a href='{url}'>home</a> page.",
            status: "Abbiamo i dettagli del tuo ordine e abbiamo notato un problema con l'assegnazione del tuo manager. Stiamo lavorando con GEAR IT per risolvere il problema."
        }
    }
}