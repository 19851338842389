<template>
    <div class="column-cta d-flex">
        <div class="pr-3">
            <div class="form-check">
                <input type="radio"
                       class="form-check-input"
                       :id="'approved_' + approval.order.id"
                       :value="'true'"
                       :checked="true === approved"
                       :name="name"
                       :disabled="disabled"
                       @input="inputApproved">

                <label class="form-check-label" :for="'approved_' + approval.order.id">
                    {{t('approve.yes.label')}}
                </label>
            </div>

            <div class="form-check">
                <input type="radio"
                       class="form-check-input"
                       :id="'notapproved_' + approval.order.id"
                       :checked="false === approved"
                       :value="'false'"
                       :name="name"
                       :disabled="disabled"
                       @input="inputApproved">

                <label class="form-check-label" :for="'notapproved_' + approval.order.id">
                    {{t('approve.no.label')}}
                </label>
            </div>
        </div>

        <form-textarea :model-value="reason"
                       :textarea-class="'form-control form-control-sm'"
                       :validator="validatorReason"
                       :placeholder="placeholder"
                       :disabled="disabled"
                       @input="$emit('reason', $event.target.value)"/>
    </div>
</template>

<script>

    import FormTextarea from "@/components/form/FormTextarea";

    export default {
        name: "ApprovalsListItemApprove",
        emits: ["approved", "reason"],
        components: {
            FormTextarea
        },
        props: {
            approved: {},
            reason: {},
            validatorReason: {
                type: Object,
                required: true
            },
            approval: {
                type: Object,
                required: true
            },
            disabled: Boolean
        },
        data() {

            return {
                t_path: "components.approvals.list.item.approve"
            };
        },
        computed: {
            name() {

                return `model_approved_${this.approval.order.id}`;
            },
            placeholder() {

                switch (this.approved) {
                    case true:
                        return this.t("reason.placeholder.approved");
                    case false:
                        return this.t("reason.placeholder.notapproved");
                    default:
                        return this.t("reason.placeholder.default");
                }
            }
        },
        methods: {
            inputApproved(event) {

                this.$emit('approved', 'true' === event.target.value)
            }
        }
    }
</script>

