export default {
    user: {
        create: {
            title: "Nuevo Usuario",
            model: {
                email: {
                    label: "Correo electrónico",
                    placeholder: "Llenar correo electrónico"
                },
                name: {
                    label: "Nombre",
                    placeholder: "Nombre de relleno"
                },
                country: {
                    label: "País",
                    placeholder: "Llenar país"
                },
                approval_manager_email: {
                    label: "Correo electrónico del administrador",
                    placeholder: "Llenar el correo electrónico del administrador",
                    errors: {
                        different: "El correo electrónico del administrador de aprobación debe ser diferente del correo electrónico del usuario."
                    }
                }
            },
            cta: {
                submit: {
                    text: "Entregar",
                    processing: "Sumisión"
                },
                cancel: {
                    text: "Cancelar"
                }
            }
        }
    }
}