export default {
    status: {
        acknowledged: "Ordonné",
        created: "Créé",
        sent: "Ordonné",
        confirmed: "Ordonné",
        not_confirmed: "Non commandé",
        delivery_email: "Expédié",
        shipped: "Expédié",
        delayed: "Retardé",
        preparing: "Préparation",
        processing: "Traitement",
        processed: "Traité",
        submitted: "Ordonné",
        delivery_not_received: "Expédié",
        shipment_exception: "Expédié",
        pre_pending_approval: "En attente",
        pending_approval: "En attente",
        approved: "Approuvé",
        not_approved: "Non approuvé",
        canceled: "Annulé",
        returned: "De retour",
        lost_shipment: "Envoi perdu",
        delivery_not_confirmed: "Livré",
        delivered: "Livré",
        delivery_received: "Livré",
        refurbished: "Retour effectué",
        returned_to_office: "De retour au bureau",
        return_to_employer: "Renvoyé à l'employeur",
        return_pickup_scheduled: "Ramassage prévu",
        return_pickup_completed: "Ramassage terminé",
        return_pickup_excluded: "Ramassage exclu",
        return_pickup_submitted: "Ramassage soumis",
        return_pickup_finalized: "Ramassage finalisé",
        disposed: "Éliminé",
        damaged: "Endommagé",
        transferred: "Transféré",
        transfer_received: "Transfert reçu"
    }
}