export default {
    menu: {
        approvals: "Goedkeuringen",
        delegates: "Afgevaardigden"
    },
    approvals: {
        title: "Goedkeuring",
        error: "Er is een fout opgetreden"
    },
    delegates: {
        title: "Afgevaardigden",
        error: "Er is een fout opgetreden",
        noDelegates: "Geen items",
        delegateDelete: "Gemachtigde is verwijderd",
        confirm: {
            delete: "Gemachtigde verwijderen?"
        },
        delegates: {
            email: "E-mail",
            startDate: "Begin datum",
            endDate: "Einddatum",
        },
        buttons: {
            add: {
                text: "Gedelegeerde toevoegen"
            }
        }
    }
}