export default {
    empty: "Niente da approvare",
    saved: "Approvazioni presentate",
    review: "Si prega di rivedere e modificare il centro di costo se non è corretto",
    confirm: {
        partial: "Ci sono ancora ordini con approvazioni in sospeso. Vuoi continuare con le modifiche correnti?"
    },
    header: {
        name: "Nome",
        order: "Ordine",
        details: "Dettagli",
        cta: "Approvazione",
        message: ""
    },
    item: {
        order: {
            manager: "Manager",
            requested: "Richiesto da"
        },
        approve: {
            reason: {
                placeholder: {
                    default: "motivo",
                    approved: "motivo",
                    notapproved: "motivo"
                }
            },
            approve: {
                yes: {
                    label: "SÌ"
                },
                no: {
                    label: "NO"
                }
            }
        },
        prior: {
            cta: {
                load: "Approvazioni precedenti",
                loading: "Caricamento",
                show: "Approvazioni precedenti",
                hide: "Approvazioni precedenti"
            },
            approvals: {
                empty: "Nessuna approvazione preventiva",
                item: {
                    status: {
                        approver: "di {approver}",
                        system: "Servizio di assistenza",
                        approved: "Approvato",
                        rejected: "Non approvato",
                        unrequired: "Approvazione non richiesta",
                        reason: "Motivo",
                        transferred: "Trasferito"
                    }
                }
            }
        }
    },
    cta: {
        submit: {
            text: "Invia",
            processing: "Invio"
        }
    }
}