export default {
    type: {
        "ac_router": "Vous avez atteint la limite de commande des routeurs AC",
        "chair": "Vous avez atteint la limite de commande de chaises",
        "default": "Vous avez atteint la limite de commande",
        "default_multiple": "Vous avez atteint la limite de commande de produits similaires",
        "desk": "Vous avez atteint la limite de commande de bureaux",
        "desktop": "Vous avez atteint la limite de commande du bureau",
        "desktop_riser": "Vous avez atteint la limite de commande des rehausseurs de bureau",
        "detector": "Vous avez atteint la limite de commande des détecteurs de vape et de bruit",
        "displayport_cable": "Vous avez atteint la limite de commande de câbles DisplayPort",
        "docking station": "Vous avez atteint la limite de commande des stations d'accueil",
        "dual_usb_adapter": "Vous avez atteint la limite de commande des adaptateurs USB double bande",
        "ear cushion": "Vous avez atteint la limite de commande de coussinets d'oreille",
        "footrest": "Vous avez atteint la limite de commande de repose-pieds",
        "hdmi_cable": "Vous avez atteint la limite de commande de câbles HDMI",
        "hdmi_displayport_cable": "Vous avez atteint la limite de commande de câbles HDMI vers DisplayPort",
        "hdmi_vga_cable": "Vous avez atteint la limite de commande de câbles HDMI vers VGA",
        "headset": "Vous avez atteint la limite de commande d'appareils audio",
        "keyboard": "Vous avez atteint la limite de commande de claviers",
        "kvm switch": "Vous avez atteint la limite de commande des commutateurs KVM",
        "kvm_switch": "Vous avez atteint la limite de commande des commutateurs KVM",
        "lamp": "Vous avez atteint la limite de commande de lampes",
        "laptop stand": "Vous avez atteint la limite de commande de supports pour ordinateur portable",
        "laptop": "Vous avez atteint la limite de commande d'ordinateurs portables",
        "mesh_router": "Vous avez atteint la limite de commande des routeurs maillés",
        "monitor": "Vous avez atteint la limite de commande de moniteurs",
        "mouse": "Vous avez atteint la limite de commande de souris",
        "patch_cord": "Vous avez atteint la limite de commande de cordons de raccordement Cat6",
        "power": "Vous avez atteint la limite de commande de cordons d'alimentation",
        "power_center": "Vous avez atteint la limite de commande des Power Centers",
        "power_strip": "Vous avez atteint la limite de commande de multiprises",
        "printer": "Vous avez atteint la limite de commande d'imprimantes",
        "smart_plug": "Vous avez atteint la limite de commande de mini prises intelligentes",
        "smart_power_strip": "Vous avez atteint la limite de commande de multiprises intelligentes",
        "stylus": "Vous avez atteint la limite de commande de stylets",
        "tablet": "Vous avez atteint la limite de commande de tablettes",
        "tablet accessory": "Vous avez atteint la limite de commande d'accessoires pour tablettes",
        "thunderbolt_cable": "Vous avez atteint la limite de commande de l'USB-C vers Thunderbolt",
        "travel_router": "Vous avez atteint la limite de commande des routeurs de voyage",
        "usb hub": "Vous avez atteint la limite de commande de concentrateurs USB",
        "usb_adapter": "Vous avez atteint la limite de commande d'adaptateurs USB",
        "usb_hub": "Vous avez atteint la limite de commande de concentrateurs USB",
        "webcam": "Vous avez atteint la limite de commande de webcams"
    }
}