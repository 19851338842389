import {PERMISSIONS} from "@/constants";
import {retryRequest} from "@/helpers/request";
import {platform} from "@/api/platform";

import page from "./config/page"
import user from "./config/user"

export default {
    namespaced: true,
    actions: {
        fetch(context) {

            const config = [];
            const userId = this.getters["user/id"];

            if (this.getters["user/permissions/hasPermission"](PERMISSIONS.EMPLOYEE)) {

                config.push("home_page_config", "checkout_config");
            }

            if (this.getters["user/permissions/hasPermission"](PERMISSIONS.MANAGER)) {

                config.push("allow_delegates", "approval_config");
            }

            return retryRequest(() => platform.get(`/api/v2/user/${userId}/portal-config`, {params: {get: config.join(",")}}))
                .then(response => {

                        if ("undefined" !== typeof response.data.results.home_page_config) {

                            context.dispatch("page/home/setMaxItems", response.data.results.home_page_config.max_items);
                        }

                        if ("undefined" !== typeof response.data.results.checkout_config) {

                            context.dispatch("user/checkout/setConfig", response.data.results.checkout_config);
                        }

                        if ("undefined" !== typeof response.data.results.approval_config) {

                            context.dispatch("user/approvals/setConfig", response.data.results.approval_config);
                        }

                        if ("undefined" !== typeof response.data.results.allow_delegates) {

                            context.dispatch("user/delegate/setEnabled", response.data.results.allow_delegates);
                        }
                    }
                );
        },
        clear(context) {

            context.dispatch("page/clear");
            context.dispatch("user/clear");
        }
    },
    modules: {
        page,
        user
    }
}