export default {
    user: {
        create: {
            title: "Nový uživatel",
            model: {
                email: {
                    label: "E-mailem",
                    placeholder: "Vyplňte email"
                },
                name: {
                    label: "název",
                    placeholder: "Vyplňte jméno"
                },
                country: {
                    label: "Země",
                    placeholder: "Vyplňte zemi"
                },
                approval_manager_email: {
                    label: "E-mail manažera",
                    placeholder: "Vyplňte e-mail manažera",
                    errors: {
                        different: "E-mail správce schválení se musí lišit od e-mailu uživatele"
                    }
                }
            },
            cta: {
                submit: {
                    text: "Předložit",
                    processing: "Odesílání"
                },
                cancel: {
                    text: "zrušení"
                }
            }
        }
    }
}