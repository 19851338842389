export default {
    ordered: {
        type: {
            "ac_router": "Již jsem si objednal jiný AC router",
            "chair": "Již jsem si objednal jinou židli",
            "default": "Již objednáno",
            "default_multiple": "Podobné produkty již byly objednány",
            "desk": "Již jsem si objednal jiný stůl",
            "desktop": "Již jsem si objednal jiný desktop",
            "desktop_riser": "Již jsem si objednal jinou stolní podložku",
            "detector": "Již jsem si objednal jiný detektor výparů a hluku",
            "displayport_cable": "Již jsem objednal jiný kabel Displayport",
            "docking station": "Již jsem si objednal jinou dokovací stanici",
            "dual_usb_adapter": "Již jsem si objednal jiný dvoupásmový USB adaptér",
            "ear cushion": "Již jsem si objednal jiný náušník",
            "footrest": "Již jsem si objednal jinou podnožku",
            "hdmi_cable": "Již jsem si objednal různé HDMI kabely",
            "hdmi_displayport_cable": "Již jsem si objednal jiný kabel HDMI na DisplayPort",
            "hdmi_vga_cable": "Již jsem si objednal jiný kabel HDMI na VGA",
            "headset": "Již bylo objednáno jiné zvukové zařízení",
            "keyboard": "Již jsem si objednal jinou klávesnici",
            "kvm switch": "Již jsem objednal jiný spínač kvm",
            "kvm_switch": "Již jsem objednal jiný spínač kvm",
            "lamp": "Již jsem si objednal jinou lampu",
            "laptop stand": "Již jsem si objednal jiný stojan na notebook",
            "laptop": "Již jsem si objednal jiný notebook",
            "mesh_router": "Již jsem si objednal jiný mesh router",
            "monitor": "Již jsem si objednal jiný monitor",
            "mouse": "Již jsem objednal jiné myši",
            "patch_cord": "Již jsem si objednal jiný patch kabel Cat6",
            "power": "Již jsem si objednal jiný napájecí kabel",
            "power_center": "Již jsem si objednal jiné Power Center",
            "power_strip": "Již jsem si objednal jinou napájecí lištu",
            "printer": "Již byla objednána jiná tiskárna",
            "smart_plug": "Již jsem si objednal jinou inteligentní zástrčku mini",
            "smart_power_strip": "Již jsem si objednal jinou inteligentní napájecí lištu",
            "stylus": "Již jsem si objednal jiný stylus",
            "tablet": "Již jsem si objednal jiný tablet",
            "tablet accessory": "Již jsem si objednal jiné příslušenství k tabletu",
            "thunderbolt_cable": "Již jsem si objednal jiný kabel USB-C na Thunderbolt",
            "travel_router": "Již jsem si objednal jiný cestovní router",
            "usb hub": "Již jsem si objednal jiný USB hub",
            "usb_adapter": "Již jsem si objednal jiný USB adaptér",
            "usb_hub": "Již jsem objednal jiný hub",
            "webcam": "Již jsem si objednal jinou webovou kameru"
        },
        product: "Objednáno"
    },
    request: "Žádost",
    add: "Přidat do košíku",
    remove: "Odebrat z košíku",
    added: "Přidáno do košíku",
    view: "Zobrazit položku"
}