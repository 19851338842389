export default {
    status: {
        acknowledged: "Bestellt",
        created: "Erstellt",
        sent: "Bestellt",
        confirmed: "Bestellt",
        not_confirmed: "Nicht bestellt",
        delivery_email: "Versendet",
        shipped: "Versendet",
        delayed: "Verzögert",
        preparing: "Vorbereitung",
        processing: "Verarbeitung",
        processed: "Verarbeitet",
        submitted: "Bestellt",
        delivery_not_received: "Versendet",
        shipment_exception: "Versendet",
        pre_pending_approval: "Ausstehend",
        pending_approval: "Ausstehend",
        approved: "Genehmigt",
        not_approved: "Nicht genehmigt",
        canceled: "Abgesagt",
        returned: "Zurückgegeben",
        lost_shipment: "Verlorene Sendung",
        delivery_not_confirmed: "Geliefert",
        delivered: "Geliefert",
        delivery_received: "Geliefert",
        refurbished: "Rückgabe abgeschlossen",
        returned_to_office: "Zurück im Amt",
        return_to_employer: "An den Arbeitgeber zurückgegeben",
        return_pickup_scheduled: "Abholung geplant",
        return_pickup_completed: "Abholung abgeschlossen",
        return_pickup_excluded: "Abholung ausgeschlossen",
        return_pickup_submitted: "Abholung eingereicht",
        return_pickup_finalized: "Abholung abgeschlossen",
        disposed: "Entsorgt",
        damaged: "Beschädigt",
        transferred: "Übertragen",
        transfer_received: "Überweisung erhalten"
    }
}