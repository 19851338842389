export default {
    error: "Un problème est survenu lors de la récupération des commandes. Veuillez actualiser votre navigateur.",
    meta: {
        default: "Ordres",
        my_orders: "Mes commandes",
        my_gear: "Reçu"
    },
    loadMore: "Charger plus",
    noOrders: "Aucune commande",
    order: {
        product: {
            title: "Commande",
            tracking: "Suivi",
            quantity: "Quantité",
            transfer: {
                sender: "Propriétaire précédent",
                receiver: "Récepteur",
                transferrer: "Transféreur",
                system: "Service d'assistance"
            },
            control: {
                cancel: {
                    cta: "Cliquez pour annuler",
                    tooltip: "Cliquez pour annuler cet article",
                    message: "Nous avons annulé votre commande."
                },
                cancelRequest: {
                    cta: "Demande d'annulation",
                    tooltip: "Cliquez pour soumettre une demande d'annulation"
                },
                received: {
                    cta: "Marquer comme reçu",
                    tooltip: "Cliquez pour confirmer la livraison",
                    message: "Merci d'avoir confirmé que vous avez reçu votre produit."
                },
                notReceived: {
                    cta: "Marquer comme non reçu",
                    tooltip: "Cliquez pour confirmer la non-livraison"
                },
                damaged: {
                    cta: "Marquer comme endommagé/cassé",
                    tooltip: "Cliquez pour créer un ticket d'assistance"
                },
                hide: {
                    cta: "Masquer l'élément",
                    tooltip: "Cliquez pour masquer l'élément de la liste"
                },
                transfer: {
                    cta: "Transfert",
                    tooltip: "Cliquez pour transférer l'article à un autre employé"
                }
            },
            requestToCancelInfo: "Nous contacterons le fournisseur pour demander une annulation. Veuillez noter qu'il s'agit uniquement d'une demande et qu'une annulation peut ne pas être possible. Une fois que nous aurons déterminé si l'annulation peut être effectuée ou non, nous vous en informerons.",
            cancelOk: "D'accord",
            reason: "Raison",
            orderedBy: "Commandé par",
            model: {
                cancel: {
                    reason: {
                        label: "Motifs d'annulation",
                        placeholder: "Motifs d'annulation"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Soumettre",
                        processing: "Soumission"
                    },
                    cancel: {
                        cta: "Annuler"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>Vous avez des questions sur votre commande ?<br/> Consultez notre <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>centre d'assistance</b></a> pour obtenir de l'aide</p><p> Avez-vous besoin d'un produit non répertorié ?<br/> Cliquez <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>ici</b></a> pour envoyer une suggestion</p>",
        email: {
            subject: ""
        }
    }
}