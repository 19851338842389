export default {
    ordered: {
        type: {
            "ac_router": "J'ai déjà commandé un autre routeur AC",
            "chair": "J'ai déjà commandé une chaise différente",
            "default": "Déjà commandé",
            "default_multiple": "J'ai déjà commandé des produits similaires",
            "desk": "J'ai déjà commandé un bureau différent",
            "desktop": "J'ai déjà commandé un autre ordinateur de bureau",
            "desktop_riser": "J'ai déjà commandé un support de bureau différent",
            "detector": "J'ai déjà commandé différents détecteurs de vape et de bruit",
            "displayport_cable": "J'ai déjà commandé un câble DisplayPort différent",
            "docking station": "J'ai déjà commandé une station d'accueil différente",
            "dual_usb_adapter": "J'ai déjà commandé un autre adaptateur USB double bande",
            "ear cushion": "J'ai déjà commandé des coussinets d'oreille différents",
            "footrest": "J'ai déjà commandé un repose-pied différent",
            "hdmi_cable": "J'ai déjà commandé différents câbles HDMI",
            "hdmi_displayport_cable": "J'ai déjà commandé un câble HDMI vers DisplayPort différent",
            "hdmi_vga_cable": "J'ai déjà commandé un câble HDMI vers VGA différent",
            "headset": "J'ai déjà commandé un autre appareil audio",
            "keyboard": "J'ai déjà commandé un clavier différent",
            "kvm switch": "J'ai déjà commandé un autre commutateur KVM",
            "kvm_switch": "J'ai déjà commandé un autre commutateur KVM",
            "lamp": "J'ai déjà commandé une lampe différente",
            "laptop stand": "J'ai déjà commandé un support pour ordinateur portable différent",
            "laptop": "J'ai déjà commandé un autre ordinateur portable",
            "mesh_router": "J'ai déjà commandé un routeur maillé différent",
            "monitor": "J'ai déjà commandé un autre moniteur",
            "mouse": "J'ai déjà commandé différentes souris",
            "patch_cord": "J'ai déjà commandé un autre cordon de raccordement Cat6",
            "power": "J'ai déjà commandé un cordon d'alimentation différent",
            "power_center": "J'ai déjà commandé un Power Center différent",
            "power_strip": "J'ai déjà commandé une autre multiprise",
            "printer": "J'ai déjà commandé une autre imprimante",
            "smart_plug": "J'ai déjà commandé une autre mini prise intelligente",
            "smart_power_strip": "J'ai déjà commandé une autre multiprise intelligente",
            "stylus": "J'ai déjà commandé un stylet différent",
            "tablet": "J'ai déjà commandé une tablette différente",
            "tablet accessory": "J'ai déjà commandé un accessoire de tablette différent",
            "thunderbolt_cable": "J'ai déjà commandé un câble USB-C vers Thunderbolt différent",
            "travel_router": "J'ai déjà commandé un autre routeur de voyage",
            "usb hub": "J'ai déjà commandé un autre hub USB",
            "usb_adapter": "J'ai déjà commandé un autre adaptateur USB",
            "usb_hub": "J'ai déjà commandé un hub différent",
            "webcam": "J'ai déjà commandé une autre webcam"
        },
        product: "Ordonné"
    },
    request: "Demande",
    add: "Ajouter au panier",
    remove: "Supprimer du panier",
    added: "Ajouté au panier",
    view: "Voir l'article"
}