export default {
    home: "Hogar",
    order: {
        submit: "Revisar orden"
    },
    tunnel: {
        loginVerification: "Verificacion de inicio de sesion",
        addressVerification: "Verificación de dirección"
    },
    catalog: "Catalogar",
    all: "Todo",
    return: {
        return: "Devolver"
    },
    errors: {
        404: "@:views.errors.404.title"
    },
    impersonation: "Orden en nombre de",
    approvals: "Aprobaciones",
    delegate: "Delegados"
}