export default {
    error: "Er is een probleem opgetreden bij het ophalen van orders. Vernieuw uw browser.",
    title: "Bestelgeschiedenis",
    loadMore: "Meer laden",
    noOrders: "Geen bestellingen",
    user: {
        loadMore: "Meer laden",
        product: {
            title: "Volgorde",
            tracking: "Volgen",
            quantity: "Hoeveelheid",
            reason: "Reden",
            transfer: {
                sender: "Afzender",
                receiver: "Ontvanger"
            },
            control: {
                cancel: {
                    cta: "Klik om te annuleren",
                    tooltip: "Klik om dit item te annuleren",
                    message: "Wij hebben uw bestelling geannuleerd."
                },
                received: {
                    cta: "Markeren als ontvangen",
                    tooltip: "Klik om de levering te bevestigen",
                    message: "Hartelijk dank voor uw bevestiging dat u uw product heeft ontvangen."
                },
                notReceived: {
                    cta: "Markeren als niet ontvangen",
                    tooltip: "Klik om niet-levering te bevestigen"
                },
                hide: {
                    cta: "Item verbergen",
                    tooltip: "Klik om het item uit de lijst te verbergen"
                }
            }
        }
    }
}