export default {
    login: "Login",
    logout: "Esci",
    home: "Casa",
    user: {
        profile: {
            edit: "Modifica profilo"
        }
    },
    approvals: {
        approvals: "Approvazioni",
        delegates: "Delegati"
    },
    impersonation: "Ordine per conto di"
}