import store from "@/store";
import permissions from "@/helpers/permissions";

export default {
    ...permissions,
    /**
     * Handles error page based on failed permissions check
     *
     * @param {String} permission
     * @param {Object} restriction
     * @param {Object} query
     * @returns {{name: string, params: {error: string}}}
     */
    getRouteByPermissionFail(permission, restriction, query = {}) {

        if (restriction || store.getters["user/permissions/hasRestriction"](permission)) {

            restriction = restriction ? restriction : store.getters["user/permissions/getRestriction"](permission);

            if ("undefined" !== typeof restriction.isUserCoreIdEmpty && true === restriction.isUserCoreIdEmpty) {

                return {name: "errors.403", params: {error: "empty-core-id"}, query};
            }

            if ("undefined" !== typeof restriction.isUserVirtualDenied && true === restriction.isUserVirtualDenied) {

                return {name: "errors.403", params: {error: "virtual-denied"}, query};
            }

            if ("undefined" !== typeof restriction.isUserDisabled && true === restriction.isUserDisabled) {

                return {name: "errors.403", params: {error: "disabled"}, query};
            }

            if ("undefined" !== typeof restriction.isUserActive && false === restriction.isUserActive) {

                return {name: "errors.403", params: {error: "not-active"}, query};
            }

            if ("undefined" !== typeof restriction.isUserTypeAccessAllowed && false === restriction.isUserTypeAccessAllowed) {

                return {name: "errors.403", params: {error: "not-allowed"}, query};
            }

            if ("undefined" !== typeof restriction.isUserManagerDefined && false === restriction.isUserManagerDefined) {

                return {name: "errors.403", params: {error: "no-manager"}, query};
            }

            if ("undefined" !== typeof restriction.isUserManagerSelf && true === restriction.isUserManagerSelf) {

                return {name: "errors.403", params: {error: "self-manager"}, query};
            }

            if ("undefined" !== typeof restriction.isUserLocationDefined && false === restriction.isUserLocationDefined) {

                return {name: "errors.403", params: {error: "no-location"}, query};
            }

            if (
                ("undefined" !== typeof restriction.isUserInSupportedCountry && false === restriction.isUserInSupportedCountry)
                || ("undefined" !== typeof restriction.isUserAllowed && false === restriction.isUserAllowed)
            ) {

                return {name: "errors.403", params: {error: "eligibility-location"}, query};
            }

            if ("undefined" !== typeof restriction.isUserSecondayAccount && true === restriction.isUserSecondayAccount) {

                return {name: "errors.403", params: {error: "secondary-account"}, query};
            }
        }

        return permissions.getRouteByPermissionFail(permission);
    }
}