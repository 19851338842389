export default {
    type: {
        "ac_router": "您已达到 AC 路由器的订购限制",
        "chair": "您已达到椅子的订购限制",
        "default": "您已达到订购限制",
        "default_multiple": "您已达到同类产品的订购限制",
        "desk": "您已达到办公桌的订购限制",
        "desktop": "您已达到桌面订购限制",
        "desktop_riser": "您已达到桌面升降器的订购限制",
        "detector": "您已达到电子烟和噪音探测器的订购限制",
        "displayport_cable": "您已达到 DisplayPort 电缆的订购限制",
        "docking station": "您已达到订购扩展坞的数量限制",
        "dual_usb_adapter": "您已达到双频 USB 适配器的订购限制",
        "ear cushion": "您已达到耳垫的订购限制",
        "footrest": "您已达到脚凳的订购限制",
        "hdmi_cable": "您已达到 HDMI 线的订购限制",
        "hdmi_displayport_cable": "您已达到 HDMI 转 DisplayPort 线缆的订购数量限制",
        "hdmi_vga_cable": "您已达到 HDMI 转 VGA 线缆的订购数量限制",
        "headset": "您已达到音频设备订购限制",
        "keyboard": "您已达到键盘订购限制",
        "kvm switch": "您已达到 kvm 切换器的订购限制",
        "kvm_switch": "您已达到 kvm 切换器的订购限制",
        "lamp": "您已达到灯具订购限制",
        "laptop stand": "您已达到笔记本电脑支架的订购限制",
        "laptop": "您已达到笔记本电脑的订购限制",
        "mesh_router": "您已达到网状路由器的订购限制",
        "monitor": "您已达到显示器的订购限制",
        "mouse": "您已达到订购老鼠的数量限制",
        "patch_cord": "您已达到 Cat6 跳线的订购限制",
        "power": "您已达到电源线的订购限制",
        "power_center": "您已达到 Power Center 的订购限制",
        "power_strip": "您已达到电源板的订购限制",
        "printer": "您已达到打印机订购限制",
        "smart_plug": "您已达到智能插头 mini 的订购限制",
        "smart_power_strip": "您已达到智能电源板的订购限制",
        "stylus": "您已达到触控笔的订购限制",
        "tablet": "您已达到平板电脑的订购限制",
        "tablet accessory": "您已达到平板电脑配件的订购限额",
        "thunderbolt_cable": "您已达到 USB-C 转 Thunderbolt 的订购限制",
        "travel_router": "您已达到旅行路由器的订购限制",
        "usb hub": "您已达到 usb 集线器的订购限制",
        "usb_adapter": "您已达到 USB 适配器的订购限制",
        "usb_hub": "您已达到 usb 集线器的订购限制",
        "webcam": "您已达到网络摄像头的订购限制"
    }
}