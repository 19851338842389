export default {
    error: "Houve um problema ao recuperar pedidos. Atualize seu navegador.",
    title: "Histórico de pedidos",
    loadMore: "Carregar mais",
    noOrders: "Sem pedidos",
    user: {
        loadMore: "Carregar mais",
        product: {
            title: "Ordem",
            tracking: "Monitorando",
            quantity: "Quantidade",
            reason: "Razão",
            transfer: {
                sender: "Remetente",
                receiver: "Receptor"
            },
            control: {
                cancel: {
                    cta: "Clique para cancelar",
                    tooltip: "Clique para cancelar este item",
                    message: "Cancelamos seu pedido."
                },
                received: {
                    cta: "Marcar como recebido",
                    tooltip: "Clique para confirmar a entrega",
                    message: "Obrigado por confirmar que você recebeu seu produto."
                },
                notReceived: {
                    cta: "Marcar como não recebido",
                    tooltip: "Clique para confirmar a não entrega"
                },
                hide: {
                    cta: "Ocultar item",
                    tooltip: "Clique para ocultar o item da lista"
                }
            }
        }
    }
}