import home from "./page/home"

export default {
    namespaced: true,
    actions: {
        clear(context) {

            context.dispatch("home/clear");
        }
    },
    modules: {
        home
    }
}