<template>
    <button type="button" class="btn btn-link approvals-list-item-prior-cta" :disabled="isDisabled" @click="toggle">
        <span>{{cta}}</span>
        <span class="ml-1" :class="icon"></span>
    </button>
</template>

<script>

    import {get} from "lodash";

    export default {
        name: "ApprovalsListItemPriorCta",
        emits: ["display", "loaded"],
        props: {
            approval: {
                type: Object,
                required: true
            },
            config: {
                type: Array,
                required: true
            },
            display: {
                type: Boolean,
                required: true
            },
            disabled: Boolean
        },
        data() {

            return {
                t_path: "components.approvals.list.item.prior.cta",
                loaded: false,
                loading: false
            };
        },
        computed: {
            isDisabled() {

                return true === this.disabled || true === this.loading;
            },
            cta() {

                if (false === this.loaded) {

                    return this.t("load");
                }

                if (true === this.loading) {

                    return this.t("load");
                }

                if (true === this.display) {

                    return this.t("hide");
                }

                return this.t("show");
            },
            icon() {

                if (true === this.loading) {

                    return "animate-spin icon-spinner";
                }

                return true === this.display ? "icon-up-dir" : "icon-down-dir";
            }
        },
        methods: {
            toggle() {

                if (false === this.loaded) {

                    this.load();

                } else {

                    this.$emit("display", !this.display);
                }
            },
            load() {

                this.loading = true;

                return this.$order
                    .get(`/users/${this.approval.user.id}/prior-approvals`)
                    .then(response => {

                        const items = Object
                            .keys(response.data.results.data)
                            .map(orderId => {

                                const item = response.data.results.data[orderId];

                                return {
                                    order: {
                                        id: item.order_id,
                                        cost: item.cost,
                                        currency: item.currency,
                                        country: item.country,
                                        date: new Date(item.date),
                                        lang_overrides: item.lang_overrides,
                                        details: item.details,
                                        requested_by: get(item, "requested_by", null)
                                    },
                                    transfer: {
                                        enabled: "undefined" !== typeof item.transfer
                                    },
                                    user: {
                                        id: item.user_id,
                                        name: item.name,
                                    },
                                    approval: {
                                        approved: item.approved,
                                        required: true !== item.approval_not_required,
                                        reason: get(item, "approval.approval_reason", null),
                                        manager: {
                                            // It does not matter who assigned approver because order approved/rejected
                                            email: null
                                        },
                                        approver: {
                                            email: get(item, "approval.approved_by_email")
                                        }
                                    },
                                    dynamic: {
                                        ...this.config.reduce(
                                            (result, field) => ({
                                                ...result,
                                                [field.payload.field]: item[field.payload.field]
                                            }),
                                            {}
                                        )
                                    }
                                };
                            });

                        this.loaded = true;

                        this.$emit("loaded", items);
                        this.$emit("display", true);
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.loading = false)
            }
        }
    }
</script>