export default {
    types: {
        "ac_router": "Roteador AC",
        "chair": "Cadeira",
        "desk": "Mesa",
        "desktop": "Área de trabalho",
        "desktop_riser": "Suporte de mesa",
        "detector": "Detector de vapor e ruído",
        "displayport_cable": "Cabo Displayport",
        "docking station": "Estação de ancoragem",
        "dual_usb_adapter": "Adaptador USB de banda dupla",
        "ear cushion": "Almofada de ouvido",
        "footrest": "Apoio para os pés",
        "hdmi_cable": "Cabo HDMI",
        "hdmi_displayport_cable": "Cabo HDMI para DisplayPort",
        "hdmi_vga_cable": "Cabo HDMI para VGA",
        "headset": "Fone de ouvido",
        "keyboard": "Teclado",
        "kvm switch": "Comutador KVM",
        "kvm_switch": "Comutador KVM",
        "lamp": "Lâmpada",
        "laptop stand": "Suporte para laptop",
        "laptop": "Computador portátil",
        "mesh_router": "Roteador de malha",
        "monitor": "Monitor",
        "mouse": "Rato",
        "patch_cord": "Cabo de conexão Cat6",
        "power": "Cabo de alimentação",
        "power_center": "Centro de Poder",
        "power_strip": "Filtro de linha",
        "printer": "Impressora",
        "smart_plug": "Mini plugue inteligente",
        "smart_power_strip": "Filtro de linha inteligente",
        "stylus": "Caneta",
        "tablet": "Comprimido",
        "tablet accessory": "Acessório para tablet",
        "thunderbolt_cable": "USB-C para Thunderbolt",
        "travel_router": "Roteador de viagem",
        "usb hub": "Hub USB",
        "usb_adapter": "Adaptador USB",
        "usb_hub": "Hub USB",
        "webcam": "Webcam"
    }
}