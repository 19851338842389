<template>
    <div class="approvals-list-item-prior-approvals pt-3">
        <div v-if="0 === approvals.length" class="no-approvals">{{t('empty')}}</div>

        <approvals-list-item-prior-approvals-item v-for="approval in approvals"
                                                  :approval="approval"
                                                  :static-fields="staticFields"
                                                  :editable-fields="editableFields"/>
    </div>
</template>

<script>

    import ApprovalsListItemPriorApprovalsItem
        from "@/components/approvals/list/item/prior/approvals/ApprovalsListItemPriorApprovalsItem";

    export default {
        name: "ApprovalsListItemPriorApprovals",
        components: {
            ApprovalsListItemPriorApprovalsItem
        },
        props: {
            approvals: {
                type: Array,
                required: true
            },
            staticFields: {
                type: Array,
                required: true
            },
            editableFields: {
                type: Array,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.approvals.list.item.prior.approvals"
            };
        }
    }
</script>