export default {
    user: {
        create: {
            title: "新用户",
            model: {
                email: {
                    label: "电子邮件",
                    placeholder: "填写邮箱"
                },
                name: {
                    label: "姓名",
                    placeholder: "填写姓名"
                },
                country: {
                    label: "国家",
                    placeholder: "填写国家"
                },
                approval_manager_email: {
                    label: "经理邮箱",
                    placeholder: "填写经理邮箱",
                    errors: {
                        different: "审批经理电子邮件必须与用户电子邮件不同"
                    }
                }
            },
            cta: {
                submit: {
                    text: "提交",
                    processing: "提交"
                },
                cancel: {
                    text: "取消"
                }
            }
        }
    }
}