export default {
    error: "Beim Abrufen der Bestellungen ist ein Problem aufgetreten. Bitte aktualisieren Sie Ihren Browser.",
    meta: {
        default: "Bestellungen",
        my_orders: "Meine Bestellungen",
        my_gear: "Erhalten"
    },
    loadMore: "Mehr laden",
    noOrders: "Keine Bestellungen",
    order: {
        product: {
            title: "Befehl",
            tracking: "Sendungsverfolgung",
            quantity: "Menge",
            transfer: {
                sender: "Vorheriger Besitzer",
                receiver: "Empfänger",
                transferrer: "Übertragender",
                system: "Hilfe-Center"
            },
            control: {
                cancel: {
                    cta: "Klicken Sie hier, um abzubrechen",
                    tooltip: "Klicken Sie hier, um diesen Artikel zu stornieren",
                    message: "Wir haben Ihre Bestellung storniert."
                },
                cancelRequest: {
                    cta: "Stornierungsanfrage",
                    tooltip: "Klicken Sie hier, um eine Stornierungsanfrage einzureichen"
                },
                received: {
                    cta: "Als erhalten markieren",
                    tooltip: "Klicken Sie hier, um die Lieferung zu bestätigen",
                    message: "Vielen Dank für die Bestätigung, dass Sie Ihr Produkt erhalten haben."
                },
                notReceived: {
                    cta: "Als nicht erhalten markieren",
                    tooltip: "Klicken Sie hier, um die Nichtlieferung zu bestätigen"
                },
                damaged: {
                    cta: "Als beschädigt/kaputt markieren",
                    tooltip: "Klicken Sie hier, um ein Support-Ticket zu erstellen"
                },
                hide: {
                    cta: "Artikel ausblenden",
                    tooltip: "Klicken Sie hier, um das Element aus der Liste auszublenden"
                },
                transfer: {
                    cta: "Überweisen",
                    tooltip: "Klicken Sie hier, um den Artikel an einen anderen Mitarbeiter zu übertragen"
                }
            },
            requestToCancelInfo: "Wir werden den Lieferanten kontaktieren und eine Stornierung beantragen. Bitte beachten Sie, dass es sich nur um eine Anfrage handelt und eine Stornierung möglicherweise nicht möglich ist. Sobald wir feststellen können, ob die Stornierung möglich ist oder nicht, werden wir Sie informieren.",
            cancelOk: "OK",
            reason: "Grund",
            orderedBy: "Bestellt von",
            model: {
                cancel: {
                    reason: {
                        label: "Gründe für die Stornierung",
                        placeholder: "Gründe für die Stornierung"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Einreichen",
                        processing: "Einreichen"
                    },
                    cancel: {
                        cta: "Stornieren"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>Haben Sie Fragen zu Ihrer Bestellung?<br/> Hilfe erhalten Sie in unserem <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Support Center</b></a></p><p> Benötigen Sie ein Produkt, das nicht aufgeführt ist?<br/> Klicken Sie <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>hier,</b></a> um einen Vorschlag zu senden</p>",
        email: {
            subject: ""
        }
    }
}