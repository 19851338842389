export default {
    disclaimer: {
        label_default: "Ich nehme zur Kenntnis und stimme zu, dass es sich bei den von mir bestellten/erhaltenen Artikeln um Eigentum von O-I handelt, das für O-I arbeitsbezogene Aktivitäten verwendet werden soll, und dass sich O-I daher das Recht vorbehält, die Rückgabe des Eigentums bei meinem Ausscheiden aus O-I (oder früher, falls von O-I verlangt) zu verlangen.",
        label_type_1: "Ich bestätige, dass die Selbsteinschätzung für die Heimarbeit genau und nach bestem Wissen und Gewissen durchgeführt wurde und dass ich alle Geräte oder Möbel, die von O-I bereitgestellt oder über Gear bestellt wurden, für meine Arbeit bei O-I verwenden werde. Ich erkenne an und stimme zu, dass die Artikel, die ich bestelle/erhalte, Eigentum von O-I sind, das für O-I arbeitsbezogene Aktivitäten verwendet werden soll, und dass sich O-I daher das Recht vorbehält, die Rückgabe des Eigentums bei meinem Ausscheiden aus O-I (oder früher, falls von O-I verlangt) zu verlangen.",
        errors: {
            required: "Bitte akzeptieren Sie die Nutzungsbedingungen"
        }
    },
    cost_center: {
        label: "Kostenstellencode",
        placeholder: "Kostenstellencode",
        cost_center_not_found: "Es kann bis zu 24 Stunden dauern, bis nicht vorhandene Kostenstellen angezeigt werden. Wenden Sie sich an den Global Service Desk, wenn Sie die entsprechende Kostenstelle nicht sehen.",
        errors: {
            regex: "Ungültiger Kostenstellencode"
        }
    },
    company_code: {
        label: "Buchungskreis"
    },
    cost_center_name: {
        label: "Kostenstellenname"
    },
    order_note: {
        label: "Bestellhinweis",
        placeholder: "Optionale Notiz an den Manager zur Genehmigung",
    },
    send_emails_to_oobo_orderer: {
        "OOBO": "Benachrichtigen Sie mich über Updates zu dieser Bestellung"
    }
}