export default {
    login: "Přihlášení",
    logout: "Odhlášení",
    home: "Domov",
    user: {
        profile: {
            edit: "Upravit profil"
        }
    },
    approvals: {
        approvals: "Schválení",
        delegates: "Delegáti"
    },
    impersonation: "Objednávejte jménem"
}