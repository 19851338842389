export default {
    error: "Při načítání objednávek došlo k problému. Obnovte prosím svůj prohlížeč.",
    title: "Historie objednávek",
    loadMore: "Načíst více",
    noOrders: "Žádné objednávky",
    user: {
        loadMore: "Načíst více",
        product: {
            title: "Objednávka",
            tracking: "Sledování",
            quantity: "Množství",
            reason: "Důvod",
            transfer: {
                sender: "Odesílatel",
                receiver: "Přijímač"
            },
            control: {
                cancel: {
                    cta: "Klikněte pro zrušení",
                    tooltip: "Kliknutím tuto položku zrušíte",
                    message: "Vaši objednávku jsme zrušili."
                },
                received: {
                    cta: "Označit jako přijaté",
                    tooltip: "Kliknutím potvrdíte doručení",
                    message: "Děkujeme za potvrzení, že jste produkt obdrželi."
                },
                notReceived: {
                    cta: "Označit jako nepřijaté",
                    tooltip: "Kliknutím potvrdíte nedoručení"
                },
                hide: {
                    cta: "Skrýt položku",
                    tooltip: "Kliknutím skryjete položku ze seznamu"
                }
            }
        }
    }
}