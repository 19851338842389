export default {
    login: "Login",
    logout: "Uitloggen",
    home: "Thuis",
    user: {
        profile: {
            edit: "Profiel bewerken"
        }
    },
    approvals: {
        approvals: "Goedkeuringen",
        delegates: "Afgevaardigden"
    },
    impersonation: "Bestelling namens"
}