export default {
    menu: {
        approvals: "Approvazioni",
        delegates: "Delegati"
    },
    approvals: {
        title: "Approvazione",
        error: "Si è verificato un errore"
    },
    delegates: {
        title: "Delegati",
        error: "Si è verificato un errore",
        noDelegates: "Nessun oggetto",
        delegateDelete: "Il delegato è stato eliminato",
        confirm: {
            delete: "Eliminare il delegato?"
        },
        delegates: {
            email: "E-mail",
            startDate: "Data d'inizio",
            endDate: "Data di fine",
        },
        buttons: {
            add: {
                text: "Aggiungi delegato"
            }
        }
    }
}