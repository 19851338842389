export default {
    title: "Megrendelés elküldve",
    header: {
        success: "@:views.order.submitted.title",
        error: "A megrendelés függőben van"
    },
    message: {
        success: "Megrendelését elküldtük. Frissítésekért tekintse meg e-mailjét vagy a <a href='{url}'>kezdőlapon</a> található irányítópultot.",
        error: {
            stock: "Megrendelését leadtuk, <b>kivéve</b> az alább felsorolt tételeket, mivel ezek elfogytak. Kérjük, válasszon más választást ezeknek a termékeknek a megrendeléséhez. Frissítésekért tekintse meg e-mailjét vagy a <a href='{url}'>kezdőlapon</a> található irányítópultot.",
            status: "Megvannak a rendelési adatai, és problémát észleltünk a vezetői megbízással kapcsolatban. A GEAR IT-vel együtt dolgozunk a probléma megoldásán."
        }
    }
}