<template>
    <div>
        <div class="approvals-list-item">
            <approvals-list-item-name :approval="approval"/>

            <approvals-list-item-order :approval="approval"
                                       :static-fields="staticFields"
                                       :editable-fields="editableFields">
                <approvals-list-item-prior-cta :approval="approval"
                                               :config="config"
                                               :display="prior.display"
                                               @display="prior.display = $event"
                                               @loaded="prior.approvals = $event"/>
            </approvals-list-item-order>

            <approvals-list-item-details :approval="approval"/>

            <approvals-list-item-dynamic v-for="field in editableFields"
                                         :model-value="modelValue"
                                         :validator="v$.modelValue[field.payload.field]"
                                         :approval="approval"
                                         :field="field"
                                         :disabled="disabled"/>

            <approvals-list-item-approve :approved="modelValue.approved"
                                         :reason="modelValue.reason"
                                         :validator-reason="v$.modelValue.reason"
                                         :approval="approval"
                                         :disabled="disabled"
                                         @approved="modelValue.approved = $event"
                                         @reason="modelValue.reason = $event"/>
        </div>

        <approvals-list-item-prior-approvals v-if="true === prior.display"
                                             :approvals="prior.approvals"
                                             :static-fields="staticFields"
                                             :editable-fields="editableFields"/>
    </div>
</template>

<script>

    import useVuelidate from "@vuelidate/core";
    import {requiredIf} from "@vuelidate/validators";

    import mixin_validator from "@/helpers/validator";
    import mixin_dynamic_field from "@/mixins/dynamic_field"

    import ApprovalsListItemApprove from "@/components/approvals/list/item/ApprovalsListItemApprove";
    import ApprovalsListItemDetails from "@/components/approvals/list/item/ApprovalsListItemDetails";
    import ApprovalsListItemDynamic from "@/components/approvals/list/item/ApprovalsListItemDynamic";
    import ApprovalsListItemName from "@/components/approvals/list/item/ApprovalsListItemName";
    import ApprovalsListItemOrder from "@/components/approvals/list/item/ApprovalsListItemOrder";
    import ApprovalsListItemPriorApprovals from "@/components/approvals/list/item/prior/ApprovalsListItemPriorApprovals";
    import ApprovalsListItemPriorCta from "@/components/approvals/list/item/prior/ApprovalsListItemPriorCta";

    export default {
        name: "ApprovalsListItem",
        mixins: [mixin_validator, mixin_dynamic_field],
        emits: ["update:modelValue"],
        components: {
            ApprovalsListItemApprove,
            ApprovalsListItemDetails,
            ApprovalsListItemDynamic,
            ApprovalsListItemName,
            ApprovalsListItemOrder,
            ApprovalsListItemPriorApprovals,
            ApprovalsListItemPriorCta
        },
        props: {
            modelValue: {},
            config: {
                type: Array,
                required: true
            },
            approval: {
                type: Object,
                required: true
            },
            editableFields: {
                type: Array,
                required: true
            },
            staticFields: {
                type: Array,
                required: true
            },
            disabled: Boolean
        },
        setup() {

            return {
                v$: useVuelidate()
            };
        },
        data() {

            return {
                t_path: "components.approvals.list.item",
                prior: {
                    display: false,
                    approvals: []
                }
            };
        },
        validations() {

            return {
                modelValue: {
                    reason: {
                        required: requiredIf(() => false === this.modelValue.approved)
                    },
                    ...this.editableFields.reduce(
                        (approvalConfigResult, config) => {

                            approvalConfigResult[config.payload.field] = this.validator_createDynamicValidationRules(
                                config.validation,
                                {}
                            );

                            approvalConfigResult[config.payload.field] = this.validator_applyDynamicMask(
                                config.mask,
                                approvalConfigResult[config.payload.field]
                            );

                            return approvalConfigResult;
                        },
                        {}
                    )
                }
            };
        },
        watch: {
            "v$.modelValue": {
                deep: true,
                handler() {

                    this.v$.$touch();

                    // Update dependant dynamic fields
                    Object
                        .keys(this.modelValue)
                        .filter(key => "undefined" !== typeof this.approval.dynamic[key])
                        .forEach(key => this.approval.dynamic[key] = this.modelValue[key]);

                    this.modelValue.valid = !this.v$.modelValue.$invalid;

                    this.$emit("update:modelValue", this.modelValue);
                }
            }
        },
        mounted() {

            this.config.forEach(
                field => this.dynamic_field_watch(field, `modelValue`)
            );
        }
    }
</script>