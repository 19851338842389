import {getStorage} from "@/api/storage";
import {platform} from "@/api/platform";
import {retryRequest} from "@/helpers/request";
import {PERMISSIONS} from "@/constants";

const STORAGE_COUNTRY_KEY = "user_country";

export const INITIAL_PROFILE_VERSION = "1.0";
export const INITIAL_EQUIPMENT_VERSION = "1.0";

export default {
    namespaced: true,
    state: {
        loaded: false,
        name: null,
        email: null,
        language: null,
        country: null,
        workplace: null,
        priority: null,
        priorityDetails: null,
        secondaryPriority: null,
        secondaryPriorityDetails: null,
        improveWorkspace: null,
        improveRemoteWorking: null,

        // Headset
        receivedEquipment: null,
        issues: null,
        issuesDescription: null,
        // Keyboard and mouse
        receivedKeyboardMouse: null,
        issuesKeyboardMouse: null,
        issuesDescriptionKeyboardMouse: null,
        // Webcam
        receivedWebcam: null,
        issuesWebcam: null,
        issuesDescriptionWebcam: null,
        // Monitor
        receivedMonitor: null,
        issuesMonitor: null,
        issuesDescriptionMonitor: null,
        // Usbhub
        receivedUsbhub: null,
        issuesUsbhub: null,
        issuesDescriptionUsbhub: null,

        profileVersions: null,
        profile_submitted: null,
        allowed_profile_version: null,
        profile_submitted_version: null,

        equipmentVersions: null,
        equipment_submitted: null,
        allowed_equipment_version: null,
        equipment_submitted_version: null,
    },
    getters: {
        loaded: state => state.loaded,
        name: state => state.name,
        firstName: state => null !== state.name ? state.name.split(" ")[0] : null,
        email: state => state.email,
        language: state => state.language,
        country: state => state.country ? state.country : getStorage().get(STORAGE_COUNTRY_KEY, null),
        workplace: state => state.workplace,
        priority: state => state.priority,
        priorityDetails: state => state.priorityDetails,
        secondaryPriority: state => state.secondaryPriority,
        secondaryPriorityDetails: state => state.secondaryPriorityDetails,
        improveWorkspace: state => state.improveWorkspace,
        improveRemoteWorking: state => state.improveRemoteWorking,
        profileVersions: state => state.profileVersions,
        equipmentVersions: state => state.equipmentVersions,

        // Headset
        receivedEquipment: state => state.receivedEquipment,
        issues: state => state.issues,
        issuesDescription: state => state.issuesDescription,
        // Keyboard and mouse
        receivedKeyboardMouse: state => state.receivedKeyboardMouse,
        issuesKeyboardMouse: state => state.issuesKeyboardMouse,
        issuesDescriptionKeyboardMouse: state => state.issuesDescriptionKeyboardMouse,
        // Webcam
        receivedWebcam: state => state.receivedWebcam,
        issuesWebcam: state => state.issuesWebcam,
        issuesDescriptionWebcam: state => state.issuesDescriptionWebcam,
        // Monitor
        receivedMonitor: state => state.receivedMonitor,
        issuesMonitor: state => state.issuesMonitor,
        issuesDescriptionMonitor: state => state.issuesDescriptionMonitor,
        // Usbhub
        receivedUsbhub: state => state.receivedUsbhub,
        issuesUsbhub: state => state.issuesUsbhub,
        issuesDescriptionUsbhub: state => state.issuesDescriptionUsbhub,

        // Profile versioning
        profile_submitted: state => state.profile_submitted,
        equipment_submitted: state => !!(state.equipment_submitted && state.country),
        profile_submitted_version: state => {

            if (!state.profile_submitted_version) {

                return state.profile_submitted ? INITIAL_PROFILE_VERSION : null;
            }

            return state.profile_submitted_version;
        },
        equipment_submitted_version: state => {

            if (!state.equipment_submitted_version) {

                return state.equipment_submitted ? INITIAL_EQUIPMENT_VERSION : null;
            }

            return state.equipment_submitted_version;
        },
        allowed_profile_version: state => {

            if (state.allowed_profile_version) {

                return state.allowed_profile_version;
            }

            return INITIAL_PROFILE_VERSION;
        },
        allowed_equipment_version: state => {

            if (state.allowed_equipment_version) {

                return state.allowed_equipment_version;
            }

            return INITIAL_EQUIPMENT_VERSION;
        },

        // Quiz
        forceQuizProfile: state => {

            // Skype message from Gary: breaking news; for now we are NOT going to ask the profile type questions anywhere. Just country (+ entity for India) and zero or more equipment questions based on the tag and profile version.
            return false;

            if (!state.profile_submitted || !state.country) {

                // Profile was never submitted
                return true;
            }

            // Retrieve submitted profile version
            const profileSubmittedVersion = state.profile_submitted_version || state.profileVersions.default;
            const allowedProfileVersion = state.profileVersions[state.country]
                ? state.profileVersions[state.country]
                : state.profileVersions.default;

            return profileSubmittedVersion != allowedProfileVersion;
        },
        forceQuizEquipment: (state, getters, rootState) => {

            if (-1 === rootState.user.permissions.permissions.indexOf(PERMISSIONS.EMPLOYEE)) {

                return false;
            }

            if (!state.equipment_submitted || !state.country) {

                // Profile was never submitted
                return true;
            }

            // Retrieve submitted profile version
            const equipmentSubmittedVersion = state.equipment_submitted_version || state.equipmentVersions.default;
            const allowedEquipmentVersion = state.equipmentVersions[state.country]
                ? state.equipmentVersions[state.country]
                : state.equipmentVersions.default;

            return equipmentSubmittedVersion != allowedEquipmentVersion;
        },


        profile: state => JSON.parse(JSON.stringify(state))
    },
    mutations: {
        updateStorage: state => getStorage().set(STORAGE_COUNTRY_KEY, state.country)
    },
    actions: {
        fetch(context) {

            return Promise.all([
                retryRequest(() => platform.get("/api/v2/widget/style-profile"), undefined, undefined, "get:/api/v2/widget/style-profile"),
                retryRequest(() => platform.get("/api/v2/profileversion"), undefined, undefined, "get:/api/v2/profileversion"),
                retryRequest(() => platform.get("/api/v2/equipmentversion"), undefined, undefined, "get:/api/v2/equipmentversion")
            ]).then(responses => {

                context.state.loaded = true;

                const [profileResponse, profileVersionsResponse, equipmentVersionsResponse] = responses;

                const profile = profileResponse.data.results;
                const profileVersions = profileVersionsResponse.data;
                const equipmentVersions = equipmentVersionsResponse.data;

                // Process profile version
                context.state.profileVersions = profileVersions;
                context.state.equipmentVersions = equipmentVersions;

                Object.keys(profile).forEach(key => context.state[key] = profile[key]);

                // Set boolean to understand profile already loaded
                context.state.profile_submitted = !!profile.profile_submitted;
                context.state.equipment_submitted = !!profile.equipment_submitted;

                context.commit("updateStorage");

                if (this.getters["user/permissions/hasPermission"](PERMISSIONS.EMPLOYEE)) {

                    if (!!(profile.country && profile.equipment_submitted)) {

                        return this.dispatch("user/address/fetch");
                    }
                }
            });
        },
        post(context, payload) {

            return retryRequest(() => platform.post("/api/v2/widget/style-profile", payload)).then(response => {

                const profile = response.data.results;

                Object.keys(profile).forEach(key => context.state[key] = profile[key]);

                context.commit("updateStorage");

                if (!!(response.data.results.country && response.data.results.profile_submitted)) {

                    return this.dispatch("user/address/fetch");
                }

                return response.data.results;
            })
        },
        clear(context) {

            Object.keys(context.state).forEach(key => context.state[key] = null);

            context.commit("updateStorage");
        }
    }
}