export default {
    login: "Conecte-se",
    logout: "Sair",
    home: "Lar",
    user: {
        profile: {
            edit: "Editar Perfil"
        }
    },
    approvals: {
        approvals: "Aprovações",
        delegates: "Delegados"
    },
    impersonation: "Ordem em nome de"
}