export default {
    ordered: {
        type: {
            "ac_router": "Ho già ordinato un router AC diverso",
            "chair": "Ho già ordinato una sedia diversa",
            "default": "Già ordinato",
            "default_multiple": "Ho già ordinato prodotti simili",
            "desk": "Ho già ordinato una scrivania diversa",
            "desktop": "Ho già ordinato un desktop diverso",
            "desktop_riser": "Ho già ordinato un supporto per scrivania diverso",
            "detector": "Ho già ordinato diversi rilevatori di fumo e rumore",
            "displayport_cable": "Ho già ordinato un cavo DisplayPort diverso",
            "docking station": "Ho già ordinato una docking station diversa",
            "dual_usb_adapter": "Ho già ordinato un adattatore USB dual band diverso",
            "ear cushion": "Ho già ordinato un cuscinetto auricolare diverso",
            "footrest": "Ho già ordinato un poggiapiedi diverso",
            "hdmi_cable": "Ho già ordinato diversi cavi HDMI",
            "hdmi_displayport_cable": "Ho già ordinato un cavo HDMI-DisplayPort diverso",
            "hdmi_vga_cable": "Ho già ordinato un cavo HDMI-VGA diverso",
            "headset": "Ho già ordinato un dispositivo audio diverso",
            "keyboard": "Ho già ordinato una tastiera diversa",
            "kvm switch": "Ho già ordinato un diverso switch KVM",
            "kvm_switch": "Ho già ordinato un diverso switch KVM",
            "lamp": "Ho già ordinato una lampada diversa",
            "laptop stand": "Ho già ordinato un supporto per laptop diverso",
            "laptop": "Ho già ordinato un altro portatile",
            "mesh_router": "Ho già ordinato un router mesh diverso",
            "monitor": "Ho già ordinato un monitor diverso",
            "mouse": "Ho già ordinato diversi mouse",
            "patch_cord": "Ho già ordinato un diverso cavo patch cat6",
            "power": "Ho già ordinato un cavo di alimentazione diverso",
            "power_center": "Ho già ordinato diversi Power Center",
            "power_strip": "Ho già ordinato una ciabatta elettrica diversa",
            "printer": "Ho già ordinato una stampante diversa",
            "smart_plug": "Ho già ordinato diversi mini smart plug",
            "smart_power_strip": "Ho già ordinato diverse prese elettriche intelligenti",
            "stylus": "Ho già ordinato uno stilo diverso",
            "tablet": "Ho già ordinato un tablet diverso",
            "tablet accessory": "Ho già ordinato un accessorio diverso per il tablet",
            "thunderbolt_cable": "Ho già ordinato un cavo USB-C a Thunderbolt diverso",
            "travel_router": "Ho già ordinato un router da viaggio diverso",
            "usb hub": "Ho già ordinato un hub USB diverso",
            "usb_adapter": "Ho già ordinato un adattatore USB diverso",
            "usb_hub": "Ho già ordinato un hub diverso",
            "webcam": "Ho già ordinato una webcam diversa"
        },
        product: "Ordinato"
    },
    request: "Richiesta",
    add: "Aggiungi al carrello",
    remove: "Rimuovi dal carrello",
    added: "Aggiunto al carrello",
    view: "Visualizza elemento"
}