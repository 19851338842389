export default {
    namespaced: true,
    state: {
        enabled: false
    },
    getters: {
        enabled: state => state.enabled
    },
    mutations: {
        setEnabled: (state, enabled) => state.enabled = enabled
    },
    actions: {
        setEnabled(context, enabled) {

            context.commit("setEnabled", enabled);
        },
        clear(context) {

            context.commit("setEnabled", false);
        }
    }
}