export default {
    error: "Se produjo un problema al recuperar los pedidos. Actualice su navegador.",
    meta: {
        default: "Pedidos",
        my_orders: "Mis pedidos",
        my_gear: "Recibió"
    },
    loadMore: "Cargar más",
    noOrders: "Sin pedidos",
    order: {
        product: {
            title: "Orden",
            tracking: "Seguimiento",
            quantity: "Cantidad",
            transfer: {
                sender: "Propietario anterior",
                receiver: "Receptor",
                transferrer: "Transferencia",
                system: "Mesa de ayuda"
            },
            control: {
                cancel: {
                    cta: "Haga clic para cancelar",
                    tooltip: "Haga clic para cancelar este artículo",
                    message: "Hemos cancelado su pedido."
                },
                cancelRequest: {
                    cta: "Solicitud de cancelación",
                    tooltip: "Haga clic para enviar una solicitud de cancelación"
                },
                received: {
                    cta: "Marcar como recibido",
                    tooltip: "Haga clic para confirmar la entrega",
                    message: "Gracias por confirmar que recibió su producto."
                },
                notReceived: {
                    cta: "Marcar como no recibido",
                    tooltip: "Haga clic para confirmar la no entrega"
                },
                damaged: {
                    cta: "Marcar como dañado/roto",
                    tooltip: "Haga clic para crear un ticket de soporte"
                },
                hide: {
                    cta: "Ocultar artículo",
                    tooltip: "Haga clic para ocultar el elemento de la lista"
                },
                transfer: {
                    cta: "Transferir",
                    tooltip: "Haga clic para transferir el artículo a otro empleado"
                }
            },
            requestToCancelInfo: "Nos pondremos en contacto con el proveedor para solicitar la cancelación. Tenga en cuenta que se trata de una solicitud únicamente y que es posible que la cancelación no sea posible. Una vez que podamos determinar si se puede cancelar o no, se lo informaremos.",
            cancelOk: "De acuerdo",
            reason: "Razón",
            orderedBy: "Ordenado por",
            model: {
                cancel: {
                    reason: {
                        label: "Motivos de cancelación",
                        placeholder: "Motivos de cancelación"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Entregar",
                        processing: "Sumisión"
                    },
                    cancel: {
                        cta: "Cancelar"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>¿Tiene preguntas sobre su pedido?<br/> Consulte nuestro <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Centro de soporte</b></a> para obtener ayuda</p><p> ¿Necesita un producto que no está en el listado?<br/> Haga clic <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>aquí</b></a> para enviar una sugerencia</p>",
        email: {
            subject: ""
        }
    }
}