import mitt from "mitt"

const emitter = mitt();

export const EVENTS = {
    USER_ORDER_SUBMIT: "user.order.submit",
    USER_ORDER_CANCEL: "user.order.cancel",
    USER_ORDER_RECEIVED: "user.order.received",
    USER_ORDER_NOT_RECEIVED: "user.order.not_received",
    USER_ORDER_UNDO: "user.order.undo",
    USER_ORDER_HIDE: "user.order.hide",
    USER_ORDER_TRANSFER: "user.order.transfer",
    USER_CONSTRAINTS_FETCH: "user.constraints.fetch",
    USER_IMPERSONATION_ORDER_CANCEL: "user.impersonation.order.cancel",
    USER_IMPERSONATION_ORDER_RECEIVED: "user.impersonation.order.received",
    USER_IMPERSONATION_ORDER_UNDO: "user.impersonation.order.undo"
};

export default emitter;