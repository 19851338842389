export default {
    menu: {
        approvals: "批准",
        delegates: "代表"
    },
    approvals: {
        title: "赞同",
        error: "发生错误"
    },
    delegates: {
        title: "代表",
        error: "发生错误",
        noDelegates: "没有相关产品",
        delegateDelete: "代表已被删除",
        confirm: {
            delete: "删除代表？"
        },
        delegates: {
            email: "电子邮件",
            startDate: "开始日期",
            endDate: "结束日期",
        },
        buttons: {
            add: {
                text: "添加代表"
            }
        }
    }
}