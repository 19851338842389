export default {
    status: {
        acknowledged: "Ordered",
        created: "Created",
        sent: "Ordered",
        confirmed: "Ordered",
        not_confirmed: "Not Ordered",
        delivery_email: "Shipped",
        shipped: "Shipped",
        delayed: "Delayed",
        preparing: "Preparing",
        processing: "Processing",
        processed: "Processed",
        submitted: "Ordered",
        delivery_not_received: "Shipped",
        shipment_exception: "Shipped",
        pre_pending_approval: "Pending",
        pending_approval: "Pending",
        approved: "Approved",
        not_approved: "Not Approved",
        canceled: "Canceled",
        returned: "Returned",
        lost_shipment: "Lost Shipment",
        delivery_not_confirmed: "Delivered",
        delivered: "Delivered",
        delivery_received: "Delivered",
        refurbished: "Return completed",
        returned_to_office: "Returned to office",
        return_to_employer: "Returned to Employer",
        return_pickup_scheduled: "Pickup Scheduled",
        return_pickup_completed: "Pickup Completed",
        return_pickup_excluded: "Pickup Excluded",
        return_pickup_submitted: "Pickup Submitted",
        return_pickup_finalized: "Pickup Finalized",
        disposed: "Disposed",
        damaged: "Damaged",
        transferred: "Transferred",
        transfer_received: "Transfer Received"
    }
}