export default {
    user: {
        create: {
            title: "Neuer Benutzer",
            model: {
                email: {
                    label: "Email",
                    placeholder: "E-Mail ausfüllen"
                },
                name: {
                    label: "Name",
                    placeholder: "Füllen Sie den Namen aus"
                },
                country: {
                    label: "Land",
                    placeholder: "Land ausfüllen"
                },
                approval_manager_email: {
                    label: "E-Mail des Managers",
                    placeholder: "Manager-E-Mail ausfüllen",
                    errors: {
                        different: "Die E-Mail-Adresse des Genehmigungsmanagers muss sich von der E-Mail-Adresse des Benutzers unterscheiden."
                    }
                }
            },
            cta: {
                submit: {
                    text: "Einreichen",
                    processing: "Einreichen"
                },
                cancel: {
                    text: "Stornieren"
                }
            }
        }
    }
}