export default {
    menu: {
        approvals: "Approbations",
        delegates: "Délégués"
    },
    approvals: {
        title: "Approbation",
        error: "Une erreur s'est produite"
    },
    delegates: {
        title: "Délégués",
        error: "Une erreur s'est produite",
        noDelegates: "Pas d'objet",
        delegateDelete: "Le délégué a été supprimé",
        confirm: {
            delete: "Supprimer le délégué ?"
        },
        delegates: {
            email: "E-mail",
            startDate: "Date de début",
            endDate: "Date de fin",
        },
        buttons: {
            add: {
                text: "Ajouter un délégué"
            }
        }
    }
}