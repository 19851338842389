export default {
    status: {
        acknowledged: "有序",
        created: "创建",
        sent: "有序",
        confirmed: "有序",
        not_confirmed: "未订购",
        delivery_email: "已发货",
        shipped: "已发货",
        delayed: "延迟",
        preparing: "准备",
        processing: "加工",
        processed: "已处理",
        submitted: "有序",
        delivery_not_received: "已发货",
        shipment_exception: "已发货",
        pre_pending_approval: "待办的",
        pending_approval: "待办的",
        approved: "得到正式认可的",
        not_approved: "未批准",
        canceled: "取消",
        returned: "已返回",
        lost_shipment: "货物丢失",
        delivery_not_confirmed: "发表",
        delivered: "发表",
        delivery_received: "发表",
        refurbished: "已完成退货",
        returned_to_office: "重返办公室",
        return_to_employer: "退还给雇主",
        return_pickup_scheduled: "已安排取件",
        return_pickup_completed: "取件已完成",
        return_pickup_excluded: "不包括取货",
        return_pickup_submitted: "取件已提交",
        return_pickup_finalized: "取货已完成",
        disposed: "已处置",
        damaged: "损坏的",
        transferred: "已转移",
        transfer_received: "已收到转账"
    }
}