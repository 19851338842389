export default {
    types: {
        "ac_router": "Router AC",
        "chair": "Krzesło",
        "desk": "Biurko",
        "desktop": "Pulpit",
        "desktop_riser": "Podstawka pod biurko",
        "detector": "Detektor waporyzacji i hałasu",
        "displayport_cable": "Kabel Displayport",
        "docking station": "Stacja dokująca",
        "dual_usb_adapter": "Dwupasmowy adapter USB",
        "ear cushion": "Poduszka na uszy",
        "footrest": "Podnóżek",
        "hdmi_cable": "Kabel HDMI",
        "hdmi_displayport_cable": "Kabel HDMI do DisplayPort",
        "hdmi_vga_cable": "Kabel HDMI do VGA",
        "headset": "Zestaw słuchawkowy",
        "keyboard": "Klawiatura",
        "kvm switch": "Przełącznik KVM",
        "kvm_switch": "Przełącznik KVM",
        "lamp": "Lampa",
        "laptop stand": "Podstawka pod laptopa",
        "laptop": "Laptop",
        "mesh_router": "Router siatkowy",
        "monitor": "Monitor",
        "mouse": "Mysz",
        "patch_cord": "Przewód krosowy Cat6",
        "power": "Przewód zasilający",
        "power_center": "Centrum Mocy",
        "power_strip": "Listwa zasilająca",
        "printer": "Drukarka",
        "smart_plug": "Inteligentna wtyczka mini",
        "smart_power_strip": "Inteligentna listwa zasilająca",
        "stylus": "Igła",
        "tablet": "Tabletka",
        "tablet accessory": "Akcesorium do tabletu",
        "thunderbolt_cable": "USB-C do Thunderbolt",
        "travel_router": "Router podróżny",
        "usb hub": "Koncentrator USB",
        "usb_adapter": "Adapter USB",
        "usb_hub": "Koncentrator USB",
        "webcam": "Kamera internetowa"
    }
}