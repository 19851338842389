export default {
    type: {
        "ac_router": "Osiągnąłeś limit zamówień routerów AC",
        "chair": "Osiągnąłeś limit zamówień krzeseł",
        "default": "Osiągnąłeś limit zamówień",
        "default_multiple": "Osiągnąłeś limit zamówień podobnych produktów",
        "desk": "Osiągnąłeś limit zamówień biurek",
        "desktop": "Osiągnąłeś limit zamówień na komputery stacjonarne",
        "desktop_riser": "Osiągnąłeś limit zamówień podstawek pod biurko",
        "detector": "Osiągnąłeś limit zamówień detektorów waporyzatorów i hałasu",
        "displayport_cable": "Osiągnąłeś limit zamówień kabli DisplayPort",
        "docking station": "Osiągnąłeś limit zamówień stacji dokujących",
        "dual_usb_adapter": "Osiągnąłeś limit zamówień adapterów USB dwuzakresowych",
        "ear cushion": "Osiągnąłeś limit zamówień nakładek nausznych",
        "footrest": "Osiągnąłeś limit zamówień podnóżków",
        "hdmi_cable": "Osiągnąłeś limit zamówień kabli HDMI",
        "hdmi_displayport_cable": "Osiągnąłeś limit zamówień kabli HDMI do DisplayPort",
        "hdmi_vga_cable": "Osiągnąłeś limit zamówień kabli HDMI do VGA",
        "headset": "Osiągnąłeś limit zamówień urządzeń audio",
        "keyboard": "Osiągnąłeś limit zamówień klawiatur",
        "kvm switch": "Osiągnąłeś limit zamówień przełączników KVM",
        "kvm_switch": "Osiągnąłeś limit zamówień przełączników KVM",
        "lamp": "Osiągnąłeś limit zamówień lamp",
        "laptop stand": "Osiągnąłeś limit zamówień podstawek pod laptopy",
        "laptop": "Osiągnąłeś limit zamówień laptopów",
        "mesh_router": "Osiągnąłeś limit zamówień routerów siatkowych",
        "monitor": "Osiągnąłeś limit zamówień monitorów",
        "mouse": "Osiągnąłeś limit zamówień myszy",
        "patch_cord": "Osiągnąłeś limit zamówień kabli krosowych Cat6",
        "power": "Osiągnąłeś limit zamówień przewodów zasilających",
        "power_center": "Osiągnąłeś limit zamówień Power Centers",
        "power_strip": "Osiągnąłeś limit zamówień listew zasilających",
        "printer": "Osiągnąłeś limit zamówień drukarek",
        "smart_plug": "Osiągnąłeś limit zamówień mini wtyczek smart",
        "smart_power_strip": "Osiągnąłeś limit zamówień inteligentnych listew zasilających",
        "stylus": "Osiągnięto limit zamówień rysików",
        "tablet": "Osiągnąłeś limit zamówień tabletów",
        "tablet accessory": "Osiągnąłeś limit zamówień akcesoriów do tabletów",
        "thunderbolt_cable": "Osiągnięto limit zamówień USB-C do Thunderbolt",
        "travel_router": "Osiągnąłeś limit zamówień routerów podróżnych",
        "usb hub": "Osiągnąłeś limit zamówień hubów USB",
        "usb_adapter": "Osiągnąłeś limit zamówień adapterów USB",
        "usb_hub": "Osiągnąłeś limit zamówień hubów USB",
        "webcam": "Osiągnąłeś limit zamówień kamer internetowych"
    }
}