export default {
    user: {
        create: {
            title: "Nowy użytkownik",
            model: {
                email: {
                    label: "E-mail",
                    placeholder: "Wypełnij e-mail"
                },
                name: {
                    label: "Nazwa",
                    placeholder: "Wypełnij nazwę"
                },
                country: {
                    label: "Kraj",
                    placeholder: "Wypełnij kraj"
                },
                approval_manager_email: {
                    label: "Adres e-mail menedżera",
                    placeholder: "Wypełnij e-mail menedżera",
                    errors: {
                        different: "Adres e-mail menedżera zatwierdzającego musi różnić się od adresu e-mail użytkownika"
                    }
                }
            },
            cta: {
                submit: {
                    text: "Składać",
                    processing: "Przedkładający"
                },
                cancel: {
                    text: "Anulować"
                }
            }
        }
    }
}