export default {
    login: "Login",
    logout: "Logout",
    home: "Home",
    user: {
        profile: {
            edit: "Edit Profile"
        }
    },
    approvals: {
        approvals: "Approvals",
        delegates: "Delegates"
    },
    impersonation: "Order on behalf of"
}