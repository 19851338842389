<template>
    <div class="row">
        <div v-for="product in products" class="col-12 col-lg-6 col-xl-4 col-xxl-3 pb-4" :key="product.id">
            <products-list-product class="h-100" :value="product" :grouping="grouping" :grouping-id="groupingId"/>
        </div>
    </div>
</template>

<script>

    import ProductsListProduct from "@/components/products/ProductsListProduct";

    export default {
        name: "ProductsList",
        components: {
            ProductsListProduct
        },
        props: {
            value: {
                type: Array,
                required: true
            },
            grouping: {
                type: String,
                required: true,
            },
            groupingId: {}
        },
        data() {

            return {
                t_path: "components.products.list",
                products: []
            }
        },
        watch: {
            value: {
                immediate: true,
                deep: true,
                handler(products) {

                    this.products = products.map(product => ({
                        ...product,
                        estimated_ship_date: product.estimated_ship_date
                            ? {...product.estimated_ship_date, loading: true}
                            : {loading: true}
                    }));

                    const request = {
                        country: this.$store.getters["user/profile/country"],
                        items: products.map(product => ({sku: product.sku, tag: product.tag}))
                    };

                    this.$platform
                        .post("/api/v2/grouping/products/estimated-ship-date", request)
                        .then(response => {

                            response.data.results.forEach(item => {

                                const index = this.products.findIndex(product => product.sku == item.sku);

                                this.products[index].estimated_ship_date.message = item && item.estimated_ship_date
                                    ? item.estimated_ship_date.message
                                    : "";
                                this.products[index].estimated_ship_date.loading = false;
                            });
                        })
                        .catch(
                            error => this.products.forEach(
                                (product, index) => {

                                    this.products[index].estimated_ship_date.error = error.toString();
                                    this.products[index].estimated_ship_date.loading = false;
                                }
                            )
                        );
                }
            }
        }
    }
</script>