<template>
    <div class="pb-3 small text-center">{{label}}</div>
</template>

<script>
    export default {
        name: "TextLabel",
        props: {
            label: String
        }
    }
</script>