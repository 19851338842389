export default {
    disclaimer: {
        label_default: "Riconosco e accetto che gli articoli che sto ordinando/ricevendo sono O-I proprietà da utilizzare per O-I attività legate al lavoro e, in quanto tali, O-I si riserva il diritto di richiedere la restituzione della proprietà al momento della mia uscita da O-I (o prima, se richiesto da O-I).",
        label_type_1: "Confermo che l'autovalutazione per il lavoro da casa è stata completata in modo accurato e in buona fede e che qualsiasi attrezzatura o arredamento fornito dalla sede O-I o ordinato tramite Gear sarà da me utilizzato per lo scopo del mio lavoro con O-I. Riconosco e accetto che gli articoli che sto ordinando/ricevendo sono O-I proprietà da utilizzare per O-I attività correlate al lavoro e, in quanto tale, O-I si riserva il diritto di richiedere la restituzione della proprietà al momento della mia uscita da O-I (o prima, se richiesto da O-I).",
        errors: {
            required: "Si prega di accettare i termini di utilizzo"
        }
    },
    cost_center: {
        label: "Codice centro di costo",
        placeholder: "Codice centro di costo",
        cost_center_not_found: "I centri di costo non presenti potrebbero richiedere fino a 24 ore per apparire. Contatta il Global Service Desk se non vedi il centro di costo appropriato.",
        errors: {
            regex: "Codice centro di costo non valido"
        }
    },
    company_code: {
        label: "Codice Aziendale"
    },
    cost_center_name: {
        label: "Nome del centro di costo"
    },
    order_note: {
        label: "Nota d'ordine",
        placeholder: "Nota facoltativa al responsabile per l'approvazione",
    },
    send_emails_to_oobo_orderer: {
        "OOBO": "Avvisami con gli aggiornamenti su questo ordine"
    }
}