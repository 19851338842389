export default {
    error: "Si è verificato un problema durante il recupero degli ordini. Aggiorna il browser.",
    title: "Cronologia degli ordini",
    loadMore: "Carica altro",
    noOrders: "Nessun ordine",
    user: {
        loadMore: "Carica altro",
        product: {
            title: "Ordine",
            tracking: "Monitoraggio",
            quantity: "Quantità",
            reason: "Motivo",
            transfer: {
                sender: "Mittente",
                receiver: "Ricevitore"
            },
            control: {
                cancel: {
                    cta: "Clicca per annullare",
                    tooltip: "Clicca per annullare questo articolo",
                    message: "Abbiamo annullato il tuo ordine."
                },
                received: {
                    cta: "Contrassegna come ricevuto",
                    tooltip: "Clicca per confermare la consegna",
                    message: "Grazie per aver confermato di aver ricevuto il prodotto."
                },
                notReceived: {
                    cta: "Contrassegna come non ricevuto",
                    tooltip: "Clicca per confermare la mancata consegna"
                },
                hide: {
                    cta: "Nascondi elemento",
                    tooltip: "Fare clic per nascondere l'elemento dall'elenco"
                }
            }
        }
    }
}