export default {
    title: "Trasferisci prodotto",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Compila email",
            errors: {
                myself: "L'email deve essere diversa dalla tua",
                notfound: "L'utente non esiste"
            }
        },
        reason: {
            label: "Motivo",
            placeholder: "Motivo"
        }
    },
    message: "Il destinatario riceverà un'e-mail per informarlo del trasferimento.",
    saved: "Salvato",
    cta: {
        submit: {
            text: "Invia",
            processing: "Invio"
        },
        cancel: {
            text: "Cancellare"
        }
    }
}