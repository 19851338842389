export default {
    title: "Transferencia de producto",
    model: {
        email: {
            label: "Correo electrónico",
            placeholder: "Completar correo electrónico",
            errors: {
                myself: "El correo electrónico debe ser diferente al tuyo",
                notfound: "El usuario no existe"
            }
        },
        reason: {
            label: "Razón",
            placeholder: "Razón"
        }
    },
    message: "Se enviará un correo electrónico al destinatario informándole de la transferencia.",
    saved: "Guardado",
    cta: {
        submit: {
            text: "Entregar",
            processing: "Sumisión"
        },
        cancel: {
            text: "Cancelar"
        }
    }
}