export default {
    user: {
        create: {
            title: "Nouvel utilisateur",
            model: {
                email: {
                    label: "E-mail",
                    placeholder: "Remplir l'e-mail"
                },
                name: {
                    label: "Nom",
                    placeholder: "Remplissez le nom"
                },
                country: {
                    label: "Pays",
                    placeholder: "Remplir le pays"
                },
                approval_manager_email: {
                    label: "E-mail du responsable",
                    placeholder: "Remplir l'e-mail du responsable",
                    errors: {
                        different: "L'adresse e-mail du gestionnaire d'approbation doit être différente de l'adresse e-mail de l'utilisateur."
                    }
                }
            },
            cta: {
                submit: {
                    text: "Soumettre",
                    processing: "Soumission"
                },
                cancel: {
                    text: "Annuler"
                }
            }
        }
    }
}