export default {
    title: "Produkt transferowy",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Wypełnij adres e-mail",
            errors: {
                myself: "Adres e-mail musi różnić się od Twojego",
                notfound: "Użytkownik nie istnieje"
            }
        },
        reason: {
            label: "Powód",
            placeholder: "Powód"
        }
    },
    message: "Odbiorca otrzyma wiadomość e-mail informującą o przelewie.",
    saved: "Zapisane",
    cta: {
        submit: {
            text: "Składać",
            processing: "Przedkładający"
        },
        cancel: {
            text: "Anulować"
        }
    }
}