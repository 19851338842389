export default {
    error: "Se produjo un problema al recuperar los pedidos. Actualice su navegador.",
    title: "Historial de pedidos",
    loadMore: "Cargar más",
    noOrders: "Sin pedidos",
    user: {
        loadMore: "Cargar más",
        product: {
            title: "Orden",
            tracking: "Seguimiento",
            quantity: "Cantidad",
            reason: "Razón",
            transfer: {
                sender: "Remitente",
                receiver: "Receptor"
            },
            control: {
                cancel: {
                    cta: "Haga clic para cancelar",
                    tooltip: "Haga clic para cancelar este artículo",
                    message: "Hemos cancelado su pedido."
                },
                received: {
                    cta: "Marcar como recibido",
                    tooltip: "Haga clic para confirmar la entrega",
                    message: "Gracias por confirmar que recibió su producto."
                },
                notReceived: {
                    cta: "Marcar como no recibido",
                    tooltip: "Haga clic para confirmar la no entrega"
                },
                hide: {
                    cta: "Ocultar artículo",
                    tooltip: "Haga clic para ocultar el elemento de la lista"
                }
            }
        }
    }
}