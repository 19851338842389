export default {
    title: "Order Submitted",
    header: {
        success: "@:views.order.submitted.title",
        error: "Order is Pending",
    },
    message: {
        success: "Your order has been submitted. For updates, please check your email or the dashboard on the <a href='{url}'>Home</a> page.",
        error: {
            stock: "Your order was placed <b>except</b> for the items listed below as they are out of stock. Please make different selections to order these items. For updates, please check your email or the dashboard on the <a href='{url}'>Home</a> page.",
            status: "We have your order details and have noticed an issue with your manager assignment. We are working with GEAR IT to resolve the issue."
        }
    }
}