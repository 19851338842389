export default {
    types: {
        "ac_router": "AC router",
        "chair": "Židle",
        "desk": "Stůl",
        "desktop": "Desktop",
        "desktop_riser": "Stolní stoupačka",
        "detector": "Detektor výparů a hluku",
        "displayport_cable": "Displayport kabel",
        "docking station": "Dokovací stanice",
        "dual_usb_adapter": "Dvoupásmový USB adaptér",
        "ear cushion": "Ušní polštářek",
        "footrest": "Opěrka nohou",
        "hdmi_cable": "HDMI kabel",
        "hdmi_displayport_cable": "Kabel HDMI na DisplayPort",
        "hdmi_vga_cable": "Kabel HDMI na VGA",
        "headset": "Sluchátka",
        "keyboard": "Klávesnice",
        "kvm switch": "Kvm přepínač",
        "kvm_switch": "Kvm přepínač",
        "lamp": "Svítilna",
        "laptop stand": "Stojan na notebook",
        "laptop": "Přenosný počítač",
        "mesh_router": "Síťový router",
        "monitor": "Monitor",
        "mouse": "Myš",
        "patch_cord": "Patch kabel Cat6",
        "power": "Napájecí kabel",
        "power_center": "Power Center",
        "power_strip": "Napájecí proužek",
        "printer": "Tiskárna",
        "smart_plug": "Smart Plug mini",
        "smart_power_strip": "Inteligentní prodlužovací kabel",
        "stylus": "Stylus",
        "tablet": "Tableta",
        "tablet accessory": "Příslušenství k tabletu",
        "thunderbolt_cable": "USB-C na Thunderbolt",
        "travel_router": "Cestovní router",
        "usb hub": "Usb Hub",
        "usb_adapter": "USB adaptér",
        "usb_hub": "Usb Hub",
        "webcam": "Webová kamera"
    }
}