export default {
    login: "Login",
    logout: "Ausloggen",
    home: "Heim",
    user: {
        profile: {
            edit: "Profil bearbeiten"
        }
    },
    approvals: {
        approvals: "Zulassungen",
        delegates: "Delegierte"
    },
    impersonation: "Bestellung im Auftrag von"
}