export default {
    menu: {
        approvals: "Aprovações",
        delegates: "Delegados"
    },
    approvals: {
        title: "Aprovação",
        error: "Um erro ocorreu"
    },
    delegates: {
        title: "Delegados",
        error: "Um erro ocorreu",
        noDelegates: "Nenhum item",
        delegateDelete: "O delegado foi excluído",
        confirm: {
            delete: "Excluir delegado?"
        },
        delegates: {
            email: "E-mail",
            startDate: "Data de início",
            endDate: "Data final",
        },
        buttons: {
            add: {
                text: "Adicionar delegado"
            }
        }
    }
}