export default {
    ordered: {
        type: {
            "ac_router": "Heb al een andere AC Router besteld",
            "chair": "Heb al een andere stoel besteld",
            "default": "Al besteld",
            "default_multiple": "Heb al soortgelijke producten besteld",
            "desk": "Heb al een ander bureau besteld",
            "desktop": "Heb al een ander bureaublad besteld",
            "desktop_riser": "Heb al een andere desktopverhoger besteld",
            "detector": "Heb al een andere damp- en geluidsdetector besteld",
            "displayport_cable": "Heb al een andere displayportkabel besteld",
            "docking station": "Heb al een ander dockingstation besteld",
            "dual_usb_adapter": "Heb al een andere dual-band USB-adapter besteld",
            "ear cushion": "Heb al een ander oorkussen besteld",
            "footrest": "Heb al een andere voetenbank besteld",
            "hdmi_cable": "Heb al verschillende HDMI-kabels besteld",
            "hdmi_displayport_cable": "Heb al een andere HDMI naar DisplayPort kabel besteld",
            "hdmi_vga_cable": "Heb al een andere HDMI naar VGA kabel besteld",
            "headset": "Heb al een ander audioapparaat besteld",
            "keyboard": "Heb al een ander toetsenbord besteld",
            "kvm switch": "Heb al een andere kvm-switch besteld",
            "kvm_switch": "Heb al een andere kvm-switch besteld",
            "lamp": "Heb al een andere lamp besteld",
            "laptop stand": "Heb al een andere laptopstandaard besteld",
            "laptop": "Heb al een andere laptop besteld",
            "mesh_router": "Heb al een andere mesh-router besteld",
            "monitor": "Heb al een andere monitor besteld",
            "mouse": "Heb al verschillende muizen besteld",
            "patch_cord": "Heb al een andere cat6 patchkabel besteld",
            "power": "Heb al een ander netsnoer besteld",
            "power_center": "Heb al een ander Power Center besteld",
            "power_strip": "Heb al een andere stekkerdoos besteld",
            "printer": "Heb al een andere printer besteld",
            "smart_plug": "Heb al een andere smart plug mini besteld",
            "smart_power_strip": "Heb al een andere slimme stekkerdoos besteld",
            "stylus": "Heb al een andere stylus besteld",
            "tablet": "Heb al een andere tablet besteld",
            "tablet accessory": "Heb al een ander tablet accessoire besteld",
            "thunderbolt_cable": "Heb al een andere USB-C naar Thunderbolt kabel besteld",
            "travel_router": "Heb al een andere reisrouter besteld",
            "usb hub": "Heb al een andere usb hub besteld",
            "usb_adapter": "Heb al een andere usb adapter besteld",
            "usb_hub": "Heb al een andere hub besteld",
            "webcam": "Heb al een andere webcam besteld"
        },
        product: "Besteld"
    },
    request: "Verzoek",
    add: "In winkelwagen",
    remove: "Verwijderen uit winkelwagen",
    added: "Toegevoegd aan winkelwagen",
    view: "Item bekijken"
}