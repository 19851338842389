export default {
    title: "orden enviada",
    header: {
        success: "@:views.order.submitted.title",
        error: "El pedido está pendiente"
    },
    message: {
        success: "Su pedido ha sido enviado. Para obtener actualizaciones, consulte su correo electrónico o el panel de control en la página <a href='{url}'>de inicio</a> .",
        error: {
            stock: "Su pedido se realizó <b>excepto</b> los artículos que se enumeran a continuación, ya que están agotados. Haga selecciones diferentes para ordenar estos artículos. Para obtener actualizaciones, consulte su correo electrónico o el panel de control en la página <a href='{url}'>de inicio</a> .",
            status: "Tenemos los detalles de su pedido y hemos notado un problema con la asignación de su administrador. Estamos trabajando con GEAR IT para resolver el problema."
        }
    }
}