export default {
    title: "Objednávka odeslána",
    header: {
        success: "@:views.order.submitted.title",
        error: "Objednávka čeká na vyřízení"
    },
    message: {
        success: "Vaše objednávka byla odeslána. Aktualizace najdete ve svém e-mailu nebo na hlavním panelu na <a href='{url}'>domovské</a> stránce.",
        error: {
            stock: "Vaše objednávka byla zadána <b>kromě</b> níže uvedených položek, protože nejsou skladem. Pro objednání těchto položek proveďte jiný výběr. Aktualizace najdete ve svém e-mailu nebo na hlavním panelu na <a href='{url}'>domovské</a> stránce.",
            status: "Máme podrobnosti o vaší objednávce a zaznamenali jsme problém s vaším přidělením manažera. Na vyřešení problému pracujeme se společností GEAR IT."
        }
    }
}