export default {
    disclaimer: {
        label_default: "Przyjmuję do wiadomości i zgadzam się, że zamawiane/otrzymywane przeze mnie przedmioty są O-I własnością przeznaczoną do wykorzystania w celach O-I związanych z pracą, w związku z czym O-I zastrzega sobie prawo do żądania zwrotu własności po moim opuszczeniu O-I (lub wcześniej, jeśli będzie to wymagane przez O-I).",
        label_type_1: "Potwierdzam, że samoocena dotycząca pracy w domu została wypełniona dokładnie i w dobrej wierze, a wszelki sprzęt lub meble dostarczone z lokalizacji O-I lub zamówione za pośrednictwem Gear zostaną przeze mnie wykorzystane do celów mojej pracy z O-I. Przyjmuję do wiadomości i zgadzam się, że zamawiane/otrzymywane przeze mnie przedmioty są O-I własnością, która ma być wykorzystywana do O-I działań związanych z pracą, i jako takie O-I zastrzega sobie prawo do żądania zwrotu własności po moim opuszczeniu O-I (lub wcześniej, jeśli będzie to wymagane przez O-I).",
        errors: {
            required: "Proszę zaakceptować warunki użytkowania"
        }
    },
    cost_center: {
        label: "Kod Centrum Kosztów",
        placeholder: "Kod Centrum Kosztów",
        cost_center_not_found: "Brak centrów kosztów może potrwać do 24 godzin, zanim się pojawią. Skontaktuj się z Global Service Desk, jeśli nie widzisz odpowiedniego centrum kosztów.",
        errors: {
            regex: "Nieprawidłowy kod centrum kosztów"
        }
    },
    company_code: {
        label: "Kod spółki"
    },
    cost_center_name: {
        label: "Nazwa Centrum Kosztów"
    },
    order_note: {
        label: "Notatka dotycząca zamówienia",
        placeholder: "Opcjonalna notatka do kierownika w celu zatwierdzenia",
    },
    send_emails_to_oobo_orderer: {
        "OOBO": "Powiadom mnie o aktualizacjach tego zamówienia"
    }
}