<template>
    <form @submit.prevent="base_submit('model', 'submit', 'processing')">
        <div class="modals-modal-header">
            {{t('title')}}
        </div>

        <div class="modals-modal-body pt-4">
            <div class="row">
                <div class="col-12">
                    <form-textbox v-model="model.email"
                                  :textbox-class="'form-control form-control-sm'"
                                  :validator="v$.model.email"
                                  :label="t('model.email.label')"
                                  :placeholder="t('model.email.placeholder')"
                                  :errors="{myself:t('model.email.errors.myself')}"
                                  :disabled="processing"/>

                    <form-textarea v-model="model.reason"
                                   :textbox-class="'form-control form-control-sm'"
                                   :validator="v$.model.reason"
                                   :label="t('model.reason.label')"
                                   :placeholder="t('model.reason.placeholder')"
                                   :disabled="processing"/>
                </div>
            </div>

            <div class="text-center small">
                {{t('message')}}
            </div>
        </div>

        <div class="modals-modal-footer">
            <button type="submit" class="btn btn-primary" :disabled="processing">
                <span v-if="true === processing">
                    <spinner/>
                    {{t('cta.submit.processing')}}
                </span>
                <span v-else>
                    {{t('cta.submit.text')}}
                </span>
            </button>

            <button type="button" class="btn btn-primary" @click="$emit('close')">
                {{t('cta.cancel.text')}}
            </button>
        </div>
    </form>
</template>

<script>

    import useVuelidate from "@vuelidate/core";
    import {email, required} from "@vuelidate/validators";

    import FormTextbox from "@/components/form/FormTextbox";
    import Spinner from "@/components/Spinner";
    import FormTextarea from "@/components/form/FormTextarea.vue";

    export default {
        name: "ModalOrderProductTransfer",
        components: {
            FormTextarea,
            FormTextbox,
            Spinner
        },
        props: {
            orderProductId: {
                type: String,
                required: true
            }
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        data() {

            return {
                t_path: "modal.orderProductTransfer",
                processing: false,
                model: {
                    email: null,
                    reason: null
                }
            };
        },
        validations() {

            return {
                model: {
                    email: {
                        required,
                        email,
                        myself: value => value !== this.$store.getters["user/profile/email"]
                    },
                    reason: {
                        required,
                    }
                }
            }
        },
        methods: {
            submit() {

                this.processing = true;

                this.$store
                    .dispatch("user/orders/transfer", {
                        orderProductId: this.orderProductId,
                        email: this.model.email,
                        reason: this.model.reason
                    })
                    .then(() => {
                        this.$toast.success(this.t("saved"));
                        this.$emit("save");
                    })
                    .catch(error => {

                        if (error.response && 404 === error.response.status) {

                            return this.$toast.error(this.t("model.email.errors.notfound"));
                        }

                        this.base_error(error)
                    })
                    .finally(() => this.processing = false);
            }
        }
    }
</script>