export default {
    user: {
        create: {
            title: "Nuovo utente",
            model: {
                email: {
                    label: "E-mail",
                    placeholder: "Compila l'e-mail"
                },
                name: {
                    label: "Nome",
                    placeholder: "Compila il nome"
                },
                country: {
                    label: "Paese",
                    placeholder: "Compila il paese"
                },
                approval_manager_email: {
                    label: "E-mail del responsabile",
                    placeholder: "Compila l'e-mail del gestore",
                    errors: {
                        different: "L'e-mail del responsabile delle approvazioni deve essere diversa dall'e-mail dell'utente"
                    }
                }
            },
            cta: {
                submit: {
                    text: "Invia",
                    processing: "Invio"
                },
                cancel: {
                    text: "Annulla"
                }
            }
        }
    }
}