<template>
    <div class="approvals-list-item prior">
        <approvals-list-item-order :approval="approval"
                                   :static-fields="staticFields"
                                   :editable-fields="editableFields"/>

        <approvals-list-item-details :approval="approval"/>

        <approvals-list-item-prior-approvals-item-dynamic v-for="field in editableFields"
                                                          :approval="approval"
                                                          :field="field"/>

        <approvals-list-item-prior-approvals-item-status class="column-cta" :approval="approval"/>
    </div>
</template>

<script>

    import ApprovalsListItemOrder from "@/components/approvals/list/item/ApprovalsListItemOrder";
    import ApprovalsListItemDetails from "@/components/approvals/list/item/ApprovalsListItemDetails";
    import ApprovalsListItemPriorApprovalsItemDynamic from "@/components/approvals/list/item/prior/approvals/ApprovalsListItemPriorApprovalsItemDynamic";
    import ApprovalsListItemPriorApprovalsItemStatus from "@/components/approvals/list/item/prior/approvals/ApprovalsListItemPriorApprovalsItemStatus";

    export default {
        name: "ApprovalsListItemPriorApprovalsItem",
        components: {
            ApprovalsListItemPriorApprovalsItemStatus,
            ApprovalsListItemPriorApprovalsItemDynamic,
            ApprovalsListItemDetails,
            ApprovalsListItemOrder
        },
        props: {
            approval: {
                type: Object,
                required: true
            },
            staticFields: {
                type: Array,
                required: true
            },
            editableFields: {
                type: Array,
                required: true
            }
        }
    }
</script>