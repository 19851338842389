export default {
    disclaimer: {
        label_default: "Eu reconheço e concordo que os itens que estou pedindo/recebendo são O-I propriedade a ser usada para O-I atividades relacionadas ao trabalho e, como tal, O-I reserva-se o direito de solicitar a devolução da propriedade após minha saída de O-I (ou antes, se exigido por O-I).",
        label_type_1: "Confirmo que a autoavaliação para trabalho em casa foi concluída com precisão e de boa-fé, e qualquer equipamento ou mobília fornecida pelo local O-I ou encomendada pela Gear será usada por mim para o propósito do meu trabalho com O-I. Reconheço e concordo que os itens que estou encomendando/recebendo são propriedade O-I para serem usados em atividades relacionadas ao trabalho O-I e, como tal, O-I reserva-se o direito de solicitar a devolução da propriedade após minha saída de O-I (ou antes, se exigido por O-I).",
        errors: {
            required: "Por favor, aceite os termos de uso"
        }
    },
    cost_center: {
        label: "Código do centro de custo",
        placeholder: "Código do centro de custo",
        cost_center_not_found: "Centros de custos não presentes podem levar até 24 horas para aparecer. Entre em contato com o Global Service Desk se você não vir o centro de custos apropriado.",
        errors: {
            regex: "Código de centro de custo inválido"
        }
    },
    company_code: {
        label: "Código da empresa"
    },
    cost_center_name: {
        label: "Nome do centro de custo"
    },
    order_note: {
        label: "Nota do pedido",
        placeholder: "Nota opcional para aprovação do gerente",
    },
    send_emails_to_oobo_orderer: {
        "OOBO": "Notifique-me sobre atualizações sobre este pedido"
    }
}