export default {
    status: {
        acknowledged: "Ordenado",
        created: "Criado",
        sent: "Ordenado",
        confirmed: "Ordenado",
        not_confirmed: "Não ordenado",
        delivery_email: "Enviado",
        shipped: "Enviado",
        delayed: "Atrasado",
        preparing: "Preparando",
        processing: "Processamento",
        processed: "Processado",
        submitted: "Ordenado",
        delivery_not_received: "Enviado",
        shipment_exception: "Enviado",
        pre_pending_approval: "Pendente",
        pending_approval: "Pendente",
        approved: "Aprovado",
        not_approved: "Não aprovado",
        canceled: "Cancelado",
        returned: "Retornado",
        lost_shipment: "Envio Perdido",
        delivery_not_confirmed: "Entregue",
        delivered: "Entregue",
        delivery_received: "Entregue",
        refurbished: "Retorno concluído",
        returned_to_office: "Retornou ao escritório",
        return_to_employer: "Devolvido ao Empregador",
        return_pickup_scheduled: "Retirada agendada",
        return_pickup_completed: "Retirada concluída",
        return_pickup_excluded: "Retirada Excluída",
        return_pickup_submitted: "Retirada enviada",
        return_pickup_finalized: "Retirada finalizada",
        disposed: "Descartado",
        damaged: "Danificado",
        transferred: "Transferido",
        transfer_received: "Transferência Recebida"
    }
}