export default {
    empty: "Nada que aprobar",
    saved: "Aprobaciones presentadas",
    review: "Revise y edite el centro de costos si es incorrecto",
    confirm: {
        partial: "Aún quedan pedidos pendientes de aprobación. ¿Desea continuar con los cambios actuales?"
    },
    header: {
        name: "Nombre",
        order: "Orden",
        details: "Detalles",
        cta: "Aprobación",
        message: ""
    },
    item: {
        order: {
            manager: "Gerente",
            requested: "Solicitado por"
        },
        approve: {
            reason: {
                placeholder: {
                    default: "razón",
                    approved: "razón",
                    notapproved: "razón"
                }
            },
            approve: {
                yes: {
                    label: "Sí"
                },
                no: {
                    label: "No"
                }
            }
        },
        prior: {
            cta: {
                load: "Aprobaciones previas",
                loading: "Cargando",
                show: "Aprobaciones previas",
                hide: "Aprobaciones previas"
            },
            approvals: {
                empty: "Sin aprobaciones previas",
                item: {
                    status: {
                        approver: "por {approver}",
                        system: "Mesa de ayuda",
                        approved: "Aprobado",
                        rejected: "No aprobado",
                        unrequired: "Aprobación no requerida",
                        reason: "Razón",
                        transferred: "Transferido"
                    }
                }
            }
        }
    },
    cta: {
        submit: {
            text: "Entregar",
            processing: "Sumisión"
        }
    }
}