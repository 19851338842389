export default {
    type: {
        "ac_router": "Você atingiu o limite de pedidos de roteadores CA",
        "chair": "Você atingiu o limite de pedidos de cadeiras",
        "default": "Você atingiu o limite de pedidos",
        "default_multiple": "Você atingiu o limite de pedidos de produtos semelhantes",
        "desk": "Você atingiu o limite de pedidos de mesas",
        "desktop": "Você atingiu o limite de pedidos de desktop",
        "desktop_riser": "Você atingiu o limite de pedidos de risers de mesa",
        "detector": "Você atingiu o limite de pedidos de detectores de vapor e ruído",
        "displayport_cable": "Você atingiu o limite de pedidos de cabos displayport",
        "docking station": "Você atingiu o limite de pedidos de estações de encaixe",
        "dual_usb_adapter": "Você atingiu o limite de pedidos de adaptadores USB de banda dupla",
        "ear cushion": "Você atingiu o limite de pedidos de almofadas auriculares",
        "footrest": "Você atingiu o limite de pedidos de apoios para os pés",
        "hdmi_cable": "Você atingiu o limite de pedidos de cabos HDMI",
        "hdmi_displayport_cable": "Você atingiu o limite de pedidos de cabos HDMI para DisplayPort",
        "hdmi_vga_cable": "Você atingiu o limite de pedidos de cabos HDMI para VGA",
        "headset": "Você atingiu o limite de pedidos de dispositivos de áudio",
        "keyboard": "Você atingiu o limite de pedidos de teclados",
        "kvm switch": "Você atingiu o limite de pedidos de switches kvm",
        "kvm_switch": "Você atingiu o limite de pedidos de switches kvm",
        "lamp": "Você atingiu o limite de pedidos de lâmpadas",
        "laptop stand": "Você atingiu o limite de pedidos de suportes para laptop",
        "laptop": "Você atingiu o limite de pedidos de laptops",
        "mesh_router": "Você atingiu o limite de pedidos de roteadores mesh",
        "monitor": "Você atingiu o limite de pedidos de monitores",
        "mouse": "Você atingiu o limite de pedidos de mouses",
        "patch_cord": "Você atingiu o limite de pedidos de patch cords Cat6",
        "power": "Você atingiu o limite de pedidos de cabos de alimentação",
        "power_center": "Você atingiu o limite de pedidos de Power Centers",
        "power_strip": "Você atingiu o limite de pedidos de réguas de energia",
        "printer": "Você atingiu o limite de pedidos de impressoras",
        "smart_plug": "Você atingiu o limite de pedidos de mini plugues inteligentes",
        "smart_power_strip": "Você atingiu o limite de pedidos de réguas de energia inteligentes",
        "stylus": "Você atingiu o limite de pedidos de canetas",
        "tablet": "Você atingiu o limite de pedidos de tablets",
        "tablet accessory": "Você atingiu o limite de pedidos de acessórios para tablets",
        "thunderbolt_cable": "Você atingiu o limite de pedidos de USB-C para Thunderbolt",
        "travel_router": "Você atingiu o limite de pedidos de roteadores de viagem",
        "usb hub": "Você atingiu o limite de pedidos de hubs USB",
        "usb_adapter": "Você atingiu o limite de pedidos de adaptadores USB",
        "usb_hub": "Você atingiu o limite de pedidos de hubs USB",
        "webcam": "Você atingiu o limite de pedidos de webcams"
    }
}