export default {
    required: "È richiesto un valore",
    email: "Il valore non è un indirizzo email valido",
    mask: "Il valore non è valido",
    maxValue: "Il valore massimo è {maxValue}",
    regex: "Il valore non è valido",
    server: {
        DELEGATES_NOT_ENABLED: "I delegati non sono abilitati per questo partner.",
        DELEGATE_DOES_NOT_EXIST: "Il delegato non esiste",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Non è possibile aggiornare un delegato che non ti appartiene.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Non è possibile eliminare un delegato che non ti appartiene.",
        DELEGATE_ALREADY_EXISTS: "Esiste già una regola delegata che corrisponde a questo criterio.",
        DELEGATE_CANNOT_BE_SELF: "Non puoi aggiungerti come delegato.",
        IMPERSONATE_USER_NO_PERMISSIONS: "L'utente {email} non ha accesso a DXC Gear e non è possibile effettuare alcun ordine per suo conto",
        "Invalid user type": "Tipo di utente non valido",
        "User not found": "Utente non trovato"
    }
}