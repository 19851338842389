export default {
    type: {
        "ac_router": "U heeft de bestellimiet van AC-routers bereikt",
        "chair": "U heeft de bestellimiet van stoelen bereikt",
        "default": "U heeft de bestellimiet bereikt",
        "default_multiple": "U heeft de bestellimiet van vergelijkbare producten bereikt",
        "desk": "U heeft de bestellimiet van bureaus bereikt",
        "desktop": "U hebt de bestellimiet van desktop bereikt",
        "desktop_riser": "U heeft de bestellimiet van bureaubladverhogers bereikt",
        "detector": "U heeft de bestellimiet van damp- en geluidsdetectoren bereikt",
        "displayport_cable": "U heeft de bestellimiet van displayportkabels bereikt",
        "docking station": "U heeft de bestellimiet van dockingstations bereikt",
        "dual_usb_adapter": "U heeft de bestellimiet van dual-band USB-adapters bereikt",
        "ear cushion": "U heeft de bestellimiet van oorkussens bereikt",
        "footrest": "U heeft de bestellimiet van voetsteunen bereikt",
        "hdmi_cable": "U heeft de bestellimiet van HDMI-kabels bereikt",
        "hdmi_displayport_cable": "U hebt de bestellimiet van HDMI naar DisplayPort-kabels bereikt",
        "hdmi_vga_cable": "U hebt de bestellimiet van HDMI naar VGA-kabels bereikt",
        "headset": "U heeft de bestellimiet van audioapparaten bereikt",
        "keyboard": "U heeft de bestellimiet van toetsenborden bereikt",
        "kvm switch": "U heeft de bestellimiet van kvm-switches bereikt",
        "kvm_switch": "U heeft de bestellimiet van kvm-switches bereikt",
        "lamp": "U heeft de bestellimiet van lampen bereikt",
        "laptop stand": "U heeft de bestellimiet van laptopstandaards bereikt",
        "laptop": "U heeft de bestellimiet van laptops bereikt",
        "mesh_router": "U hebt de bestellimiet van mesh-routers bereikt",
        "monitor": "U heeft de bestellimiet van monitoren bereikt",
        "mouse": "U heeft de bestellimiet van muizen bereikt",
        "patch_cord": "U heeft de bestellimiet van Cat6 patchkabels bereikt",
        "power": "U heeft de bestellimiet van netsnoeren bereikt",
        "power_center": "U heeft de bestellimiet van Power Centers bereikt",
        "power_strip": "U heeft de bestellimiet van stekkerdozen bereikt",
        "printer": "U heeft de bestellimiet van printers bereikt",
        "smart_plug": "U heeft de bestellimiet van slimme stekkers mini bereikt",
        "smart_power_strip": "U heeft de bestellimiet van slimme stekkerdozen bereikt",
        "stylus": "U heeft de bestellimiet van stylussen bereikt",
        "tablet": "U heeft de bestellimiet van tablets bereikt",
        "tablet accessory": "U heeft de bestellimiet van tabletaccessoires bereikt",
        "thunderbolt_cable": "U hebt de bestellimiet van USB-C naar Thunderbolt bereikt",
        "travel_router": "U heeft de bestellimiet van reisrouters bereikt",
        "usb hub": "U heeft de bestellimiet van USB-hubs bereikt",
        "usb_adapter": "U heeft de bestellimiet van USB-adapters bereikt",
        "usb_hub": "U heeft de bestellimiet van USB-hubs bereikt",
        "webcam": "U heeft de bestellimiet van webcams bereikt"
    }
}