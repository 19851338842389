export default {
    title: "Bestelling ingediend",
    header: {
        success: "@:views.order.submitted.title",
        error: "Bestelling is in behandeling"
    },
    message: {
        success: "Uw bestelling is verzonden. Voor updates kunt u uw e-mail of het dashboard op de <a href='{url}'>startpagina</a> controleren.",
        error: {
            stock: "Uw bestelling is geplaatst, <b>met uitzondering</b> van de onderstaande artikelen, omdat deze niet op voorraad zijn. Maak verschillende keuzes om deze artikelen te bestellen. Voor updates kunt u uw e-mail of het dashboard op de <a href='{url}'>startpagina</a> controleren.",
            status: "We hebben uw bestelgegevens en hebben een probleem opgemerkt met uw managertoewijzing. We werken samen met GEAR IT om het probleem op te lossen."
        }
    }
}