export default {
    namespaced: true,
    state: {
        maxItems: 10
    },
    getters: {
        maxItems: state => state.maxItems
    },
    mutations: {
        setMaxItems: (state, maxItems) => state.maxItems = maxItems
    },
    actions: {
        setMaxItems(context, maxItems) {

            context.commit("setMaxItems", maxItems);
        },
        clear(context) {

            context.commit("setMaxItems", 10);
        }
    }
}