export default {
    title: "Ordine per conto di",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Si prega di compilare l'e-mail dell'utente per ordinare per conto di",
            errors: {
                myself: "L'email deve essere diversa dalla tua"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Invia",
            processing: "Invio"
        }
    },
    error: {
        userNotExists: "{email} non esiste",
        country: {
            default: "OI non è attualmente disponibile nel paese dell'utente",
            ALPHA3: ""
        }
    }
}