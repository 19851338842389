export default {
    error: "Wystąpił problem z pobieraniem zamówień. Odśwież przeglądarkę.",
    title: "Historia zamówień",
    loadMore: "Załaduj więcej",
    noOrders: "Brak zamówień",
    user: {
        loadMore: "Załaduj więcej",
        product: {
            title: "Zamówienie",
            tracking: "Śledzenie",
            quantity: "Ilość",
            reason: "Powód",
            transfer: {
                sender: "Nadawca",
                receiver: "Odbiornik"
            },
            control: {
                cancel: {
                    cta: "Kliknij, aby anulować",
                    tooltip: "Kliknij, aby anulować tę pozycję",
                    message: "Anulowaliśmy Twoje zamówienie."
                },
                received: {
                    cta: "Oznacz jako otrzymane",
                    tooltip: "Kliknij, aby potwierdzić dostawę",
                    message: "Dziękujemy za potwierdzenie otrzymania produktu."
                },
                notReceived: {
                    cta: "Oznacz jako nieotrzymane",
                    tooltip: "Kliknij, aby potwierdzić brak dostawy"
                },
                hide: {
                    cta: "Ukryj element",
                    tooltip: "Kliknij, aby ukryć element na liście"
                }
            }
        }
    }
}