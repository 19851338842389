export default {
    status: {
        acknowledged: "Ordinato",
        created: "Creato",
        sent: "Ordinato",
        confirmed: "Ordinato",
        not_confirmed: "Non ordinato",
        delivery_email: "Spedito",
        shipped: "Spedito",
        delayed: "Ritardato",
        preparing: "Preparazione",
        processing: "Elaborazione",
        processed: "Elaborato",
        submitted: "Ordinato",
        delivery_not_received: "Spedito",
        shipment_exception: "Spedito",
        pre_pending_approval: "In attesa di",
        pending_approval: "In attesa di",
        approved: "Approvato",
        not_approved: "Non approvato",
        canceled: "Annullato",
        returned: "Restituito",
        lost_shipment: "Spedizione persa",
        delivery_not_confirmed: "Consegnato",
        delivered: "Consegnato",
        delivery_received: "Consegnato",
        refurbished: "Ritorno completato",
        returned_to_office: "Rientrato in carica",
        return_to_employer: "Restituito al datore di lavoro",
        return_pickup_scheduled: "Ritiro programmato",
        return_pickup_completed: "Ritiro completato",
        return_pickup_excluded: "Ritiro escluso",
        return_pickup_submitted: "Ritiro inviato",
        return_pickup_finalized: "Ritiro finalizzato",
        disposed: "Disposto",
        damaged: "Danneggiato",
        transferred: "Trasferito",
        transfer_received: "Trasferimento ricevuto"
    }
}