export default {
    types: {
        "ac_router": "Enrutador de CA",
        "chair": "Silla",
        "desk": "Escritorio",
        "desktop": "De oficina",
        "desktop_riser": "Elevador de escritorio",
        "detector": "Detector de vapeo y ruido",
        "displayport_cable": "Cable DisplayPort",
        "docking station": "Estación de acoplamiento",
        "dual_usb_adapter": "Adaptador USB de banda dual",
        "ear cushion": "Almohadilla para la oreja",
        "footrest": "Reposapiés",
        "hdmi_cable": "Cable HDMI",
        "hdmi_displayport_cable": "Cable HDMI a DisplayPort",
        "hdmi_vga_cable": "Cable HDMI a VGA",
        "headset": "Auriculares",
        "keyboard": "Teclado",
        "kvm switch": "Conmutador KVM",
        "kvm_switch": "Conmutador KVM",
        "lamp": "Lámpara",
        "laptop stand": "Soporte para computadora portátil",
        "laptop": "Computadora portátil",
        "mesh_router": "Enrutador de malla",
        "monitor": "Monitor",
        "mouse": "Ratón",
        "patch_cord": "Cable de conexión Cat6",
        "power": "Cable de alimentación",
        "power_center": "Centro de poder",
        "power_strip": "Regleta de enchufes",
        "printer": "Impresora",
        "smart_plug": "Mini enchufe inteligente",
        "smart_power_strip": "Regleta inteligente",
        "stylus": "Aguja",
        "tablet": "Tableta",
        "tablet accessory": "Accesorio para tableta",
        "thunderbolt_cable": "USB-C a Thunderbolt",
        "travel_router": "Enrutador de viaje",
        "usb hub": "Concentrador USB",
        "usb_adapter": "Adaptador USB",
        "usb_hub": "Concentrador USB",
        "webcam": "Cámara web"
    }
}