<template>
    <a v-if="displayRequest" class="btn btn-sm btn-primary" target="_blank" rel="noopener" :href="value.url_link">
        {{t('request')}}
    </a>
    <div v-else-if="displayOrdered" class="btn btn-sm btn-primary d-inline-flex btn-cart-disabled pr-0 align-items-center">
        <div class="flex-grow-1">
            <span v-if="isOrderedProduct">{{t('ordered.product')}}</span>
            <span v-else>{{orderedType}}</span>
        </div>
        <div class="flex-grow-0 px-2">
            <products-product-limit :value="value"/>
        </div>
    </div>
    <router-link v-else-if="forceProductPage" class="btn btn-sm btn-primary btn-cart" :to="route">
        {{t('view')}}
    </router-link>
    <button v-else-if="displayAdd" type="button" class="btn btn-sm btn-primary" :disabled="disableAdd" @click="add">
        {{t('add')}}
    </button>
    <button v-else-if="displayRemove" type="button" class="btn btn-sm btn-outline-primary" @click="remove">
        {{t('remove')}}
    </button>
</template>

<script>

    import mixin_cart from "@/mixins/cart";
    import mixin_constraints from "@/mixins/constraints";
    import mixin_products from "@/mixins/products";
    import Spinner from "@/components/Spinner";

    import {PRODUCT_CLASS} from "@/constants";
    import ProductsProductLimit from "./ProductsProductLimit";

    export default {
        name: "ProductsProductCart",
        components: {
            ProductsProductLimit,
            Spinner
        },
        mixins: [mixin_cart, mixin_constraints, mixin_products],
        emits: ["add", "remove"],
        props: {
            value: {
                type: Object,
                required: true
            },
            grouping: String,
            groupingId: [Number, String],
            displayAddToast: Boolean,
            handleForceDetailPage: Boolean
        },
        data() {

            return {
                t_path: "components.products.cart",

                state: {
                    isOrderedProduct: null,
                    displayOrdered: null,
                    displayAdd: null,
                    disableAdd: null,
                    displayRemove: null
                },
            };
        },
        computed: {
            isLoading() {

                return this.$store.getters["user/orders/loading"] || this.$store.getters["user/constraints/loading"];
            },
            isOrderedProduct() {

                return this.isLoading
                    ? this.state.isOrderedProduct
                    : this.products_productOrdered(this.value);
            },
            displayRequest() {

                return this.value.class && PRODUCT_CLASS.LINKED_PRODUCT === this.value.class;
            },
            displayOrdered() {

                return this.isLoading
                    ? this.state.displayOrdered
                    : ("undefined" !== typeof this.value.allowed_to_order && false === this.value.allowed_to_order);
            },
            displayAdd() {

                return this.isLoading
                    ? this.state.displayAdd
                    : (
                        0 < this.constraints_filterAvailableProducts([this.value], true).length
                        || 1 < this.constraints_getProductTypeAvailableAmount(this.value)
                        || !this.$store.getters["user/cart/exists"](this.value)
                    );
            },
            disableAdd() {

                return this.isLoading
                    ? this.state.disableAdd
                    : 0 === this.constraints_filterAvailableProducts([this.value], true).length;
            },
            displayRemove() {

                return this.isLoading
                    ? this.state.displayRemove
                    : (
                        1 === this.constraints_getProductTypeAvailableAmount(this.value)
                        && !this.displayAdd
                        && this.$store.getters["user/cart/exists"](this.value)
                    );
            },
            type() {

                return this.products_translateProductType(this.value).toLowerCase();
            },
            orderedType() {

                if (1 < this.value.product_type.length) {

                    return this.t("ordered.type.default_multiple");
                }

                for (let i = 0; i < this.value.product_type.length; i ++) {

                    if (true === this.te(`ordered.type.${this.value.product_type[i]}`)) {

                        return this.t(`ordered.type.${this.value.product_type[i]}`);
                    }
                }

                return this.t("ordered.type.default");
            },
            forceProductPage() {

                return true === this.handleForceDetailPage
                    && 1 === parseInt(process.env.VUE_APP_CATALOG_FORCE_PRODUCT_PAGE);
            },
            route() {

                return {
                    name: "products.product",
                    params: {
                        grouping: this.grouping,
                        grouping_id: this.groupingId,
                        sku: this.value.sku
                    }
                };
            }
        },
        methods: {
            remove() {

                this.cart_remove(this.value);

                this.$emit("remove");
            },
            add() {

                if (this.displayAddToast) {

                    this.$toast.success(this.t("added"));
                }

                this.cart_add(this.value);

                this.$emit("add")
            }
        },
        watch: {
            isLoading: {
                immediate: true,
                handler(newValue) {

                    if (false === newValue) {

                        this.state.isOrderedProduct = this.isOrderedProduct;
                        this.state.displayOrdered = this.displayOrdered;
                        this.state.displayAdd = this.displayAdd;
                        this.state.disableAdd = this.disableAdd;
                        this.state.displayRemove = this.displayRemove;
                    }
                }
            }
        }
    }
</script>