export default {
    home: "Maison",
    order: {
        submit: "Réviser la commande"
    },
    tunnel: {
        loginVerification: "Identification",
        addressVerification: "Vérification de l'adresse"
    },
    catalog: "Catalogue",
    all: "Tous",
    return: {
        return: "Retour"
    },
    errors: {
        404: "@:views.errors.404.title"
    },
    impersonation: "Commande au nom de",
    approvals: "Approbations",
    delegate: "Délégués"
}