export default {
    type: {
        "ac_router": "Has alcanzado el límite de pedidos de enrutadores de CA",
        "chair": "Has alcanzado el límite de pedido de sillas.",
        "default": "Has alcanzado el límite de pedidos.",
        "default_multiple": "Has alcanzado el límite de pedidos de productos similares",
        "desk": "Has alcanzado el límite de pedidos de escritorios.",
        "desktop": "Has alcanzado el límite de pedidos de escritorio",
        "desktop_riser": "Has alcanzado el límite de pedidos de elevadores de escritorio.",
        "detector": "Has alcanzado el límite de pedidos de detectores de vapeo y ruido.",
        "displayport_cable": "Has alcanzado el límite de pedidos de cables DisplayPort",
        "docking station": "Has alcanzado el límite de pedidos de estaciones de acoplamiento.",
        "dual_usb_adapter": "Ha alcanzado el límite de pedidos de adaptadores USB de banda dual",
        "ear cushion": "Has alcanzado el límite de pedido de almohadillas para los oídos.",
        "footrest": "Has alcanzado el límite de pedido de reposapiés.",
        "hdmi_cable": "Has alcanzado el límite de pedidos de cables HDMI",
        "hdmi_displayport_cable": "Has alcanzado el límite de pedidos de cables HDMI a DisplayPort",
        "hdmi_vga_cable": "Has alcanzado el límite de pedidos de cables HDMI a VGA",
        "headset": "Has alcanzado el límite de pedidos de dispositivos de audio.",
        "keyboard": "Has alcanzado el límite de pedidos de teclados.",
        "kvm switch": "Ha alcanzado el límite de pedidos de conmutadores kvm",
        "kvm_switch": "Ha alcanzado el límite de pedidos de conmutadores kvm",
        "lamp": "Has alcanzado el límite de pedido de lámparas.",
        "laptop stand": "Has alcanzado el límite de pedidos de soportes para portátiles.",
        "laptop": "Has alcanzado el límite de pedidos de portátiles.",
        "mesh_router": "Has alcanzado el límite de pedidos de enrutadores de malla",
        "monitor": "Has alcanzado el límite de pedidos de monitores.",
        "mouse": "Has alcanzado el límite de pedidos de ratones.",
        "patch_cord": "Ha alcanzado el límite de pedido de cables de conexión Cat6",
        "power": "Has alcanzado el límite de pedidos de cables de alimentación",
        "power_center": "Has alcanzado el límite de pedidos de Power Centers",
        "power_strip": "Has alcanzado el límite de pedidos de regletas de enchufes",
        "printer": "Has alcanzado el límite de pedidos de impresoras.",
        "smart_plug": "Has alcanzado el límite de pedidos de enchufes inteligentes mini",
        "smart_power_strip": "Has alcanzado el límite de pedidos de regletas de enchufes inteligentes",
        "stylus": "Has alcanzado el límite de pedidos de lápices ópticos.",
        "tablet": "Has alcanzado el límite de pedidos de tabletas.",
        "tablet accessory": "Has alcanzado el límite de pedidos de accesorios para tabletas.",
        "thunderbolt_cable": "Has alcanzado el límite de pedidos de USB-C a Thunderbolt",
        "travel_router": "Has alcanzado el límite de pedidos de enrutadores de viaje",
        "usb hub": "Has alcanzado el límite de pedidos de concentradores USB",
        "usb_adapter": "Has alcanzado el límite de pedidos de adaptadores USB",
        "usb_hub": "Has alcanzado el límite de pedidos de concentradores USB",
        "webcam": "Has alcanzado el límite de pedidos de cámaras web"
    }
}