export default {
    error: "Beim Abrufen der Bestellungen ist ein Problem aufgetreten. Bitte aktualisieren Sie Ihren Browser.",
    title: "Bestellverlauf",
    loadMore: "Mehr laden",
    noOrders: "Keine Bestellungen",
    user: {
        loadMore: "Mehr laden",
        product: {
            title: "Befehl",
            tracking: "Sendungsverfolgung",
            quantity: "Menge",
            reason: "Grund",
            transfer: {
                sender: "Absender",
                receiver: "Empfänger"
            },
            control: {
                cancel: {
                    cta: "Klicken Sie hier, um abzubrechen",
                    tooltip: "Klicken Sie hier, um diesen Artikel zu stornieren",
                    message: "Wir haben Ihre Bestellung storniert."
                },
                received: {
                    cta: "Als erhalten markieren",
                    tooltip: "Klicken Sie hier, um die Lieferung zu bestätigen",
                    message: "Vielen Dank für die Bestätigung, dass Sie Ihr Produkt erhalten haben."
                },
                notReceived: {
                    cta: "Als nicht erhalten markieren",
                    tooltip: "Klicken Sie hier, um die Nichtlieferung zu bestätigen"
                },
                hide: {
                    cta: "Artikel ausblenden",
                    tooltip: "Klicken Sie hier, um das Element aus der Liste auszublenden"
                }
            }
        }
    }
}