export default {
    menu: {
        approvals: "Jóváhagyások",
        delegates: "Küldöttek"
    },
    approvals: {
        title: "Jóváhagyás",
        error: "Hiba lépett fel"
    },
    delegates: {
        title: "Küldöttek",
        error: "Hiba lépett fel",
        noDelegates: "Nincsenek tárgyak",
        delegateDelete: "A delegált törölve lett",
        confirm: {
            delete: "Törli a delegált?"
        },
        delegates: {
            email: "Email",
            startDate: "Kezdő dátum",
            endDate: "Befejezés dátuma",
        },
        buttons: {
            add: {
                text: "Képviselő hozzáadása"
            }
        }
    }
}