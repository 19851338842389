export default {
    error: "Wystąpił problem z pobieraniem zamówień. Odśwież przeglądarkę.",
    meta: {
        default: "Święcenia",
        my_orders: "Moje zamówienia",
        my_gear: "Otrzymane"
    },
    loadMore: "Załaduj więcej",
    noOrders: "Brak zamówień",
    order: {
        product: {
            title: "Zamówienie",
            tracking: "Śledzenie",
            quantity: "Ilość",
            transfer: {
                sender: "Poprzedni właściciel",
                receiver: "Odbiornik",
                transferrer: "Przekazujący",
                system: "Biuro pomocy"
            },
            control: {
                cancel: {
                    cta: "Kliknij, aby anulować",
                    tooltip: "Kliknij, aby anulować tę pozycję",
                    message: "Anulowaliśmy Twoje zamówienie."
                },
                cancelRequest: {
                    cta: "Prośba o anulowanie",
                    tooltip: "Kliknij, aby wysłać prośbę o anulowanie"
                },
                received: {
                    cta: "Oznacz jako otrzymane",
                    tooltip: "Kliknij, aby potwierdzić dostawę",
                    message: "Dziękujemy za potwierdzenie otrzymania produktu."
                },
                notReceived: {
                    cta: "Oznacz jako nieotrzymane",
                    tooltip: "Kliknij, aby potwierdzić brak dostawy"
                },
                damaged: {
                    cta: "Oznacz jako uszkodzone/zepsute",
                    tooltip: "Kliknij, aby utworzyć zgłoszenie pomocy technicznej"
                },
                hide: {
                    cta: "Ukryj element",
                    tooltip: "Kliknij, aby ukryć element na liście"
                },
                transfer: {
                    cta: "Przenosić",
                    tooltip: "Kliknij, aby przenieść element do innego pracownika"
                }
            },
            requestToCancelInfo: "Skontaktujemy się z dostawcą, aby poprosić o anulowanie. Należy pamiętać, że jest to tylko prośba i anulowanie może nie być możliwe. Gdy tylko ustalimy, czy można anulować, damy Ci znać.",
            cancelOk: "OK",
            reason: "Powód",
            orderedBy: "Zamówione przez",
            model: {
                cancel: {
                    reason: {
                        label: "Powody anulowania",
                        placeholder: "Powody anulowania"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Składać",
                        processing: "Przedkładający"
                    },
                    cancel: {
                        cta: "Anulować"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>Masz pytania dotyczące swojego zamówienia?<br/> Aby uzyskać pomoc, odwiedź nasze <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Centrum wsparcia</b></a></p><p> Czy potrzebujesz produktu, którego nie ma na liście?<br/> Kliknij <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>tutaj</b></a> , aby wysłać sugestię</p>",
        email: {
            subject: ""
        }
    }
}