export default {
    title: "Produkt übertragen",
    model: {
        email: {
            label: "E-Mail",
            placeholder: "E-Mail ausfüllen",
            errors: {
                myself: "Die E-Mail-Adresse muss sich von Ihrer",
                notfound: "Benutzer existiert nicht"
            }
        },
        reason: {
            label: "Grund",
            placeholder: "Grund"
        }
    },
    message: "Der Empfänger wird per E-Mail über die Überweisung informiert.",
    saved: "Gespeichert",
    cta: {
        submit: {
            text: "Einreichen",
            processing: "Einreichen"
        },
        cancel: {
            text: "Stornieren"
        }
    }
}