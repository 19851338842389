export default {
    disclaimer: {
        label_default: "Ik erken en ga ermee akkoord dat de artikelen die ik bestel/ontvang O-I eigendom zijn dat gebruikt kan worden voor O-I werkgerelateerde activiteiten, en als zodanig behoudt O-I zich het recht voor om teruggave van de eigendommen te eisen bij mijn vertrek uit O-I (of eerder, indien vereist door O-I).",
        label_type_1: "Ik bevestig dat de zelfevaluatie voor thuiswerken nauwkeurig en te goeder trouw is voltooid, en dat alle apparatuur of meubels die zijn geleverd door de locatie van O-I of besteld via Gear door mij worden gebruikt voor mijn werk met O-I. Ik erken en ga ermee akkoord dat de artikelen die ik bestel/ontvang O-I eigendom zijn die moeten worden gebruikt voor werkgerelateerde activiteiten van O-I, en als zodanig behoudt O-I zich het recht voor om de teruggave van de eigendommen te eisen bij mijn vertrek uit O-I (of eerder, indien vereist door O-I).",
        errors: {
            required: "Accepteer de gebruiksvoorwaarden"
        }
    },
    cost_center: {
        label: "Kostenplaatscode",
        placeholder: "Kostenplaatscode",
        cost_center_not_found: "Kostenplaatsen die niet aanwezig zijn, kunnen tot 24 uur nodig hebben om te verschijnen. Neem contact op met de Global Service Desk als u het juiste kostenplaats niet ziet.",
        errors: {
            regex: "Ongeldige kostenplaatscode"
        }
    },
    company_code: {
        label: "Bedrijfscode"
    },
    cost_center_name: {
        label: "Kostenplaatsnaam"
    },
    order_note: {
        label: "Bestelnotitie",
        placeholder: "Optionele notitie voor goedkeuring aan manager",
    },
    send_emails_to_oobo_orderer: {
        "OOBO": "Stuur mij updates over deze bestelling"
    }
}