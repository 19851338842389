export default {
    error: "Probléma lépett fel a rendelések lekérésekor. Kérjük, frissítse a böngészőt.",
    title: "Rendelési előzmények",
    loadMore: "Load More",
    noOrders: "Nincs rendelés",
    user: {
        loadMore: "Load More",
        product: {
            title: "Rendelés",
            tracking: "Követés",
            quantity: "Mennyiség",
            reason: "Ok",
            transfer: {
                sender: "Feladó",
                receiver: "Vevő"
            },
            control: {
                cancel: {
                    cta: "Kattintson a visszavonáshoz",
                    tooltip: "Kattintson az elem törléséhez",
                    message: "Megrendelését töröltük."
                },
                received: {
                    cta: "Megjelölés átvettként",
                    tooltip: "Kattintson a kézbesítés megerősítéséhez",
                    message: "Köszönjük, hogy megerősítette, hogy megkapta termékét."
                },
                notReceived: {
                    cta: "Megjelölés meg nem érkezettként",
                    tooltip: "Kattintson a nem kézbesítés megerősítéséhez"
                },
                hide: {
                    cta: "Elem elrejtése",
                    tooltip: "Kattintson az elem elrejtéséhez a listából"
                }
            }
        }
    }
}