export default {
    namespaced: true,
    state: {
        config: []
    },
    getters: {
        config: state => state.config
    },
    mutations: {
        setConfig: (state, config) => state.config = config
    },
    actions: {
        setConfig(context, config) {

            context.commit("setConfig", config);
        },
        clear(context) {

            context.commit("setConfig", []);
        }
    }
}