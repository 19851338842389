export default {
    title: "Auftrag gesendet",
    header: {
        success: "@:views.order.submitted.title",
        error: "Bestellung steht aus"
    },
    message: {
        success: "Ihre Bestellung wurde übermittelt. Aktuelle Informationen finden Sie in Ihrem E-Mail-Postfach oder im Dashboard auf der <a href='{url}'>Startseite</a> .",
        error: {
            stock: "Ihre Bestellung wurde aufgegeben, <b>mit Ausnahme</b> der unten aufgeführten Artikel, da diese nicht vorrätig sind. Bitte treffen Sie eine andere Auswahl, um diese Artikel zu bestellen. Für Updates überprüfen Sie bitte Ihre E-Mail oder das Dashboard auf der <a href='{url}'>Startseite</a> .",
            status: "Wir haben Ihre Bestelldetails und haben ein Problem mit Ihrer Managerzuweisung festgestellt. Wir arbeiten mit GEAR IT zusammen, um das Problem zu lösen."
        }
    }
}