<template>
    <div v-if="description" class="description">
        <div class="text truncate" :style="{maxHeight:maxHeight}" ref="description">
            <span v-html="formattedDescription"></span>&nbsp;<router-link v-if="displayMultilineDescription" :to="route">
                {{t('more')}}
            </router-link>

            <router-link v-if="true === displayMoreAbsolute" class="link" :to="route">
                {{t('more')}}
            </router-link>
        </div>
    </div>
</template>

<script>

    export default {
        name: "ProductsListProductDescription",
        props: {
            route: {
                type: Object,
                required: true
            },
            description: String,
            maxHeight: Number
        },
        data() {

            return {
                t_path: "components.products.list.product.description",
                displayReadMore: false,
                timeout: null
            }
        },
        computed: {
            formattedDescription() {

                // Display description only before line break
                return this.description
                    .split("<br />")[0]
                    .split("<br/>")[0]
                    .split("<br>")[0];
            },
            forceProductPage() {

                return 1 === parseInt(process.env.VUE_APP_CATALOG_FORCE_PRODUCT_PAGE);
            },
            displayMoreAbsolute() {

                return false === this.forceProductPage
                    && false === this.isMultilineDescription
                    && true === this.displayReadMore;
            },
            isMultilineDescription() {

                return /\<br[\s]*\/?\>/.test(this.description);
            },
            displayMultilineDescription() {

                return false === this.forceProductPage && true === this.isMultilineDescription;
            }
        },
        methods: {
            handleDisplayReadMore() {

                if (this.$refs.description) {

                    const $clone = this.$refs.description.cloneNode(true);
                    const computedStyle = window.getComputedStyle(this.$refs.description);
                    const rect = this.$refs.description.getBoundingClientRect();

                    $clone.classList.remove("truncate");

                    $clone.style.position = "absolute";
                    $clone.style.width = computedStyle["width"];
                    $clone.style.top = 0;
                    $clone.style.left = -2 * (rect.left + parseInt(computedStyle["width"]));

                    document.body.appendChild($clone);
                    this.displayReadMore = parseInt(computedStyle["height"]) !== parseInt(window.getComputedStyle($clone)["height"])
                    document.body.removeChild($clone);
                }
            }
        },
        watch: {
            description: {
                immediate: true,
                handler() {

                    clearInterval(this.timeout);
                    this.timeout = setInterval(() => {

                        if (this.$refs.description) {

                            this.handleDisplayReadMore();
                            clearInterval(this.timeout);
                        }
                    }, 10);
                }
            }
        }
    }
</script>