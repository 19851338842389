export default {
    empty: "Niets om goed te keuren",
    saved: "Goedkeuringen ingediend",
    review: "Controleer en bewerk het kostencentrum als het onjuist is",
    confirm: {
        partial: "Er zijn nog orders met goedkeuringen in behandeling. Wilt u doorgaan met uw huidige wijzigingen?"
    },
    header: {
        name: "Naam",
        order: "Volgorde",
        details: "Details",
        cta: "Goedkeuring",
        message: ""
    },
    item: {
        order: {
            manager: "Manager",
            requested: "Op verzoek van"
        },
        approve: {
            reason: {
                placeholder: {
                    default: "reden",
                    approved: "reden",
                    notapproved: "reden"
                }
            },
            approve: {
                yes: {
                    label: "Ja"
                },
                no: {
                    label: "Nee"
                }
            }
        },
        prior: {
            cta: {
                load: "Voorafgaande goedkeuringen",
                loading: "Laden",
                show: "Voorafgaande goedkeuringen",
                hide: "Voorafgaande goedkeuringen"
            },
            approvals: {
                empty: "Geen voorafgaande goedkeuringen",
                item: {
                    status: {
                        approver: "door {approver}",
                        system: "Helpdesk",
                        approved: "Goedgekeurd",
                        rejected: "Niet goedgekeurd",
                        unrequired: "Goedkeuring niet vereist",
                        reason: "Reden",
                        transferred: "Overgedragen"
                    }
                }
            }
        }
    },
    cta: {
        submit: {
            text: "Indienen",
            processing: "Verzenden"
        }
    }
}