<template>
    <div>
        <!-- Approval -->
        <div v-if="null !== status" class="small pt-1 font-weight-bold">
            {{status}}
        </div>

        <!-- Approver -->
        <div v-if="null !== approver" class="small pt-1">
            <div>{{t('approver', {approver: approver})}}</div>
        </div>

        <!-- Reason -->
        <div v-if="null !== reason" class="small pt-1">
            <div class="pr-1">{{t('reason')}}:</div>

            <div>{{reason}}</div>
        </div>
    </div>
</template>

<script>

    import {get} from "lodash";

    export default {
        name: "ApprovalsListItemPriorApprovalsItemStatus",
        props: {
            approval: {
                type: Object,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.approvals.list.item.prior.approvals.item.status"
            };
        },
        computed: {
            status() {

                if (true === this.approval.transfer.enabled) {

                    return this.t("transferred");
                }

                if (
                    "boolean" === typeof this.approval.approval.required
                    && false === this.approval.approval.required
                ) {

                    return this.t("unrequired");
                }

                if ("boolean" === typeof this.approval.approval.approved) {

                    return true === this.approval.approval.approved ? this.t("approved") : this.t("rejected");
                }

                return null;
            },
            reason() {

                return this.approval.approval.reason ? this.approval.approval.reason : null;
            },
            approver() {

                const email = get(this.approval, "approval.approver.email")
                    ? get(this.approval, "approval.approver.email").toLowerCase()
                    : "";

                if ("" !== email && this.$store.getters["user/profile/email"].toLowerCase() !== email) {

                    return "system" === email
                        ? this.t("system")
                        : email;
                }

                return null;
            }
        }
    }
</script>