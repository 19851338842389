export default {
    home: "Casa",
    order: {
        submit: "Revisione dell'ordine"
    },
    tunnel: {
        loginVerification: "Verifica dell'accesso",
        addressVerification: "Verifica dell'indirizzo"
    },
    catalog: "Catalogare",
    all: "Tutto",
    return: {
        return: "Ritorno"
    },
    errors: {
        404: "@:views.errors.404.title"
    },
    impersonation: "Ordine per conto di",
    approvals: "Approvazioni",
    delegate: "Delegati"
}