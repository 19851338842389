export default {
    ordered: {
        type: {
            "ac_router": "Habe bereits einen anderen AC-Router bestellt",
            "chair": "Habe bereits einen anderen Stuhl bestellt",
            "default": "Bereits bestellt",
            "default_multiple": "Habe bereits ähnliche Produkte bestellt",
            "desk": "Habe bereits einen anderen Schreibtisch bestellt",
            "desktop": "Habe bereits einen anderen Desktop bestellt",
            "desktop_riser": "Habe bereits einen anderen Desktop-Riser bestellt",
            "detector": "Habe bereits verschiedene Dampf- und Geräuschdetektoren bestellt",
            "displayport_cable": "Habe bereits ein anderes DisplayPort-Kabel bestellt",
            "docking station": "Habe bereits eine andere Dockingstation bestellt",
            "dual_usb_adapter": "Habe bereits einen anderen Dualband-USB-Adapter bestellt",
            "ear cushion": "Habe bereits andere Ohrpolster bestellt",
            "footrest": "Habe bereits eine andere Fußstütze bestellt",
            "hdmi_cable": "Habe bereits verschiedene HDMI-Kabel bestellt",
            "hdmi_displayport_cable": "Habe bereits ein anderes HDMI-zu-DisplayPort-Kabel bestellt",
            "hdmi_vga_cable": "Habe bereits ein anderes HDMI-zu-VGA-Kabel bestellt",
            "headset": "Habe bereits ein anderes Audiogerät bestellt",
            "keyboard": "Habe bereits eine andere Tastatur bestellt",
            "kvm switch": "Habe bereits einen anderen KVM-Switch bestellt.",
            "kvm_switch": "Habe bereits einen anderen KVM-Switch bestellt.",
            "lamp": "Habe bereits eine andere Lampe bestellt",
            "laptop stand": "Habe bereits einen anderen Laptopständer bestellt",
            "laptop": "Habe bereits einen anderen Laptop bestellt",
            "mesh_router": "Habe bereits einen anderen Mesh-Router bestellt",
            "monitor": "Habe bereits einen anderen Monitor bestellt",
            "mouse": "Habe bereits verschiedene Mäuse bestellt",
            "patch_cord": "Habe bereits ein anderes Cat6-Patchkabel bestellt",
            "power": "Habe bereits ein anderes Netzkabel bestellt",
            "power_center": "Habe bereits ein anderes Power Center bestellt",
            "power_strip": "Habe bereits eine andere Steckdosenleiste bestellt",
            "printer": "Habe bereits einen anderen Drucker bestellt",
            "smart_plug": "Habe bereits einen anderen Smart Plug Mini bestellt",
            "smart_power_strip": "Habe bereits eine andere intelligente Steckdosenleiste bestellt",
            "stylus": "Habe bereits einen anderen Stift bestellt",
            "tablet": "Habe bereits ein anderes Tablet bestellt",
            "tablet accessory": "Habe bereits anderes Tablet-Zubehör bestellt",
            "thunderbolt_cable": "Habe bereits ein anderes USB-C-zu-Thunderbolt-Kabel bestellt",
            "travel_router": "Habe bereits einen anderen Reiserouter bestellt",
            "usb hub": "Habe bereits einen anderen USB-Hub bestellt",
            "usb_adapter": "Habe bereits einen anderen USB-Adapter bestellt",
            "usb_hub": "Habe bereits einen anderen Hub bestellt",
            "webcam": "Habe bereits eine andere Webcam bestellt"
        },
        product: "Bestellt"
    },
    request: "Anfrage",
    add: "In den Warenkorb legen",
    remove: "Aus dem Warenkorb entfernen",
    added: "Zum Warenkorb hinzugefügt",
    view: "Artikel anzeigen"
}