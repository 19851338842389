export default {
    types: {
        "ac_router": "Routeur CA",
        "chair": "Chaise",
        "desk": "Bureau",
        "desktop": "Bureau",
        "desktop_riser": "Support de bureau",
        "detector": "Détecteur de vapeur et de bruit",
        "displayport_cable": "Câble DisplayPort",
        "docking station": "Station d'accueil",
        "dual_usb_adapter": "Adaptateur USB double bande",
        "ear cushion": "Coussin d'oreille",
        "footrest": "Repose-pieds",
        "hdmi_cable": "Câble HDMI",
        "hdmi_displayport_cable": "Câble HDMI vers DisplayPort",
        "hdmi_vga_cable": "Câble HDMI vers VGA",
        "headset": "Casque d'écoute",
        "keyboard": "Clavier",
        "kvm switch": "Commutateur KVM",
        "kvm_switch": "Commutateur KVM",
        "lamp": "Lampe",
        "laptop stand": "Support pour ordinateur portable",
        "laptop": "Ordinateur portable",
        "mesh_router": "Routeur maillé",
        "monitor": "Moniteur",
        "mouse": "Souris",
        "patch_cord": "Câble de raccordement Cat6",
        "power": "Cordon d'alimentation",
        "power_center": "Centre de pouvoir",
        "power_strip": "Multiprise",
        "printer": "Imprimante",
        "smart_plug": "Mini prise intelligente",
        "smart_power_strip": "Multiprise intelligente",
        "stylus": "Style",
        "tablet": "Comprimé",
        "tablet accessory": "Accessoire pour tablette",
        "thunderbolt_cable": "USB-C vers Thunderbolt",
        "travel_router": "Routeur de voyage",
        "usb hub": "Concentrateur USB",
        "usb_adapter": "Adaptateur USB",
        "usb_hub": "Concentrateur USB",
        "webcam": "Webcam"
    }
}