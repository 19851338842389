export default {
    status: {
        acknowledged: "Rendelve",
        created: "Létrehozva",
        sent: "Rendelve",
        confirmed: "Rendelve",
        not_confirmed: "Nincs megrendelve",
        delivery_email: "Szállítva",
        shipped: "Szállítva",
        delayed: "Késleltetett",
        preparing: "Felkészülés",
        processing: "Feldolgozás",
        processed: "Feldolgozott",
        submitted: "Rendelve",
        delivery_not_received: "Szállítva",
        shipment_exception: "Szállítva",
        pre_pending_approval: "Függőben levő",
        pending_approval: "Függőben levő",
        approved: "Jóváhagyott",
        not_approved: "Nincs jóváhagyva",
        canceled: "Törölve",
        returned: "Visszatért",
        lost_shipment: "Elveszett szállítmány",
        delivery_not_confirmed: "Szállítva",
        delivered: "Szállítva",
        delivery_received: "Szállítva",
        refurbished: "A visszaküldés befejeződött",
        returned_to_office: "Visszatért az irodába",
        return_to_employer: "Visszakerült a munkáltatóhoz",
        return_pickup_scheduled: "Átvétel ütemezett",
        return_pickup_completed: "Átvétel kész",
        return_pickup_excluded: "Felvétel kizárva",
        return_pickup_submitted: "Átvétel elküldve",
        return_pickup_finalized: "Az átvétel véglegesítve",
        disposed: "Kiselejtezett",
        damaged: "Sérült",
        transferred: "Áthelyezve",
        transfer_received: "Átutalás érkezett"
    }
}