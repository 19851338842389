<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <loading v-if="products.loading"/>
                <div v-else>
                    <div v-if="catalogMessage" v-html="catalogMessage"></div>

                    <products-grouping v-for="grouping in products.groupings" :grouping="grouping"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex";

    import mixin_view from "@/mixins/view";
    import mixin_constraints from "@/mixins/constraints";
    import mixin_widget from "@mixins_widget";
    import {retryRequest} from "@/helpers/request";
    import {CATALOG_STATUS_MESSAGE} from "@/constants";

    import Loading from "@/components/Loading";
    import ProductsList from "@/components/products/ProductsList";
    import ProductsGrouping from "@/components/products/ProductsGrouping";

    const GROUPING = process.env.VUE_APP_HOME_PAGE_GROUPING_TYPE;

    export default {
        name: "ViewsCatalog",
        mixins: [mixin_view, mixin_constraints, mixin_widget],
        components: {
            ProductsGrouping,
            ProductsList,
            Loading
        },
        data() {

            return {
                t_path: "views.catalog",
                products: {
                    loading: false,
                    groupings: []
                },
                catalogMessageSource: null
            };
        },
        computed: {
            ...mapGetters({
                maxItems: "config/page/home/maxItems"
            }),
            catalogMessage() {

                return this.catalogMessageSource
                    ? this.$t(`catalog.message.${this.catalogMessageSource.message}`, {
                        equipmentQuizLink: this.$router.resolve({name: "quiz", query: {reset: true}}).fullPath,
                        widgetOptions: this.widget_optionsJson,
                        supportEmail: this.catalogMessageSource.email
                    })
                    : null
            }
        },
        methods: {
            handleCatalog(result) {

                const groupings = result.groupings;

                if (0 === groupings.length) {

                    this.products.loading = false;

                    this.$activity.log("catalog_no_groupings", {constraints: this.$store.getters["user/constraints/type"]});
                    this.$activity.log("catalog_no_products", {constraints: this.$store.getters["user/constraints/type"]});

                    return false;
                }

                this.$activity.log("catalog_groupings", {
                    groupings: groupings.map(grouping => ({name: grouping.name, id: grouping.id})),
                    constraints: this.$store.getters["user/constraints/type"]
                });

                const promises = groupings.map(item => retryRequest(() => this.$platform.get(`/api/v2/grouping/${GROUPING}/${item.id}`)));

                return Promise
                    .all(promises)
                    .then(responses => {

                        responses.forEach(response => {

                            const result = response.data.results;
                            const items = this.constraints_filterAvailableProducts(result.products);
                            const total = "undefined" !== typeof result.total
                                ? result.total
                                : items.length;

                            this.$activity.log("catalog_grouping_products", {
                                grouping_id: result._id,
                                products: items.slice(0, this.maxItems),
                                constraints: this.$store.getters["user/constraints/type"]
                            });

                            if (0 < items.length) {

                                this.products.groupings.push({
                                    ...result,
                                    id: result._id,
                                    type: GROUPING,
                                    items: items.slice(0, this.maxItems),
                                    showMore: total > items.slice(0, this.maxItems).length
                                });
                            }
                        });
                    })
                    .finally(() => this.products.loading = false);
            },
            fetch() {

                if (!this.constraints_hasUserAvailableProducts()) {

                    this.$activity.log("catalog_no_products", {constraints: this.$store.getters["user/constraints/type"]});

                    return false;
                }

                this.products.loading = true;

                const promises = [retryRequest(() => this.$platform.get(`/api/v2/groupingList/${GROUPING}`))];

                if (CATALOG_STATUS_MESSAGE) {

                    promises.push(this.$store.dispatch("user/catalog/getCatalogMessage"))
                }

                return Promise
                    .all(promises)
                    .then(responses => {

                        const [catalog, catalogMessage] = responses;

                        this.handleCatalog(catalog.data.results);

                        if (CATALOG_STATUS_MESSAGE) {
                            this.catalogMessageSource = catalogMessage.message ? catalogMessage : null;
                        }
                    });
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>