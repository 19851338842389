export default {
    required: "Er is een waarde vereist",
    email: "Waarde is geen geldig e-mailadres",
    mask: "De waarde is ongeldig",
    maxValue: "De maximale waarde is {maxValue}",
    regex: "De waarde is ongeldig",
    server: {
        DELEGATES_NOT_ENABLED: "Gedelegeerden zijn niet ingeschakeld voor deze partner.",
        DELEGATE_DOES_NOT_EXIST: "Gedelegeerde bestaat niet",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Kan een gedelegeerde die niet van u is, niet bijwerken.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Een gedelegeerde die niet van u is, kan niet worden verwijderd.",
        DELEGATE_ALREADY_EXISTS: "Er bestaat al een bestaande gedelegeerde regel die aan deze criteria voldoet.",
        DELEGATE_CANNOT_BE_SELF: "U kunt uzelf niet als afgevaardigde toevoegen.",
        IMPERSONATE_USER_NO_PERMISSIONS: "Gebruiker {email} heeft geen toegang tot DXC Gear en er kan geen bestelling namens hem worden gedaan.",
        "Invalid user type": "Ongeldig gebruikerstype",
        "User not found": "Gebruiker niet gevonden"
    }
}