export default {
    title: "转移产品",
    model: {
        email: {
            label: "电子邮件",
            placeholder: "填写邮箱",
            errors: {
                myself: "电子邮件必须与您的不同",
                notfound: "用户不存在"
            }
        },
        reason: {
            label: "原因",
            placeholder: "原因"
        }
    },
    message: "收件人将收到一封电子邮件，通知他们转账事宜。",
    saved: "已保存",
    cta: {
        submit: {
            text: "提交",
            processing: "提交"
        },
        cancel: {
            text: "取消"
        }
    }
}