<template>
    <div class="orders-history">
        <div v-for="(type, typeIndex) in orders" class="orders-history-item" :key="type.meta">
            <!-- Title -->
            <div v-if="type.loading || 0 < type.items.length" class="title">{{t('meta.' + type.meta)}}</div>
            <!-- Loading -->
            <loading v-if="type.loading && 0 === type.items.length"/>
            <div v-else-if="0 <  type.items.length">
                <!-- Orders -->
                <orders-history-order-product v-for="product in type.items" class="orders-history-item"
                                              :key="product._id"
                                              :product="product"/>
                <!-- Load more-->
                <div v-if="type.total > type.items.length" class="load-more">
                    <button type="button"
                            class="btn btn-link p-0"
                            :disabled="type.loading"
                            @click="load(typeIndex, type.items.length, true, true)">
                        {{t('loadMore')}}
                        <spinner v-if="type.loading"/>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="noOrders" class="orders-history-item">
             <!-- No orders -->
            <div class="no-orders">{{t('noOrders')}}</div>
        </div>
    </div>
</template>

<script>

    import emitter, {EVENTS} from "@/api/events";

    import Spinner from "@/components/Spinner";
    import Loading from "@/components/Loading";
    import OrdersHistoryOrderProduct from "@/components/orders/OrdersHistoryOrderProduct";

    const METADATA = process.env.VUE_APP_COMPONENT_ORDERHISTORY_META.split("|").map(meta => ({
        meta: meta.split("=")[0],
        statuses: meta.split("=")[1].split(",")
    }));

    export default {
        name: "OrdersHistory",
        components: {
            OrdersHistoryOrderProduct,
            Loading,
            Spinner
        },
        data() {

            return {
                t_path: "components.orders.history",
                orders: METADATA.map(meta => ({
                    meta: meta.meta,
                    params: {"meta_status.status": meta.statuses.join(","), archived: "false"},
                    items: [],
                    total: 0,
                    loading: false
                }))
            }
        },
        methods: {
            fetch() {

                this.orders.forEach(
                    (type, index) => this.load(
                        index,
                        0,
                        false,
                        true,
                        process.env.VUE_APP_COMPONENT_ORDERHISTORY_CHUNK_SIZE
                    )
                );
            },
            load(index, skip, append, changeLoadingIndicator, limit) {

                if (changeLoadingIndicator) {

                    this.orders[index].loading = true;
                }

                this.$store
                    .dispatch("user/orders/loadItems", {
                        ...this.orders[index].params,
                        skip,
                        limit: limit ? limit : process.env.VUE_APP_COMPONENT_ORDERHISTORY_CHUNK_SIZE
                    })
                    .then(result => {

                        this.orders[index].total = result.total;
                        this.orders[index].items = append ? this.orders[index].items.concat(result.items) : result.items;
                    })
                    .finally(() => this.orders[index].loading = false);
            },
            refresh() {

                this.orders.forEach(
                    (type, index) => this.load(
                        index,
                        0,
                        false,
                        false,
                        type.items.length < process.env.VUE_APP_COMPONENT_ORDERHISTORY_CHUNK_SIZE
                            ? process.env.VUE_APP_COMPONENT_ORDERHISTORY_CHUNK_SIZE
                            : type.items.length
                    )
                );
            }
        },
        computed: {
            noOrders() {

                return this.orders.length === this.orders
                    .filter(type => false === type.loading && 0 === type.items.length)
                    .length;
            }
        },
        mounted() {

            this.fetch();

            emitter.on(EVENTS.USER_ORDER_CANCEL, this.refresh);
            emitter.on(EVENTS.USER_ORDER_RECEIVED, this.refresh);
            emitter.on(EVENTS.USER_ORDER_NOT_RECEIVED, this.refresh);
            emitter.on(EVENTS.USER_ORDER_UNDO, this.refresh);
            emitter.on(EVENTS.USER_ORDER_HIDE, this.refresh);
            emitter.on(EVENTS.USER_ORDER_TRANSFER, this.refresh);
        },
        beforeUnmount() {

            emitter.off(EVENTS.USER_ORDER_CANCEL, this.refresh);
            emitter.off(EVENTS.USER_ORDER_RECEIVED, this.refresh);
            emitter.off(EVENTS.USER_ORDER_NOT_RECEIVED, this.refresh);
            emitter.off(EVENTS.USER_ORDER_UNDO, this.refresh);
            emitter.off(EVENTS.USER_ORDER_HIDE, this.refresh);
            emitter.off(EVENTS.USER_ORDER_TRANSFER, this.refresh);
        }
    }
</script>