<template>
    <page-message>
        <template #header>{{header}}</template>

        <template #message>
            <div>

                <div v-for="note in notes" class="pb-3 text-center" :key="note">{{note}}</div>

                <div v-html="message"></div>

                <div v-if="0 < outOfStockProducts.length">

                    <!-- Product items -->
                    <div class="row pt-5">
                        <div class="offset-md-2 offset-lg-3 col-md-8 col-lg-6">
                            <div class="row pt-3" v-for="product in outOfStockProducts" :key="product.sku">
                                <div class="col-4">
                                    <products-product-thumbnails v-if="0 < product.pictures.length" size="small"
                                                                 :value="product.pictures"/>
                                </div>
                                <div class="col-8 text-left">
                                    {{product.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="additionalMessage" class="pt-3 text-center">
                    {{additionalMessage}}
                </div>
            </div>

        </template>

        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
</template>

<script>

    import mixin_view from "@/mixins/view";
    import mixin_cart from "@/mixins/cart";
    import mixin_products from "@/mixins/products";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    import PageMessage from "@/components/page/PageMessage";
    import ProductsProductThumbnails from "@/components/products/product/ProductsProductThumbnails";

    export default {
        name: "ViewsOrderSubmitted",
        components: {
            ProductsProductThumbnails,
            PageMessage
        },
        mixins: [mixin_view, mixin_cart, mixin_products],
        data() {

            return {
                t_path: "views.order.submitted",
                outOfStock: [],
                notes: [],
                messageTranslations: null
            }
        },
        computed: {
            header() {

                if (this.$route.query.messageOrderStatusError) {

                    return this.t("header.error");
                }

                return this.t("header.success");
            },
            message() {

                const params = {
                    url: this.$router.resolve({name: "home"}).fullPath
                };

                const messages = [
                    this.$route.query.messageOrderStatusError
                        ? this.t("message.error.status", params)
                        : this.t("message.success", params)
                ];


                if (0 < this.outOfStockProducts.length) {

                    messages.push(this.t("message.error.stock", params));
                }

                return messages.join("<br/><br/>");
            },
            outOfStockProducts() {

                return this.outOfStock.map(item => ({
                    ...item,
                    name: this.products_translateProductName(item)
                }));
            },
            additionalMessage() {

                return null !== this.messageTranslations
                    ? getTranslationValue(this.messageTranslations, "message", this.$i18n)
                    : null;
            }
        },
        mounted() {

            if (this.$route.query.outOfStockSkus) {

                this.outOfStock = this.$route.query.outOfStockSkus
                    .split(",")
                    .map(sku => {

                        // Find item for showing in modal
                        const item = this.cart_find({sku});

                        return item && item.item ? item.item : null;
                    })
                    .filter(item => !!item);
            }

            if (this.$route.query.notes) {

                this.notes = JSON.parse(this.$route.query.notes);
            }

            if (this.$route.query.messageTranslations) {

                this.messageTranslations = JSON.parse(this.$route.query.messageTranslations);
            }

            // Clear cart
            this.$store.dispatch("user/cart/clear");
        }
    }
</script>