export default {
    menu: {
        approvals: "Zulassungen",
        delegates: "Delegierte"
    },
    approvals: {
        title: "Genehmigung",
        error: "Ein Fehler ist aufgetreten"
    },
    delegates: {
        title: "Delegierte",
        error: "Ein Fehler ist aufgetreten",
        noDelegates: "Keine Gegenstände",
        delegateDelete: "Der Delegierte wurde gelöscht",
        confirm: {
            delete: "Delegierten löschen?"
        },
        delegates: {
            email: "Email",
            startDate: "Startdatum",
            endDate: "Endtermin",
        },
        buttons: {
            add: {
                text: "Delegierten hinzufügen"
            }
        }
    }
}