export default {
    types: {
        "ac_router": "AC 路由器",
        "chair": "椅子",
        "desk": "桌子",
        "desktop": "桌面",
        "desktop_riser": "桌面升降器",
        "detector": "电子烟和噪音检测器",
        "displayport_cable": "Displayport 电缆",
        "docking station": "扩展坞",
        "dual_usb_adapter": "双频 USB 适配器",
        "ear cushion": "耳垫",
        "footrest": "脚凳",
        "hdmi_cable": "HDMI 线",
        "hdmi_displayport_cable": "HDMI 转 DisplayPort 线缆",
        "hdmi_vga_cable": "HDMI 转 VGA 线",
        "headset": "耳机",
        "keyboard": "键盘",
        "kvm switch": "KVM切换器",
        "kvm_switch": "KVM切换器",
        "lamp": "灯",
        "laptop stand": "笔记本电脑支架",
        "laptop": "笔记本电脑",
        "mesh_router": "网状路由器",
        "monitor": "监视器",
        "mouse": "老鼠",
        "patch_cord": "Cat6 跳线",
        "power": "电源线",
        "power_center": "动力中心",
        "power_strip": "电源板",
        "printer": "打印机",
        "smart_plug": "迷你智能插头",
        "smart_power_strip": "智能电源板",
        "stylus": "触控笔",
        "tablet": "药片",
        "tablet accessory": "平板电脑配件",
        "thunderbolt_cable": "USB-C 转 Thunderbolt",
        "travel_router": "旅行路由器",
        "usb hub": "USB 集线器",
        "usb_adapter": "USB 适配器",
        "usb_hub": "USB 集线器",
        "webcam": "摄像头"
    }
}