import address from "./address/index";
import advisor from "./advisor/index"
import approvals from "./approvals/index"
import banners from "./banners/index"
import cart from "./cart/index"
import home from "./home"
import impersonation from "./impersonation"
import loading from "./loading";
import orders from "./orders/index";
import products from "./products/index";
import survey from "./survey/index"

export default {
    address,
    advisor,
    approvals,
    banners,
    cart,
    home,
    impersonation,
    loading,
    orders,
    products,
    survey
}