import {createStore} from "vuex"

import breadcrumbs from "./breadcrumbs";
import config from "./config";
import db from "./db";
import grouping from "./grouping";
import i18n from "./i18n";
import modals from "./modals";
import picker from "./picker";
import user from "./user";
import widget from "./widget";

export default createStore({
    state: {
        partnerId: process.env.VUE_APP_PARTNER_ID
    },
    getters: {
        partnerId: state => state.partnerId
    },
    modules: {
        breadcrumbs,
        config,
        db,
        grouping,
        i18n,
        modals,
        picker,
        user,
        widget
    }
})
