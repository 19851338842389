export default {
    login: "登录",
    logout: "登出",
    home: "家",
    user: {
        profile: {
            edit: "編輯資料"
        }
    },
    approvals: {
        approvals: "批准",
        delegates: "代表"
    },
    impersonation: "代订"
}