export default {
    home: "Thuis",
    order: {
        submit: "Bestelling bekijken"
    },
    tunnel: {
        loginVerification: "Inlogverificatie",
        addressVerification: "Adresverificatie"
    },
    catalog: "Catalogus",
    all: "Alle",
    return: {
        return: "Opbrengst"
    },
    errors: {
        404: "@:views.errors.404.title"
    },
    impersonation: "Bestel namens",
    approvals: "Goedkeuringen",
    delegate: "Afgevaardigden"
}