<template>
    <div class="column-name">
        {{approval.user.name}}
    </div>
</template>

<script>
    export default {
        name: "ApprovalsListItemName",
        props: {
            approval: {
                type: Object,
                required: true
            }
        }
    }
</script>

