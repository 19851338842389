<template>
    <div class="column-editable">
        <approvals-list-item-dynamic-decorator :approval="approval" :field="field">
            <form-autocomplete v-if="true === isTextbox"
                               class="mb-0"
                               textbox-class="form-control form-control-sm"
                               v-model="modelValue[field.payload.field]"
                               :validator="validator"
                               :placeholder="field.placeholder"
                               :mask="field.mask"
                               :errors="field.errors"
                               :disabled="disabled || field.disabled"
                               :message-not-found="dynamic_field_messageNotFound(field, 'approval_config.' + field.payload.field)"
                               :infinity-scroll="dynamic_field_infinityScroll(field)"
                               :strict="field.typeahead && field.typeahead.strict"
                               @search="onAutocompleteSearch"/>
        </approvals-list-item-dynamic-decorator>
    </div>
</template>

<script>

    import mixin_dynamic_field from "@/mixins/dynamic_field";

    import ApprovalsListItemDynamicDecorator from "@/components/approvals/list/item/dynamic/ApprovalsListItemDynamicDecorator";
    import FormAutocomplete from "@/components/form/FormAutocomplete.vue";

    export default {
        name: "ApprovalsListItemDynamic",
        mixins: [mixin_dynamic_field],
        emits: ["update:modelValue"],
        components: {
            FormAutocomplete,
            ApprovalsListItemDynamicDecorator
        },
        props: {
            modelValue: {},
            approval: {
                type: Object,
                required: true
            },
            field: {
                type: Object,
                required: true
            },
            validator: Object,
            disabled: Boolean
        },
        computed: {
            isTextbox() {

                return "textbox" === this.field.type;
            }
        },
        methods: {
            onAutocompleteSearch(value, render) {

                this.dynamic_field_typeahead(this.field, value, render, {user_id: this.approval.user.id});
            }
        }
    }
</script>