export default {
    type: {
        "ac_router": "Sie haben das Bestelllimit für AC-Router erreicht",
        "chair": "Sie haben das Bestelllimit für Stühle erreicht",
        "default": "Sie haben das Bestelllimit erreicht",
        "default_multiple": "Sie haben das Bestelllimit für ähnliche Produkte erreicht",
        "desk": "Sie haben das Bestelllimit für Schreibtische erreicht",
        "desktop": "Sie haben das Bestelllimit für Desktop erreicht",
        "desktop_riser": "Sie haben das Bestelllimit für Desktop-Riser erreicht",
        "detector": "Sie haben das Bestelllimit für Dampf- und Geräuschdetektoren erreicht",
        "displayport_cable": "Sie haben das Bestelllimit für DisplayPort-Kabel erreicht",
        "docking station": "Sie haben das Bestelllimit für Dockingstationen erreicht",
        "dual_usb_adapter": "Sie haben das Bestelllimit für Dualband-USB-Adapter erreicht",
        "ear cushion": "Sie haben das Bestelllimit für Ohrpolster erreicht",
        "footrest": "Sie haben das Bestelllimit für Fußstützen erreicht",
        "hdmi_cable": "Sie haben das Bestelllimit für HDMI-Kabel erreicht",
        "hdmi_displayport_cable": "Sie haben das Bestelllimit für HDMI-zu-DisplayPort-Kabel erreicht",
        "hdmi_vga_cable": "Sie haben das Bestelllimit für HDMI-zu-VGA-Kabel erreicht",
        "headset": "Sie haben das Bestelllimit für Audiogeräte erreicht",
        "keyboard": "Sie haben das Bestelllimit für Tastaturen erreicht",
        "kvm switch": "Sie haben das Bestelllimit für KVM-Switche erreicht",
        "kvm_switch": "Sie haben das Bestelllimit für KVM-Switche erreicht",
        "lamp": "Sie haben das Bestelllimit für Lampen erreicht",
        "laptop stand": "Sie haben das Bestelllimit für Laptopständer erreicht",
        "laptop": "Sie haben das Bestelllimit für Laptops erreicht",
        "mesh_router": "Sie haben das Bestelllimit für Mesh-Router erreicht",
        "monitor": "Sie haben das Bestelllimit für Monitore erreicht",
        "mouse": "Sie haben das Bestelllimit für Mäuse erreicht",
        "patch_cord": "Sie haben das Bestelllimit für Cat6-Patchkabel erreicht",
        "power": "Sie haben das Bestelllimit für Netzkabel erreicht",
        "power_center": "Sie haben das Bestelllimit von Power Centers erreicht",
        "power_strip": "Sie haben das Bestelllimit für Steckdosenleisten erreicht",
        "printer": "Sie haben das Bestelllimit für Drucker erreicht",
        "smart_plug": "Sie haben das Bestelllimit für Smart Plugs Mini erreicht",
        "smart_power_strip": "Sie haben das Bestelllimit für intelligente Steckdosenleisten erreicht",
        "stylus": "Sie haben das Bestelllimit für Stifte erreicht",
        "tablet": "Sie haben das Bestelllimit für Tabletten erreicht",
        "tablet accessory": "Sie haben das Bestelllimit für Tablet-Zubehör erreicht",
        "thunderbolt_cable": "Sie haben das Bestelllimit von USB-C auf Thunderbolt erreicht",
        "travel_router": "Sie haben das Bestelllimit für Reiserouter erreicht",
        "usb hub": "Sie haben das Bestelllimit für USB-Hubs erreicht",
        "usb_adapter": "Sie haben das Bestelllimit für USB-Adapter erreicht",
        "usb_hub": "Sie haben das Bestelllimit für USB-Hubs erreicht",
        "webcam": "Sie haben das Bestelllimit für Webcams erreicht"
    }
}