export default {
    error: "Er is een probleem opgetreden bij het ophalen van orders. Vernieuw uw browser.",
    meta: {
        default: "Bestellingen",
        my_orders: "Mijn bestellingen",
        my_gear: "Ontvangen"
    },
    loadMore: "Meer laden",
    noOrders: "Geen bestellingen",
    order: {
        product: {
            title: "Volgorde",
            tracking: "Volgen",
            quantity: "Hoeveelheid",
            transfer: {
                sender: "Vorige eigenaar",
                receiver: "Ontvanger",
                transferrer: "Overdrager",
                system: "Helpdesk"
            },
            control: {
                cancel: {
                    cta: "Klik om te annuleren",
                    tooltip: "Klik om dit item te annuleren",
                    message: "Wij hebben uw bestelling geannuleerd."
                },
                cancelRequest: {
                    cta: "Verzoek tot annulering",
                    tooltip: "Klik om een annuleringsverzoek in te dienen"
                },
                received: {
                    cta: "Markeren als ontvangen",
                    tooltip: "Klik om de levering te bevestigen",
                    message: "Hartelijk dank voor uw bevestiging dat u uw product heeft ontvangen."
                },
                notReceived: {
                    cta: "Markeren als niet ontvangen",
                    tooltip: "Klik om niet-levering te bevestigen"
                },
                damaged: {
                    cta: "Markeer als beschadigd/kapot",
                    tooltip: "Klik om een supportticket aan te maken"
                },
                hide: {
                    cta: "Item verbergen",
                    tooltip: "Klik om het item uit de lijst te verbergen"
                },
                transfer: {
                    cta: "Overdracht",
                    tooltip: "Klik om het item over te dragen aan een andere werknemer"
                }
            },
            requestToCancelInfo: "Wij nemen contact op met de leverancier om een annulering aan te vragen. Let op: dit is alleen een verzoek en annulering is mogelijk niet mogelijk. Zodra we kunnen bepalen of het kan worden geannuleerd of niet, laten we u dit weten.",
            cancelOk: "OK",
            reason: "Reden",
            orderedBy: "Geordend door",
            model: {
                cancel: {
                    reason: {
                        label: "Redenen voor annulering",
                        placeholder: "Redenen voor annulering"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Indienen",
                        processing: "Verzenden"
                    },
                    cancel: {
                        cta: "Annuleren"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>Heeft u vragen over uw bestelling?<br/> Zie ons <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Support Center</b></a> voor hulp</p><p> Heeft u een product nodig dat niet in de lijst staat?<br/> Klik <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>hier</b></a> om een suggestie te sturen</p>",
        email: {
            subject: ""
        }
    }
}