export default {
    title: "Commande soumise",
    header: {
        success: "@:views.order.submitted.title",
        error: "La commande est en attente"
    },
    message: {
        success: "Votre commande a été soumise. Pour les mises à jour, veuillez vérifier votre courrier électronique ou le tableau de bord sur la page <a href='{url}'>d'accueil</a> .",
        error: {
            stock: "Votre commande a été passée <b>à l'exception</b> des articles listés ci-dessous car ils sont en rupture de stock. Veuillez faire différentes sélections pour commander ces articles. Pour les mises à jour, veuillez vérifier votre courrier électronique ou le tableau de bord sur la page <a href='{url}'>d'accueil</a> .",
            status: "Nous avons les détails de votre commande et avons remarqué un problème avec votre affectation de responsable. Nous travaillons avec GEAR IT pour résoudre le problème."
        }
    }
}