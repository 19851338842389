export default {
    disclaimer: {
        label_default: "Reconozco y acepto que los artículos que estoy ordenando/recibiendo son propiedad de O-I para ser utilizados en O-I actividades relacionadas con el trabajo y, como tal, O-I se reserva el derecho de solicitar la devolución de la propiedad a mi salida de O-I (o antes, si así lo requiere O-I).",
        label_type_1: "Confirmo que la autoevaluación para el trabajo desde casa se completó de manera precisa y de buena fe, y que cualquier equipo o mobiliario proporcionado desde la ubicación de O-I o pedido a través de Gear será utilizado por mí para el propósito de mi trabajo con O-I. Reconozco y acepto que los artículos que estoy pidiendo/recibiendo son propiedad de O-I para ser utilizados en actividades relacionadas con el trabajo de O-I y, como tal, O-I se reserva el derecho de solicitar la devolución de la propiedad al momento de mi salida de O-I (o antes, si así lo requiere O-I).",
        errors: {
            required: "Por favor acepte los términos de uso"
        }
    },
    cost_center: {
        label: "Código de centro de costos",
        placeholder: "Código de centro de costos",
        cost_center_not_found: "Los centros de costos que no están presentes pueden tardar hasta 24 horas en aparecer. Comuníquese con el Centro de servicio global si no ve el centro de costos apropiado.",
        errors: {
            regex: "Código de centro de costos no válido"
        }
    },
    company_code: {
        label: "Código de la empresa"
    },
    cost_center_name: {
        label: "Nombre del centro de costos"
    },
    order_note: {
        label: "Nota de pedido",
        placeholder: "Nota opcional para el gerente para aprobación",
    },
    send_emails_to_oobo_orderer: {
        "OOBO": "Notificarme actualizaciones sobre este pedido"
    }
}