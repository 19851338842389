export default {
    required: "Ein Wert ist erforderlich",
    email: "Der Wert ist keine gültige E-Mail-Adresse",
    mask: "Der Wert ist ungültig",
    maxValue: "Der Maximalwert ist {maxValue}",
    regex: "Der Wert ist ungültig",
    server: {
        DELEGATES_NOT_ENABLED: "Für diesen Partner sind keine Delegierten aktiviert.",
        DELEGATE_DOES_NOT_EXIST: "Der Delegierte existiert nicht",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Ein Delegierter, der Ihnen nicht gehört, kann nicht aktualisiert werden.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Ein Delegierter, der Ihnen nicht gehört, kann nicht gelöscht werden.",
        DELEGATE_ALREADY_EXISTS: "Es gibt bereits eine Delegiertenregel, die diesem Kriterium entspricht.",
        DELEGATE_CANNOT_BE_SELF: "Sie können sich selbst nicht als Delegierter hinzufügen.",
        IMPERSONATE_USER_NO_PERMISSIONS: "Benutzer {email} hat keinen Zugriff auf DXC Gear und kann in seinem Namen keine Bestellung aufgeben.",
        "Invalid user type": "Ungültiger Benutzertyp",
        "User not found": "Benutzer nicht gefunden"
    }
}