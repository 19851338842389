<template>
    <div class="column-details approvals-list-item-details">
        <!-- Details -->
        <div v-html="details"></div>

        <!-- Price -->
        <div v-if="null !== price" class="mt-3">{{price}}</div>
    </div>
</template>

<script>

    import {get} from "lodash";
    import clm from "country-locale-map";

    import {sanitize} from "@/helpers/html";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    export default {
        name: "ApprovalsListItemDetails",
        props: {
            approval: {
                type: Object,
                required: true
            }
        },
        computed: {
            details() {

                return sanitize(
                    getTranslationValue(this.approval.order, "details", this.$i18n)
                );
            },
            price() {

                if (this.approval.order.cost && 1 === parseInt(process.env.VUE_APP_VIEW_APPROVAL_ORDER_PRICE)) {

                    const country = get(this.approval.order, "country", this.$store.getters["user/profile/country"]);

                    // Currency format local must be calculated based on order selected country
                    return new Intl
                        .NumberFormat(
                            clm.getLocaleByAlpha3(country).replace(/_/ig, "-") + "-u-nu-latn",
                            {
                                style: "currency",
                                currency: get(this.approval.order, "currency", "USD")
                            }
                        )
                        .format(this.approval.order.cost);
                }

                return null;
            }
        }
    }
</script>

