export default {
    title: "提交订单",
    header: {
        success: "@:views.order.submitted.title",
        error: "订单待处理"
    },
    message: {
        success: "您的订单已提交。如需更新，请查看您的电子邮件或<a href='{url}'>主页</a>上的仪表板。",
        error: {
            stock: "您已下订单，但下列商品<b>除外</b>，因为它们已缺货。请选择其他商品来订购这些商品。如需更新，请查看您的电子邮件或<a href='{url}'>主页</a>上的仪表板。",
            status: "我们已收到您的订单详细信息，并注意到您的经理分配存在问题。我们正在与 GEAR IT 合作解决该问题。"
        }
    }
}