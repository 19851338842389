<template>
    <layout-two-column>
        <template #column>
            <panel>
                <impersonation-orders-history/>
            </panel>
        </template>

        <template #default>
            <h3>{{t('title')}}</h3>

            <loading v-if="true === loading"/>
            <form v-else @submit.prevent="base_submit('model', 'submit', 'processing')">
                <form-textbox-group v-model="model.email"
                                    :label="t('model.email.label')"
                                    :placeholder="t('model.email.placeholder')"
                                    :textbox-class="'form-control'"
                                    :disabled="processing"
                                    :errors="{myself:t('model.email.errors.myself')}"
                                    :validator="v$.model.email">
                    <template #after>
                        <button type="submit" class="btn btn-sm btn-primary" :disabled="processing">
                            <spinner v-if="true === processing"/>
                            <span v-if="true === processing">{{t('cta.impersonate.processing')}}</span>
                            <span v-else>{{t('cta.impersonate.cta')}}</span>
                        </button>
                    </template>
                </form-textbox-group>
            </form>
        </template>
    </layout-two-column>
</template>

<script>

    import {email, required} from "@vuelidate/validators";
    import useVuelidate from "@vuelidate/core";
    import {get} from "lodash";

    import mixin_view from "@/mixins/view";
    import mixin_auth from "@mixins_auth";
    import {PERMISSIONS, IMPERSONATION_TOKEN_LIFETIME_HOURS} from "@/constants";

    import FormTextboxGroup from "@/components/form/FormTextboxGroup";
    import ImpersonationOrdersHistory from "@/components/impersonation/ImpersonationOrdersHistory";
    import LayoutTwoColumn from "@/components/layout/LayoutTwoColumn";
    import ModalImpersonationUserCreate from "@/modal/ModalImpersonationUserCreate";
    import Panel from "@/components/Panel";
    import Spinner from "@/components/Spinner";
    import Loading from "@/components/Loading.vue";

    export default {
        name: "ViewsImpersonation",
        mixins: [mixin_view, mixin_auth],
        components: {
            Loading,
            FormTextboxGroup,
            ImpersonationOrdersHistory,
            LayoutTwoColumn,
            Panel,
            Spinner,
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        data() {

            return {
                t_path: "views.impersonation",
                loading: false,
                processing: false,
                countries: [],
                model: {
                    email: null
                }
            };
        },
        computed: {
            permissions() {

                return {
                    user: {
                        create: this.$store.getters["user/permissions/hasPermission"](PERMISSIONS.CREATE_USER)
                    }
                }
            }
        },
        validations() {

            return {
                model: {
                    email: {
                        required,
                        email,
                        myself: value => true === this.processing || value !== this.$store.getters["user/profile/email"]
                    }
                }
            }
        },
        methods: {
            submit() {

                this.$store
                    .dispatch("user/impersonation/getUser", this.model.email)
                    .then(user => {

                        const country = get(user, `style_profile.${this.$store.getters["partnerId"]}.country`);
                        const allowed = get(user, "allowed", false);

                        const index = this.countries.findIndex(
                            supportedCountry => supportedCountry.iso_alpha3 === country && (
                                false === supportedCountry.is_limited || true === allowed
                            )
                        );

                        if (-1 === index) {

                            this.processing = false;

                            this.$toast.error(
                                this.te(`error.country.${country}`)
                                    ? this.t(`error.country.${country}`)
                                    : this.t(`error.country.default`)
                            );
                        } else {

                            this.impersonate(user.id, user.email);
                        }
                    })
                    .catch(error => {

                        this.processing = false;

                        if (404 === get(error, "response.status")) {

                            if (true === this.permissions.user.create) {

                                return this.$modal
                                    .show({
                                        component: ModalImpersonationUserCreate,
                                        props: {
                                            email: this.model.email
                                        }
                                    })
                                    .then(user => this.impersonate(user.id, user.email), () => true);
                            }

                            return this.$toast.error(this.t("error.userNotExists", {email: this.model.email}));
                        }

                        return this.$toast.error(
                            this.base_error_messages(error, true)
                        );
                    });
            },
            impersonate(userId, email) {

                return this.$store
                    .dispatch("user/impersonation/impersonate", userId)
                    .then(
                        token => this.auth_impersonate(
                            token,
                            userId,
                            [],
                            {
                                permissions: [PERMISSIONS.EMPLOYEE],
                                restrictions: []
                            },
                            false,
                            IMPERSONATION_TOKEN_LIFETIME_HOURS * 3600 * 1000 // 1 day
                        )
                    )
                    .catch(error => {

                        this.processing = false;
                        this.base_error(error, false, {translate: true, translateParameters: {email}});
                    });
            },
            init() {

                this.loading = true;

                this.$platform
                    .get("/api/v2/partner/countrieslist")
                    .then(response => this.countries.splice(0, this.countries.length, ...response.data.results))
                    .catch(error => this.base_error(error, true))
                    .finally(() => this.loading = false)
            }
        },
        mounted() {

            this.init();
        }
    }
</script>