export default {
    menu: {
        approvals: "Approvals",
        delegates: "Delegates"
    },
    approvals: {
        title: "Approval",
        error: "An error occurred"
    },
    delegates: {
        title: "Delegates",
        error: "An error occurred",
        noDelegates: "No items",
        delegateDelete: "Delegate has been deleted",
        confirm: {
            delete: "Delete delegate?"
        },
        delegates: {
            email: "Email",
            startDate: "Start Date",
            endDate: "End Date",
        },
        buttons: {
            add: {
                text: "Add Delegate"
            }
        }
    }
}