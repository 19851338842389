export default {
    types: {
        "ac_router": "Router CA",
        "chair": "Sedia",
        "desk": "Scrivania",
        "desktop": "Scrivania",
        "desktop_riser": "Supporto da tavolo",
        "detector": "Rilevatore di fumo e rumore",
        "displayport_cable": "Cavo DisplayPort",
        "docking station": "Stazione di attracco",
        "dual_usb_adapter": "Adattatore USB a doppia banda",
        "ear cushion": "Cuscinetto auricolare",
        "footrest": "Poggiapiedi",
        "hdmi_cable": "Cavo HDMI",
        "hdmi_displayport_cable": "Cavo HDMI a DisplayPort",
        "hdmi_vga_cable": "Cavo HDMI a VGA",
        "headset": "Cuffia",
        "keyboard": "Tastiera",
        "kvm switch": "Interruttore KVM",
        "kvm_switch": "Interruttore KVM",
        "lamp": "Lampada",
        "laptop stand": "Supporto per laptop",
        "laptop": "Computer portatile",
        "mesh_router": "Router a maglie",
        "monitor": "Monitor",
        "mouse": "Topo",
        "patch_cord": "Cavo di collegamento Cat6",
        "power": "Cavo di alimentazione",
        "power_center": "Centro di potere",
        "power_strip": "Ciabatta elettrica",
        "printer": "Stampante",
        "smart_plug": "Mini spina intelligente",
        "smart_power_strip": "Ciabatta intelligente",
        "stylus": "Stilo",
        "tablet": "Tavoletta",
        "tablet accessory": "Accessorio per tablet",
        "thunderbolt_cable": "Da USB-C a Thunderbolt",
        "travel_router": "Router di viaggio",
        "usb hub": "Hub USB",
        "usb_adapter": "Adattatore USB",
        "usb_hub": "Hub USB",
        "webcam": "Webcam"
    }
}