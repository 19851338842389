export default {
    status: {
        acknowledged: "Zamówione",
        created: "Stworzony",
        sent: "Zamówione",
        confirmed: "Zamówione",
        not_confirmed: "Nie zamówiono",
        delivery_email: "Wysłany",
        shipped: "Wysłany",
        delayed: "Opóźniony",
        preparing: "Przygotowanie",
        processing: "Przetwarzanie",
        processed: "Obrobiony",
        submitted: "Zamówione",
        delivery_not_received: "Wysłany",
        shipment_exception: "Wysłany",
        pre_pending_approval: "Aż do",
        pending_approval: "Aż do",
        approved: "Zatwierdzony",
        not_approved: "Niezatwierdzone",
        canceled: "Odwołany",
        returned: "Zwrócony",
        lost_shipment: "Zaginiona przesyłka",
        delivery_not_confirmed: "Dostarczony",
        delivered: "Dostarczony",
        delivery_received: "Dostarczony",
        refurbished: "Zwrot ukończony",
        returned_to_office: "Wrócił do biura",
        return_to_employer: "Zwrócono pracodawcy",
        return_pickup_scheduled: "Odbiór zaplanowany",
        return_pickup_completed: "Odbiór ukończony",
        return_pickup_excluded: "Odbiór osobisty wykluczony",
        return_pickup_submitted: "Odbiór przesłany",
        return_pickup_finalized: "Odbiór sfinalizowany",
        disposed: "Zutylizowany",
        damaged: "Uszkodzony",
        transferred: "Przeniesiony",
        transfer_received: "Otrzymano przelew"
    }
}