export default {
    home: "Heim",
    order: {
        submit: "Bestellung überprüfen"
    },
    tunnel: {
        loginVerification: "Anmeldebestätigung",
        addressVerification: "Adressverifikation"
    },
    catalog: "Katalog",
    all: "Alle",
    return: {
        return: "Zurückkehren"
    },
    errors: {
        404: "@:views.errors.404.title"
    },
    impersonation: "Bestellung im Auftrag von",
    approvals: "Zulassungen",
    delegate: "Delegierte"
}