export default {
    empty: "Nincs mit helyeselni",
    saved: "A jóváhagyások benyújtva",
    review: "Ha téves, tekintse át és szerkessze a költséghelyet",
    confirm: {
        partial: "Vannak még jóváhagyásra váró megrendelések. Szeretné folytatni a jelenlegi változtatásokat?"
    },
    header: {
        name: "Név",
        order: "Rendelés",
        details: "Részletek",
        cta: "Jóváhagyás",
        message: ""
    },
    item: {
        order: {
            manager: "Menedzser",
            requested: "kérte"
        },
        approve: {
            reason: {
                placeholder: {
                    default: "ok",
                    approved: "ok",
                    notapproved: "ok"
                }
            },
            approve: {
                yes: {
                    label: "Igen"
                },
                no: {
                    label: "Nem"
                }
            }
        },
        prior: {
            cta: {
                load: "Előzetes jóváhagyások",
                loading: "Terhelés",
                show: "Előzetes jóváhagyások",
                hide: "Előzetes jóváhagyások"
            },
            approvals: {
                empty: "Nincs előzetes jóváhagyás",
                item: {
                    status: {
                        approver: "szerző: {approver}",
                        system: "Help Desk",
                        approved: "Jóváhagyott",
                        rejected: "Nincs jóváhagyva",
                        unrequired: "Jóváhagyás nem szükséges",
                        reason: "Ok",
                        transferred: "Áthelyezve"
                    }
                }
            }
        }
    },
    cta: {
        submit: {
            text: "Beküld",
            processing: "Beküldés"
        }
    }
}