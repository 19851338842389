<template>
    <div class="container">
        <div class="row">
            <div class="col-12 layout-two-column" :class="{'inverse-order': true === inverse}">
                <div class="column-small">
                    <slot name="column"></slot>
                </div>

                <div class="column-content">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LayoutTwoColumn",
        props: {
            inverse: Boolean
        }
    }
</script>