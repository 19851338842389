<template>
    <div class="column-order">
        <!-- Order date -->
        <div>{{date}}</div>

        <!-- Order dynamic data -->
        <div v-for="field in fields" class="small pt-1" :key="field.id">
            <div>{{field.label}}:</div>

            <div class="pre-white-space">{{field.value}}</div>
        </div>

        <!-- Requested by (impersonation) -->
        <div v-if="null !== requested" class="small pt-1">
            <div>{{t('requested')}}:</div>

            <div>{{requested}}</div>
        </div>

        <!-- Approval manager -->
        <div v-if="null !== manager" class="small pt-1">
            <div>{{t('manager')}}:</div>

            <div>{{manager}}</div>
        </div>

        <slot></slot>
    </div>
</template>

<script>

    import {v4 as uuidv4} from "uuid";
    import {get} from "lodash";

    export default {
        name: "ApprovalsListItemOrder",
        props: {
            approval: {
                type: Object,
                required: true
            },
            staticFields: {
                type: Array,
                required: true
            },
            editableFields: {
                type: Array,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.approvals.list.item.order"
            };
        },
        computed: {
            date() {

                return this.d(this.approval.order.date, "date");
            },
            fields() {

                return this.staticFields
                    .filter(staticField => true === !!this.approval.dynamic[staticField.payload.field])
                    .filter(
                        staticField => this.editableFields.reduce(
                            (display, editableField) => {

                                if (true === display && "undefined" !== typeof editableField.decorators) {

                                    // Check for editable field does not use this field as decorator
                                    for (let i = 0; i < editableField.decorators.length; i ++ ) {

                                        if ("undefined" !== typeof editableField.decorators[i].values) {

                                            for (let k = 0; k < editableField.decorators[i].values.length; k ++) {

                                                // Check for decorator source is current static field
                                                if (
                                                    editableField.decorators[i].values[k].source
                                                    && "order" === editableField.decorators[i].values[k].source.type
                                                    && staticField.payload.field === editableField.decorators[i].values[k].source.value
                                                ) {

                                                    // Hide this static field because it's displayed by decorator
                                                    return false;
                                                }
                                            }
                                        }
                                    }
                                }

                                return display;
                            },
                            true
                        )
                    )
                    .map(
                        staticField => ({
                            id: uuidv4(),
                            label: staticField.label,
                            value: this.approval.dynamic[staticField.payload.field]
                        })
                    );
            },
            manager() {

                const email = get(this.approval, "approval.manager.email")
                    ? get(this.approval, "approval.manager.email").toLowerCase()
                    : "";

                if ("" !== email && this.$store.getters["user/profile/email"].toLowerCase() !== email) {

                    return email;
                }

                return null;
            },
            requested() {

                if (
                    null !== this.approval.order.requested_by
                    && this.approval.user.id !== this.approval.order.requested_by.id
                ) {

                    return this.approval.order.requested_by.name;
                }

                return null;
            }
        }
    }
</script>