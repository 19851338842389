export default {
    title: "Přenést produkt",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Vyplňte email",
            errors: {
                myself: "E-mail se musí lišit od vašeho",
                notfound: "Uživatel neexistuje"
            }
        },
        reason: {
            label: "Důvod",
            placeholder: "Důvod"
        }
    },
    message: "Příjemci bude zaslán e-mail s informací o převodu.",
    saved: "Uloženo",
    cta: {
        submit: {
            text: "Předložit",
            processing: "Odesílání"
        },
        cancel: {
            text: "Zrušit"
        }
    }
}