import store from "@/store";

/**
 * Helper for handling user permissions
 */
export default {
    /**
     * Handles error page based on failed permissions check
     *
     * @param {String} permission
     * @param {Object} restriction
     * @param {Object} query parameters
     * @returns {{name: string, params: {error: string}}}
     */
    getRouteByPermissionFail(permission, restriction, query = {}) {

        if (restriction || store.getters["user/permissions/hasRestriction"](permission)) {

            restriction = restriction ? restriction : store.getters["user/permissions/getRestriction"](permission);

            if (restriction.isLoginFailure) {

                return {name: "errors.401", query};
            }

            if (
                ("undefined" !== typeof restriction.isUserTypeAccessAllowed && false === restriction.isUserTypeAccessAllowed)
                || ("undefined" !== typeof restriction.isUserDisabled && true === restriction.isUserDisabled)
                || ("undefined" !== typeof restriction.isUserCoreIdEmpty && true === restriction.isUserCoreIdEmpty)
                || ("undefined" !== typeof restriction.isUserAllowed && false === restriction.isUserAllowed)
                || ("undefined" !== typeof restriction.isUserActive && false === restriction.isUserActive)
            ) {

                return {name: "errors.403", params: {error: "eligibility"}, query};
            }

            if ("undefined" !== typeof restriction.isUserInSupportedCountry && false === restriction.isUserInSupportedCountry) {

                return {name: "errors.403", params: {error: "eligibility-location"}, query};
            }

            if (restriction.tfaNeedLoginVerification) {

                // SSO login user failed for (user was not created or found in backend)
                return {name: "auth.confirm", params: data, query};
            }
        }

        return {name: "errors.403", params: {error: "no-permissions"}, query};
    }
}