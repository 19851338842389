export default {
    menu: {
        approvals: "Schválení",
        delegates: "Delegáti"
    },
    approvals: {
        title: "Odsouhlasení",
        error: "Vyskytla se chyba"
    },
    delegates: {
        title: "Delegáti",
        error: "Vyskytla se chyba",
        noDelegates: "Žádně věci",
        delegateDelete: "Delegát byl smazán",
        confirm: {
            delete: "Smazat delegáta?"
        },
        delegates: {
            email: "E-mailem",
            startDate: "Datum zahájení",
            endDate: "Datum ukončení",
        },
        buttons: {
            add: {
                text: "Přidat delegáta"
            }
        }
    }
}