import {platform} from "@/api/platform";
import {retryRequest} from "@/helpers/request";
import orders from "./impersonation/orders"

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        impersonate(context, userId) {

            return retryRequest(() => platform.post(`/api/v2/users/${this.getters["user/id"]}/impersonate`, {
                impersonate: userId
            })).then(response => response.data.results.token);
        },
        getUser(context, email) {

            return platform
                .get(`/api/v2/user`, {
                    params: {
                        email,
                        schema: "id,email,style_profile,allowed,properties,attributes".split(",")
                    }
                })
                .then(response => response.data.results);
        },
        createUser(context, request) {

            return retryRequest(() => platform.post("/api/v2/user/create", request))
                .then(response => response.data.results.user);
        }
    },
    modules: {
        orders
    }
}