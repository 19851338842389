import {retryRequest} from "@/helpers/request";
import {platform} from "@/api/platform";

export default {
    namespaced: true,
    state: {
        enabled: false
    },
    getters: {
        enabled: state => state.enabled
    },
    mutations: {
        setEnabled: (state, enabled) => state.enabled = enabled,
    },
    actions: {
        fetch(context) {

            return retryRequest(() => platform.get("/api/v2/delegate_config"))
                .then(response => context.commit("setEnabled", response.data.results.allow_delegates));
        },
        clear: context => context.commit("setEnabled", false)
    }
}