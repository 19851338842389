export default {
    login: "Se connecter",
    logout: "Déconnexion",
    home: "Maison",
    user: {
        profile: {
            edit: "Modifier le profil"
        }
    },
    approvals: {
        approvals: "Approbations",
        delegates: "Délégués"
    },
    impersonation: "Commander au nom de"
}