export default {
    types: {
        "ac_router": "AC-router",
        "chair": "Stoel",
        "desk": "Bureau",
        "desktop": "Bureaublad",
        "desktop_riser": "Bureaubladverhoger",
        "detector": "Vape- en geluidsdetector",
        "displayport_cable": "Displayport-kabel",
        "docking station": "Dockingstation",
        "dual_usb_adapter": "Dual-band USB-adapter",
        "ear cushion": "Oorkussen",
        "footrest": "Voetsteun",
        "hdmi_cable": "HDMI-kabel",
        "hdmi_displayport_cable": "HDMI naar DisplayPort-kabel",
        "hdmi_vga_cable": "HDMI naar VGA-kabel",
        "headset": "Koptelefoon",
        "keyboard": "Toetsenbord",
        "kvm switch": "Kvm-schakelaar",
        "kvm_switch": "Kvm-schakelaar",
        "lamp": "Lamp",
        "laptop stand": "Laptopstandaard",
        "laptop": "Laptop",
        "mesh_router": "Mesh-router",
        "monitor": "Monitor",
        "mouse": "Muis",
        "patch_cord": "Cat6 patchkabel",
        "power": "Stroomkabel",
        "power_center": "Krachtcentrum",
        "power_strip": "Stekkerdoos",
        "printer": "Printer",
        "smart_plug": "Slimme stekker mini",
        "smart_power_strip": "Slimme stekkerdoos",
        "stylus": "Stylus",
        "tablet": "Tablet",
        "tablet accessory": "Tablet accessoire",
        "thunderbolt_cable": "USB-C naar Thunderbolt",
        "travel_router": "Reisrouter",
        "usb hub": "USB-hub",
        "usb_adapter": "USB-adapter",
        "usb_hub": "USB-hub",
        "webcam": "Webcamera"
    }
}